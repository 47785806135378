import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  FC,
  useMemo,
  useEffect,
} from 'react';
import useAuth from './AuthContext';
import { StudentData } from '../types/UserProfile';
import { useSearchParams } from 'react-router-dom';

interface SelectedStudentContextType {
  selectedStudentUid: string | undefined;
  setSelectedStudentUid: (studentId: string) => void;
  selectedStudent: StudentData | undefined;
}

const SelectedStudentContext = createContext<SelectedStudentContextType>(
  {} as SelectedStudentContextType,
);

export const SelectedStudentProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuth();
  const [selectedStudentUid, setSelectedStudentUid] = useState<
    string | undefined
  >(searchParams.get('studentId') || undefined);

  const selectedStudent = useMemo(() => {
    return user?.students?.find((s) => s.uid === selectedStudentUid);
  }, [user?.students, selectedStudentUid]);

  useEffect(() => {
    if (user?.students && !selectedStudentUid) {
      setSelectedStudentUid(user?.students?.[0]?.uid);
    }
  }, [user?.students]);

  const setSelectedStudentUidWrapper = (uid: string) => {
    const newSearchParams: { [key: string]: string } = {};
    if (user?.students?.[0].uid !== uid) {
      newSearchParams['studentId'] = uid;
    }
    setSearchParams(newSearchParams, { replace: true });
    setSelectedStudentUid(uid);
  };

  return (
    <SelectedStudentContext.Provider
      value={{
        selectedStudentUid,
        setSelectedStudentUid: setSelectedStudentUidWrapper,
        selectedStudent,
      }}
    >
      {children}
    </SelectedStudentContext.Provider>
  );
};

export default function useSelectedStudent() {
  return useContext(SelectedStudentContext);
}
