import { ErrorMessages } from '../constants/messages';
import * as yup from 'yup';
import { validateStudentEmail } from '../pages/RegisterStudent/useRegisterStudent';

export interface FormField {
  inputType:
    | 'text'
    | 'checkbox'
    | 'email'
    | 'select'
    | 'date'
    | 'password'
    | 'double'
    | 'textarea'
    | 'button';
  name: string;
  displayName?: string;
  hasLabel?: boolean;
  children?: FormField[];
  options?: { label: string; value: string }[];
  label?: string;
}

export interface DynamicFormProps {
  formFields: FormField[];
  onSubmit: (data: FormField) => void;
  formMethods: any;
  onReady: (methods: { validate: any }) => void;
}

const EmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

export const emailValidation = yup
  .string()
  .email(ErrorMessages.Invalid('Email'))
  .required(ErrorMessages.Required('Email'))
  .test('dot', ErrorMessages.Invalid('Email'), (value) => {
    if (value) {
      return EmailRegex.test(value);
    }
  })
  .max(80, ErrorMessages.TooLong('Email', 80));

export const emailValidationExists = emailValidation.test(
  'unique-email',
  '',
  async function (email) {
    if (!email || !EmailRegex.test(email)) return true;

    try {
      const isEmailValid = await validateStudentEmail(email);
      if (isEmailValid) {
        return this.createError({
          message: ErrorMessages.EmailExists(email),
        });
      }
      return true;
    } catch (error) {
      return true;
    }
  },
);

export const passwordValidation = yup
  .string()
  .min(8)
  .max(32)
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+<>?])[A-Za-z\d!@#$%^&*()_+<>?]{8,32}$/,
    'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character among the following: ! @ # $ % ^ & * ( ) _ + < > ?',
  )
  .required(ErrorMessages.Required('Password'));
