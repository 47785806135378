import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import PlayIcon from '../../icons/PlayIcon';
import useAuth from '../../contexts/AuthContext';
import { UserRole } from '../../types/UserProfile';
import BackgroundImage from '../../components/BackgroundImage';
import { useStartTest } from './useStartTest';

export default function NeuropsychTestIntro() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { studentUserId } = useParams();
  const { mutation } = useStartTest({
    onSuccess: () => {
      navigate(
        user?.role === UserRole.PARENT
          ? `/parent/neuro-test/${studentUserId}`
          : '/student/neuro-test',
      );
    },
  });

  return (
    <div className="relative w-full h-full grow items-center">
      <BackgroundImage
        desktopImage={'/images/pebble-background/desktop-2.png'}
        mobileImage={'/images/pebble-background/mobile-2.png'}
      />
      <div className="flex flex-col items-center pt-[93px] pb-[40px] md:pt-[190px] max-w-[810px] px-[17px] m-auto text-center">
        <img src="/images/uluru-logo/smiling.svg" alt="" width="180px" />
        <h2 className="text-2xl md:text-4xl mt-[30px]">
          Welcome to The ULURU Mental Health Rating Tool
        </h2>
        <p className="text-sm md:text-base m-0">
          The following screening instruments are standardized
          neuropsychological instruments used in practices across the country to
          determine a variety of mental capacities and behaviors. ULURU will use
          this information to further customize its approach to providing EF
          coaching suggestions. It will also provide you with an excellent look
          into your own feelings, giving you a better understanding of how you
          regulate your emotions and actions in a variety of circumstances.
        </p>
        <Button
          className="text-base flex gap-[10px] py-[12px] w-full md:w-[260px] justify-center items-center mt-[30px]"
          loading={mutation.isLoading}
          onClick={() => {
            mutation.mutate();
          }}
        >
          Begin
          <PlayIcon />
        </Button>
      </div>
    </div>
  );
}
