import React, { FC, useEffect, useState } from 'react';
import useAuth from '../../contexts/AuthContext';
import { getHomePath } from '../../utils/getHomePath';
import ProfileLayout from '../../layouts/ProfileLayout';
import { UserRole } from '../../types/UserProfile';
import {
  getStudentsList,
  Student,
  useGetStudentsList,
} from '../InvintationLinks/useInvitationLinks';
import { GcrComponent } from './GcrComponent';
import { BlackbaudComponent } from './BlackbaudComponent';
import { SchoologyComponent } from './Schoology/SchoologyComponent';
import { CanvasComponent } from './Canvas/CanvasComponent';

export const LMS: FC = () => {
  const { user } = useAuth();
  const { data: students } = useGetStudentsList({
    enabled: user?.role === UserRole.PARENT,
  });

  return (
    <ProfileLayout
      breadcrumbValues={[
        {
          label: 'Home',
          href: getHomePath(user),
        },
        { label: 'Google Classroom', href: '#' },
      ]}
    >
      <div className="md:mt-[40px] flex justify-between items-center">
        <h3 className="m-0 text-2xl md:text-4xl text-header-color">
          Link Uluru to Google Classroom
        </h3>
      </div>
      {user?.role === UserRole.STUDENT && (
        <div className="mt-2 flex flex-wrap">
          <div className="w-full">
            <GcrComponent studentUid={user.uid} />{' '}
          </div>
          <div className="w-full">
            <img
              src="/images/blackbaud.png"
              alt="blackbaud"
              style={{ width: '100px' }}
            />
            <br />
            <BlackbaudComponent studentUid={user.uid} />
          </div>
          <div className="w-full pt-5">
            <SchoologyComponent studentUid={user.uid} />
          </div>
          <div className="w-full pt-5">
            <CanvasComponent studentUid={user.uid} />
          </div>
        </div>
      )}
      {user?.role === UserRole.PARENT &&
        !!students?.length &&
        students.map((student) => {
          return (
            <div className="mt-14">
              <h3 className="w-full text-2xl font-bold truncate">
                {student.fullName}
              </h3>
              <div className="mt-2 flex flex-wrap">
                <div className="w-full">
                  <img src="/images/gcr.png" style={{ width: '100px' }} />
                  <br />
                  <GcrComponent studentUid={student.uid} />
                </div>
                <div className="w-full pt-5">
                  <img
                    src="/images/blackbaud.png"
                    alt="blackbaud"
                    style={{ width: '100px' }}
                  />
                  <br />
                  <BlackbaudComponent studentUid={student.uid} />
                </div>
                <div className="w-full pt-5">
                  <SchoologyComponent studentUid={student.uid} />
                </div>
                <div className="w-full pt-5">
                  <CanvasComponent studentUid={student.uid} />
                </div>
              </div>
            </div>
          );
        })}
    </ProfileLayout>
  );
};
