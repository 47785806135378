import { httpClient } from '../../../api/http/http-client';

export enum SchoologyTokenStatus {
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified',
}

export interface SchoologyVerifyToken {
  oauth_token?: string | null;
  realm_id?: string | null;
}

export interface SchoologyVerifyResponse {
  status: SchoologyTokenStatus;
}

export const verifyToken = async (token: SchoologyVerifyToken) => {
  return httpClient.post<void, SchoologyVerifyResponse>('/schoology/verify', {
    ...token,
  });
};
