import React, { FC } from 'react';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';

export const ListOfAssignmentsButton: FC<{ link: string }> = ({ link }) => {
  return (
    <Button
      className="!bg-grey2 !border-none flex justify-center p-0 md:mb-[74px]"
      onClick={() => {}}
      link
    >
      <Link
        to={link}
        className="text-sm flex gap-[10px] items-center justify-center w-full py-[12px]"
      >
        <i className="pi pi-list text-blue1" />
        <span className="!text-black1">List of Assignments</span>
      </Link>
    </Button>
  );
};
