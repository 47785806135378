import React, { FC } from 'react';

interface Props {
  activeQuestion: number;
  totalQuestions: number;
}
export const QuestionProgressCounter: FC<Props> = ({
  activeQuestion,
  totalQuestions,
}) => {
  const questionWidth = 180 / totalQuestions;
  const blueLineWidth = activeQuestion * questionWidth;
  const greyLineWidth = (totalQuestions - activeQuestion) * questionWidth;
  return (
    <div className="flex h-[24px] min-w-[290px] text-sm md:text-base">
      <span className="whitespace-nowrap">Question {activeQuestion}</span>
      <div className="px-2.5 whitespace-nowrap">
        <span
          className="blue line"
          style={{ width: `${blueLineWidth}px` }}
        ></span>
        <span
          className="grey line"
          style={{ width: `${greyLineWidth}px` }}
        ></span>
      </div>
      <span>{totalQuestions}</span>
    </div>
  );
};
