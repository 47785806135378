import React from 'react';
import { RegisterProvider } from './RegisterContext';
import { Outlet } from 'react-router';

const RegisterLayout: React.FC = () => {
  return (
    <RegisterProvider>
      <Outlet />
    </RegisterProvider>
  );
};

export default RegisterLayout;
