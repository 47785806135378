import { httpClient } from '../../api/http/http-client';
import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

export interface AnswerValue {
  text: string;
  value: number;
}

export interface QuestionsData {
  answerOptions: AnswerValue[];
  category: string;
  createdAt: string;
  instructions: string;
  questionNumber: number;
  questionText: string;
  subTestName: string;
  testUid: string;
  uid: string;
  updatedAt: string;
  answerValue?: string;
}

export interface QuestionsResponse {
  questions: QuestionsData[];
  testUid: string;
}

export const getQuestions = async ({
  queryKey,
}: {
  queryKey: (string | undefined)[];
}): Promise<QuestionsResponse> => {
  const [, studentUid] = queryKey;
  return httpClient.get<void, QuestionsResponse>('/tests/proceed', {
    params: { studentUid },
  });
};

export const useGetQuestions = (studentUid?: string) => {
  return useQuery(['/tests/proceed', studentUid], getQuestions, {
    retry: false,
  });
};
