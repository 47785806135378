import { httpClient } from '../../api/http/http-client';
import { useQuery } from '@tanstack/react-query';
import { isNil, omitBy } from 'lodash';
import { Parent } from '../../types/Parent';

export interface FamilyAccountsData {
  parents: Parent[];
  total: number;
}

export const getFamilyAccounts = async ({
  queryKey,
}: {
  queryKey: (string | undefined)[];
}): Promise<FamilyAccountsData> => {
  const [, search, limit, page] = queryKey;
  const params = {
    search,
    limit: Number(limit),
    page: Number(page) - 1,
  };

  const filteredParams = omitBy(params, isNil);

  return httpClient.get<void, FamilyAccountsData>('/family-profile/list-all', {
    params: filteredParams,
  });
};

export const useGetFamilyAccounts = ({
  search,
  limit,
  page,
}: {
  search?: string;
  limit?: string;
  page?: string;
}) => {
  return useQuery(
    ['/family-profile/list-all', search, limit, page],
    getFamilyAccounts,
    {
      keepPreviousData: true,
    },
  );
};
