import { Credentials } from './types';
import { UserProfile } from '../../types/UserProfile';

class CredentialsService {
  private _credentials: Credentials | null = null;
  private _user: UserProfile | null = null;
  private credentialsKey = 'credentials';
  private listeners: Array<(user: UserProfile | null) => void> = [];

  constructor() {
    const savedCredentials =
      localStorage.getItem(this.credentialsKey) ||
      sessionStorage.getItem(this.credentialsKey);

    if (savedCredentials) {
      try {
        this._credentials = JSON.parse(savedCredentials) as Credentials;
      } catch (e) {
        this._credentials = null;
        localStorage.removeItem(this.credentialsKey);
        sessionStorage.removeItem(this.credentialsKey);
      }
    }
  }

  public get token(): string | null {
    return this._credentials ? this._credentials.token : null;
  }

  /**
   * Gets the user credentials.
   *
   * @return The user credentials or null if the user is not authenticated.
   */
  public get credentials(): Credentials | null {
    return this._credentials;
  }

  public subscribe(listener: (user: UserProfile | null) => void): void {
    this.listeners.push(listener);
  }

  public unsubscribe(listener: (user: UserProfile | null) => void): void {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }

  public setUser(user: UserProfile | null): void {
    this._user = user;
    this.listeners.forEach((listener) => listener(user));
  }

  public get user(): UserProfile | null {
    return this._user;
  }

  /**
   * Sets the user credentials.
   * The credentials persisted across sessions.
   *
   * @param credentials The user credentials.
   * @param saveToSessionStorage Save credentials to session storage instead of local storage.
   */
  public set(credentials: Credentials, saveToSessionStorage?: boolean): void {
    this.flush();
    this._credentials = credentials;

    if (saveToSessionStorage) {
      sessionStorage.setItem(this.credentialsKey, JSON.stringify(credentials));
    } else {
      localStorage.setItem(this.credentialsKey, JSON.stringify(credentials));
    }
  }

  public flush(): void {
    this._credentials = null;
    sessionStorage.removeItem(this.credentialsKey);
    localStorage.removeItem(this.credentialsKey);

    this.setUser(null);
  }
}

export const credentialsService = new CredentialsService();
