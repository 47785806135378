import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import StartNeurotest from '../../components/StartNeurotest';
import { useGetTestProgress } from '../NeuropsychTest/useGetTestProgress';
import { HomeAssignmentsList } from '../../components/HomeAssignmentsList/HomeAssignmentsList';
import useAuth from '../../contexts/AuthContext';
import MindMap from '../../components/MindMap';
import { ListOfAssignmentsButton } from '../../components/ListOfAssignmentsButton/ListOfAssignmentsButton';
import { updateTimezone } from '../../hooks/usePushNotifications';

export const StudentHome: FC = () => {
  const navigate = useNavigate();
  const { data: progressData, isLoading: isLoadingProgress } =
    useGetTestProgress();
  const { user } = useAuth();

  useEffect(() => {
    updateTimezone(
      window?.Intl?.DateTimeFormat().resolvedOptions().timeZone,
    ).catch((e) => {});
  }, [user]);

  const studentId = user?.uid;
  return (
    <div className="bg-white h-full w-full py-[30px] px-[17px] md:py-[30px] lg:px-[130px]">
      <div className="flex flex-col md:flex-row gap-[30px]">
        {studentId ? (
          <HomeAssignmentsList studentId={studentId} />
        ) : (
          <div className="min-h-[100vh] md:min-h-fit"></div>
        )}

        {!isLoadingProgress &&
          (progressData?.isTestCompleted ? (
            <div className="flex flex-col gap-[20px] w-full md:w-[373px] xl:w-[469px] shrink-0 justify-between">
              <MindMap studentId={studentId} />
              <ListOfAssignmentsButton link="/student/assignments" />
            </div>
          ) : (
            <div className="flex flex-col gap-[20px] w-full md:w-[300px] xl:w-[373px] shrink-0 ml-auto">
              <StartNeurotest
                onStartClick={() => {
                  if (progressData?.isTestStarted) {
                    navigate('/student/neuro-test');
                  } else {
                    navigate('/student/neuro-test/intro');
                  }
                  window.scrollTo(0, 0);
                }}
                completionPercentage={progressData?.percent}
                testStatus={progressData?.testStatus}
                testResultStatus={
                  progressData?.resultStatusData?.finalResult?.testResultStatus
                }
                testResultPercent={
                  progressData?.resultStatusData?.finalResult?.testResultPercent
                }
              />
              <ListOfAssignmentsButton link="/student/assignments" />
            </div>
          ))}
      </div>
    </div>
  );
};
