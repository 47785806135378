import { FC, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  to: string;
  disabled?: boolean;
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
}

export const NavButtonClass = 'text-base cursor-pointer !text-grey3 p-[10px]';
const ActiveNavButtonClass =
  'text-base p-[10px] !text-white bg-blue1 rounded-[5px]';

export const NavbarLink: FC<Props> = ({
  to,
  disabled,
  className = '',
  children,
  onClick = () => {},
}) => {
  return disabled ? (
    <div className={`${NavButtonClass} ${className} opacity-40`}>
      {children}
    </div>
  ) : (
    <NavLink
      to={to}
      className={({ isActive }) =>
        (isActive ? ActiveNavButtonClass : NavButtonClass) + ` ${className}`
      }
      onClick={onClick}
    >
      {children}
    </NavLink>
  );
};
