import { httpClient } from '../../api/http/http-client';
import { loadStripe } from '@stripe/stripe-js';
import { useQuery } from '@tanstack/react-query';

export interface SubscriptionDetails {
  primaryPrice: number;
  secondaryPrice: number;
  refundDays: number;
  stripePublicKey: string;
}

export const getSubscriptionDetails =
  async (): Promise<SubscriptionDetails> => {
    return httpClient.get<void, SubscriptionDetails>('/subscriptions/details');
  };

export const useGetSubscriptionDetails = () => {
  return useQuery(['/subscription-details'], getSubscriptionDetails);
};

export const createStripeSession = async (
  selectedStudents: {
    [uid: string]: boolean;
  },
  cancelUrl: string,
  successUrl: string,
): Promise<{ id: string }> => {
  return httpClient.post<void, { id: string }>('/subscriptions/session', {
    selectedStudents,
    cancelUrl,
    successUrl,
  });
};

export function formatPrice(price: number) {
  return (price / 100).toFixed(2);
}

export const purchaseStripe = async (
  stripePublicKey: string,
  selectedStudents: { [uid: string]: boolean },
  cancelUrl: string,
  successUrl: string,
) => {
  const stripe = await loadStripe(stripePublicKey);
  if (!stripePublicKey || !stripe) {
    return false;
  }

  const response = await createStripeSession(
    selectedStudents,
    cancelUrl,
    successUrl,
  );
  if (!response.id) {
    return false;
  }

  // Redirect to Stripe checkout
  const result = await stripe.redirectToCheckout({
    sessionId: response.id,
  });

  return !result.error;
};
