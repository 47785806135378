import React, { FC } from 'react';
import { Student } from '../../types/Student';
import { format, parseISO } from 'date-fns';
import { GendersMap } from '../RegisterStudentForm/RegisterStudentForm';
import {
  getStatusLabel,
  StripeStatus,
} from '../../pages/ManageSubscription/useManageSubscriptions';
import { StatusItemClassName } from '../../pages/FamilyAccountsList/FamilyAccountsList';

export const StudentsTable: FC<{ students: Student[] }> = ({ students }) => {
  return (
    <div className="">
      <div className="hidden md:grid min-w-full text-sm text-black1 grid-cols-[1fr_auto_auto_auto_1fr_auto_auto_auto] items-center">
        <div className="flex border-bottom-grey1 py-[17px] pl-[20px] font-bold h-full">
          <div className="h-fit my-auto">Student name / email</div>
        </div>
        <div className="flex border-bottom-grey1 py-[17px] pl-[20px] font-bold h-full">
          <div className="h-fit my-auto">Date of birth</div>
        </div>
        <div className="flex border-bottom-grey1 py-[17px] pl-[20px] font-bold h-full">
          <div className="h-fit my-auto">Gender</div>
        </div>
        <div className="flex border-bottom-grey1 py-[17px] pl-[20px] font-bold h-full">
          <div className="h-fit my-auto">Zip code</div>
        </div>
        <div className="flex border-bottom-grey1 py-[17px] pl-[20px] font-bold h-full">
          <div className="h-fit my-auto">School Name</div>
        </div>
        <div className="flex border-bottom-grey1 py-[17px] pl-[20px] font-bold h-full">
          <div className="h-fit my-auto">Grade Level</div>
        </div>
        <div className="flex border-bottom-grey1 py-[17px] pl-[20px] font-bold h-full">
          <div className="h-fit my-auto">Active Before</div>
        </div>
        <div className="flex border-bottom-grey1 px-[20px] font-bold h-full">
          <div className="h-fit m-auto">
            Subscription <br /> status
          </div>
        </div>

        {students.map((student) => (
          <>
            <div className="border-bottom-grey2 h-full flex py-[10px] pl-[20px] whitespace-nowrap">
              <div className="h-fit my-auto">
                <div className="text-sm md:text-base text-black1 font-bold break-all">
                  {student.fullName}
                </div>
                <div className="text-sm md:text-base text-grey3 break-all">
                  {student.user?.email}
                </div>
              </div>
            </div>
            <div className="border-bottom-grey2 h-full flex py-[10px] pl-[20px]">
              <div className="h-fit my-auto">
                {student?.birthday &&
                  format(parseISO(student?.birthday), 'MM-dd-yyyy')}
              </div>
            </div>
            <div className="border-bottom-grey2 h-full flex py-[10px] pl-[20px]">
              <div className="h-fit my-auto">{GendersMap[student.gender]}</div>
            </div>
            <div className="border-bottom-grey2 h-full flex py-[10px] pl-[20px]">
              <div className="h-fit my-auto">{student.schoolZipCode}</div>
            </div>
            <div className="border-bottom-grey2 h-full flex py-[10px] pl-[20px]">
              <div className="h-fit my-auto">{student.schoolName}</div>
            </div>
            <div className="border-bottom-grey2 h-full flex py-[10px] pl-[20px]">
              <div className="h-fit my-auto">{student.gradeLevel}</div>
            </div>
            <div className="border-bottom-grey2 h-full flex py-[10px] pl-[20px]">
              <div className="h-fit my-auto">
                {student.subscription?.expDate
                  ? format(
                      parseISO(student.subscription?.expDate),
                      'MM-dd-yyyy',
                    )
                  : '-'}
              </div>
            </div>
            <div className="border-bottom-grey2 h-full flex py-[10px] pl-[20px]">
              <div className="h-fit my-auto">
                <div
                  className={`${
                    student.subscription?.status === StripeStatus.ACTIVE
                      ? 'bg-green4 text-green5'
                      : 'bg-red3 text-red4'
                  }  ${StatusItemClassName}`}
                >
                  {getStatusLabel(student.subscription?.status || 'inactive')}
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
      <div className="md:hidden flex flex-col">
        {students.map((student, i) => (
          <div
            className="flex flex-col gap-[7px] p-[15px]"
            style={{
              borderTop: i === 0 ? '1px solid #EDF0F6' : 'none',
              borderBottom: '1px solid #EDF0F6',
            }}
          >
            <div className="flex items-center">
              <div className="w-1/2 text-[12px]">Student name / email</div>
              <div className="w-1/2">
                <div className="text-sm md:text-base text-black1 font-bold break-all">
                  {student.fullName}
                </div>
                <div className="text-sm md:text-base text-grey3 break-all">
                  {student.user?.email}
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-1/2 text-[12px]">Date of birth</div>
              <div className="w-1/2 text-sm md:text-base">
                {student?.birthday &&
                  format(parseISO(student?.birthday), 'MM-dd-yyyy')}
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-1/2 text-[12px]">Gender</div>
              <div className="w-1/2 text-sm md:text-base">
                {GendersMap[student.gender]}
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-1/2 text-[12px]">Zip code</div>
              <div className="w-1/2 text-sm md:text-base">
                {student.schoolZipCode}
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-1/2 text-[12px]">School Name</div>
              <div className="w-1/2 text-sm md:text-base">
                {student.schoolName}
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-1/2 text-[12px]">Grade Level</div>
              <div className="w-1/2 text-sm md:text-base">
                {student.gradeLevel}
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-1/2 text-[12px]">Active Before</div>
              <div className="w-1/2 text-sm md:text-base">
                {student.subscription?.expDate
                  ? format(
                      parseISO(student.subscription?.expDate),
                      'MM-dd-yyyy',
                    )
                  : '-'}
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-1/2 text-[12px]">Subscription status</div>
              <div className="w-1/2 text-sm md:text-base">
                <div
                  className={`w-fit ${
                    student.subscription?.status === StripeStatus.ACTIVE
                      ? 'bg-green4 text-green5'
                      : 'bg-red3 text-red4'
                  }  ${StatusItemClassName}`}
                >
                  {getStatusLabel(student.subscription?.status || 'inactive')}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
