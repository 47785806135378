const LinkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M13.1199 8.18657L8.54991 3.61657C7.21941 2.28607 5.03241 2.31507 3.66591 3.68207C2.29891 5.04907 2.26941 7.23557 3.60041 8.56607L7.56791 12.5341M16.4514 11.5016L20.4189 15.4691C21.7494 16.8001 21.7204 18.9866 20.3539 20.3536C18.9864 21.7206 16.7999 21.7496 15.4689 20.4186L10.8989 15.8486"
        stroke="#797F8D"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.0556 13.071C14.4221 11.704 14.4516 9.51702 13.1206 8.18652M10.9001 10.899C9.53309 12.266 9.50409 14.453 10.8346 15.7835"
        stroke="#797F8D"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default LinkIcon;
