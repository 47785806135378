import { Outlet } from 'react-router';
import BackgroundImage from '../components/BackgroundImage';
import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../contexts/AuthContext';
import { getHomePath } from '../utils/getHomePath';

const smilingRoutes = ['/', '/login', '/register/invitations'];
const smilingSearch = ['?linkSent=true&email='];
const sadRoutes = ['/change-password', '/reset-password'];

const restrictedRoutes = ['/', '/login'];
const Main = () => {
  const location = useLocation();
  const { user } = useAuth();
  const navigate = useNavigate();

  const logo = useMemo(() => {
    if (location.pathname.startsWith('/student/register')) {
      return '/images/uluru-logo/student/wink.svg';
    }
    if (location.pathname.startsWith('/register/survey/intro')) {
      return '/images/target-image.png';
    }

    if (
      smilingRoutes.includes(location.pathname) ||
      smilingSearch.some((search) => location.search.includes(search))
    ) {
      return '/images/uluru-logo/smiling.svg';
    }
    if (sadRoutes.includes(location.pathname)) {
      return '/images/uluru-logo/sad.svg';
    }
    return '/images/uluru-logo/wink.svg';
  }, [location.pathname, location.search]);

  const logoHeight = useMemo(() => {
    if (location.pathname.startsWith('/register/survey/intro')) {
      return 372;
    }

    return 242;
  }, [location.pathname]);

  const mobileLogoHeight = useMemo(() => {
    if (location.pathname.startsWith('/register/survey/intro')) {
      return 169;
    } else if (location.pathname.includes('login')) {
      return 118;
    }

    return 92;
  }, [location.pathname]);

  useEffect(() => {
    if (user && restrictedRoutes.includes(location.pathname)) {
      navigate(getHomePath(user));
    }
  }, [location, user]);

  return (
    <div className={`min-h-screen relative`}>
      <BackgroundImage
        desktopImage={'/images/pebble-background/desktop-1.png'}
        mobileImage={'/images/pebble-background/mobile-1.png'}
        fixed
      />
      <div className="flex flex-col md:flex-row content-wrap min-h-screen">
        <div className="h-[230px] md:h-auto md:w-[42%] flex justify-center">
          <div
            className="md:fixed h-fit self-center"
            style={{
              top: `calc(50% - ${logoHeight / 2}px)`,
            }}
          >
            <img
              src={logo}
              alt="Uluru logo"
              className="hidden md:block"
              style={{ height: `${logoHeight}px` }}
            />

            <img
              src={logo}
              alt="Uluru logo"
              className="md:hidden"
              style={{ height: `${mobileLogoHeight}px` }}
            />
          </div>
        </div>
        <div className="md:m-0 md:w-[58%] md:justify-center flex flex-col grow register-steps">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Main;
