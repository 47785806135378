import React, { FC, useMemo } from 'react';
import { SurveyAnswerData } from '../../pages/SurveySteps/useSurveySteps';
import useAuth from '../../contexts/AuthContext';
import { SurveyQuestions } from '../../pages/SurveySteps/constant';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';

export const CheckupSurveyResponses: FC<{ surveys: SurveyAnswerData[] }> = ({
  surveys,
}) => {
  const { user } = useAuth();
  const questions = Object.values(SurveyQuestions);
  const surveyObj = useMemo(
    () =>
      surveys.reduce(
        (acc, survey) => {
          acc[survey.question] = survey.answers;
          return acc;
        },
        {} as Record<string, string[]>,
      ),
    [surveys],
  );
  return (
    <div className="w-auto md:max-w-[574px] flex flex-col gap-[30px]">
      {questions.map((question, i) => (
        <div
          className="flex flex-col gap-[10px] pb-[30px]"
          style={{
            borderBottom:
              i === questions.length - 1 ? 'none' : '1px solid #B3B9C3',
          }}
        >
          <h2 className="text-lg md:text-2xl m-0">
            {user?.students && user.students.length > 1
              ? question.question?.replace('child', 'children')
              : question.question}
          </h2>
          <div className="flex flex-col gap-[10px]">
            {question &&
              question?.answers.map((answer) =>
                answer.name === 'mostChallenging' ? (
                  <div className="h-[250px]">
                    <InputTextarea
                      className="h-full"
                      value={surveyObj[question.question]?.[0]}
                      readOnly
                    />
                  </div>
                ) : (
                  <div
                    className="flex gap-[15px] pl-[15px] p-[20px] bg-grey2 rounded-[5px]"
                    style={{
                      borderLeft:
                        surveyObj[question.question]?.indexOf?.(answer.name) >=
                        0
                          ? '5px solid #468ED0'
                          : '5px solid #EDF0F6',
                    }}
                  >
                    <Checkbox
                      className="grey-checkbox"
                      checked={
                        surveyObj[question.question]?.indexOf?.(answer.name) >=
                        0
                      }
                      disabled
                    />
                    <div className="text-sm md:text-base">
                      {user?.students && user?.students.length > 1
                        ? answer.label?.replace('child', 'children')
                        : answer.label}
                    </div>
                  </div>
                ),
              )}
          </div>
        </div>
      ))}
    </div>
  );
};
