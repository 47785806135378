import { Modal } from '@mui/material';
import { Button } from 'primereact/button';
import {
  AssignmentSuggestionsStatus,
  CoachingSuggestionType,
} from '../CoachingSuggestionsModal/useGetCoaching';
import { ProgressSpinner } from 'primereact/progressspinner';
import { capitalize } from 'lodash';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  open: boolean;
  onClose: () => void;
}

const SignUpPopup: FC<Props> = ({ open, onClose }) => {
  const navigate = useNavigate();

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={`flex items-center justify-center`}
    >
      <div className="w-full max-w-[810px] h-fit p-[17px] outline-none">
        <div className="relative w-full h-full p-[30px] bg-white rounded-[10px] flex flex-col gap-[20px]">
          <Button
            className="absolute top-[10px] right-[10px]"
            icon="pi pi-times"
            rounded
            text
            severity="secondary"
            aria-label="Close modal"
            onClick={onClose}
          />
          <div className="m-auto w-[110px]">
            <img
              src="/images/uluru-logo/wink.svg"
              alt="Uluru Logo"
              className="w-full"
            />
          </div>

          <h2 className="text-2xl md:text-4xl text-center m-0">
            Are you the parent or guardian of the students?
          </h2>
          <p className="m-0 px-[10px] md:px-[20px] text-base md:text-lg mb-[20px] text-center">
            Family account registration is possible only for adults (parents or
            guardians).
          </p>

          <div className="flex justify-center gap-[20px] w-full">
            <Button
              className="flex w-[150px] justify-center !bg-grey1 text-sm md:text-base"
              onClick={onClose}
            >
              No, cancel
            </Button>
            <Button
              className="flex w-[150px] justify-center text-sm md:text-base"
              onClick={() => {
                navigate('/register');
              }}
            >
              Yes, continue
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SignUpPopup;
