import { InputText } from 'primereact/inputtext';
import { useFormContext } from 'react-hook-form';
import { ChangeEvent, FC } from 'react';

interface Props {
  name: string;
  label?: string;
  placeholder?: string;
  pattern?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  inputClassName?: string;
  inputType?: string;
  min?: number;
  max?: number;
  inputValue?: string;
}
export const Input: FC<Props> = ({
  label,
  placeholder,
  name,
  pattern,
  onChange,
  inputClassName = '',
  inputType = 'text',
  inputValue,
  min,
  max,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const fieldError = errors[name];
  const message = fieldError?.message?.toString();

  return (
    <div className={`${!!fieldError ? 'error' : ''} label-container`}>
      {label && <label className="hidden md:block">{label}</label>}
      <InputText
        {...register(name)}
        placeholder={placeholder}
        type={inputType}
        className={`w-full ${inputClassName}`}
        pattern={pattern}
        {...(onChange && { onChange })}
        {...(inputType === 'number' && min && min >= 0 && { min })}
        {...(inputType === 'number' && max && min && max > min && { max })}
        {...(inputValue && { value: inputValue })}
      />
      {message && <div className="input-error-text">{message}</div>}
    </div>
  );
};
