export const goals = [
  { name: 'Confidence', type: 'goal' },
  { name: 'Organization', type: 'goal' },
  { name: 'Friends', type: 'goal' },
  { name: 'Discipline', type: 'goal' },
  { name: 'Not procrastinating', type: 'goal' },
  { name: 'Better grades', type: 'goal' },
  { name: 'Faster at homework', type: 'goal' },
  { name: 'Timelines', type: 'goal' },
  { name: 'Less anxiety', type: 'goal' },
  { name: 'More fun', type: 'goal' },
  { name: 'Parents off my back', type: 'goal' },
];
