import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { httpClient } from '../../../api/http/http-client';

interface SchoologyLoginCredentials {
  email: string;
  password: string;
  realm?: string;
  realm_id?: string;
  app_id?: string;
  is_ssl?: string;
}

interface SchoologyLoginResponse {
  url?: string;
  integrated: boolean;
  accessToken: string;
}

const loginSchoologyUser = async (
  credentials: SchoologyLoginCredentials,
): Promise<SchoologyLoginResponse> => {
  return httpClient.post<void, SchoologyLoginResponse>('/schoology/integrate', {
    ...credentials,
  });
};

export const useSchoologyLogin = (callbacks: {
  onSuccess?: (data: SchoologyLoginResponse) => void;
  onError?: (error: AxiosError<{ message: string | string[] }>) => void;
}) => {
  const mutation = useMutation(
    (credentials: SchoologyLoginCredentials) => loginSchoologyUser(credentials),
    {
      onSuccess: callbacks.onSuccess,
      onError: (error: AxiosError<{ message: string | string[] }>) => {
        callbacks?.onError?.(error);
      },
    },
  );

  return { mutation };
};
