import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { httpClient } from '../../api/http/http-client';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { TestResult } from '../TestResult/useGetTestResult';

interface FinishTestData {
  testUid?: string;
}

const finishTest = async (data: FinishTestData): Promise<TestResult> => {
  return httpClient.post('/tests/finish', data);
};

export const useFinishTest = (callbacks: {
  onSuccess?: (data: TestResult, variables: FinishTestData) => void;
  onError?: (error: AxiosError<{ message: string | string[] }>) => void;
}) => {
  const { handleNetworkError } = useSnackbar();
  const mutation = useMutation(
    (questionData: FinishTestData) => finishTest(questionData),
    {
      onSuccess: callbacks.onSuccess ?? (() => {}),
      onError: (error: AxiosError<{ message: string | string[] }>) => {
        handleNetworkError(error);
        callbacks?.onError?.(error);
      },
    },
  );

  return { mutation };
};
