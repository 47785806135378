import { FC, ReactNode } from 'react';
import pluralize from 'pluralize';

const EducationalStatusBannerWrapper: FC<{
  children: ReactNode;
  redBackground?: boolean;
}> = ({ children, redBackground }) => {
  return (
    <>
      <div
        className={`w-full rounded-[8px] flex-row p-0 pl-[30px] pt-[6px] hidden md:flex overflow-hidden ${
          redBackground ? 'bg-red3' : 'bg-yellow2'
        }`}
        style={{
          backgroundImage: `url("/images/pebble-background/banner-1.png")`,
          backgroundPosition: 'left',
          backgroundClip: 'border-box',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundOrigin: 'padding-box',
        }}
      >
        {children}
      </div>
      <div
        className={`w-full rounded-[8px] flex flex-col p-[30px] md:hidden items-center overflow-hidden  ${
          redBackground ? 'bg-red3' : 'bg-yellow2'
        }`}
        style={{
          backgroundImage: `url("/images/pebble-background/banner-2.png")`,
          backgroundPosition: 'bottom',
          backgroundClip: 'border-box',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundOrigin: 'padding-box',
        }}
      >
        {children}
      </div>
    </>
  );
};

export const EducationalStatusBanner: FC<{ todoCount: number }> = ({
  todoCount,
}) => {
  return (
    <EducationalStatusBannerWrapper redBackground={!!todoCount}>
      <img
        src={
          todoCount
            ? '/images/uluru-logo/sad-no-text.svg'
            : '/images/uluru-logo/wink-no-text.svg'
        }
        alt=""
        className="md:mr-[50px] md:mb-[-10px]"
        width="90px"
        height="90px"
      />
      <div className="">
        <h2 className="m-0 mt-[8px] text-2xl md:text-start text-center">
          {todoCount ? 'Don’t forget! ' : 'Great Job!'}
        </h2>
        <p className="m-0 mt-[5px] text-sm text-grey1 md:text-start text-center">
          {todoCount
            ? `${pluralize(
                'assignment',
                todoCount,
                true,
              )} should be done before tomorrow`
            : 'All Assignments were done in time!'}
        </p>
      </div>
    </EducationalStatusBannerWrapper>
  );
};
