import React, { useEffect } from 'react';
import PreviousNextButtons from '../PreviousNextButtons/PreviousNextButtons';
import RegistrationProgress from '../RegistrationProgress/RegistrationProgress';
import { useRegisterContext } from '../../layouts/RegisterLayout/RegisterContext';
import { UserRole } from '../../types/UserProfile';
import useAuth from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import Copyright from '../Copyright';

const IntroSurveyStep: React.FC = () => {
  const {
    registrationSteps,
    goToNextStep,
    goToPrevStep,
    updateRegistrationSteps,
  } = useRegisterContext();

  updateRegistrationSteps(3);

  return (
    <div className="relative pb-[80px] md:flex content-wrap md:min-h-screen px-[50px] md:justify-center flex flex-col h-full grow">
      <RegistrationProgress registrationSteps={registrationSteps} />
      <h2 className="text-2xl md:text-4xl">
        Learning about your goals and vision for you and your family will help
        us tailor your ULURU experience
      </h2>
      <div className="register-checkbox-wrap"></div>
      <PreviousNextButtons
        nextStep={goToNextStep}
        previousStep={goToPrevStep}
      />
      <Copyright className="absolute left-0 right-0 bottom-0 p-[30px] px-[50px] mt-auto md:mt-0" />
    </div>
  );
};

export default IntroSurveyStep;
