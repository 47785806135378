import { useMutation } from '@tanstack/react-query';
import { httpClient } from '../../api/http/http-client';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { AxiosError } from 'axios';

const startAssignment = async (assignmentId: string): Promise<boolean> => {
  return httpClient.put(`/assignments/${assignmentId}/start`, {});
};

export const useStartAssignment = (options: {
  assignmentId?: string;
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const { handleNetworkError } = useSnackbar();

  const mutation = useMutation(
    () => startAssignment(options.assignmentId || ''),
    {
      onSuccess: options.onSuccess,
      onError: (error: AxiosError<{ message: string | string[] }>) => {
        handleNetworkError(error);
        options?.onError?.();
      },
    },
  );

  return { mutation };
};
