const InfoIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="18" fill="#FFBF00" />
      <g clip-path="url(#clip0_3223_1187)">
        <path
          d="M17.9987 24.6666C21.6806 24.6666 24.6654 21.6818 24.6654 17.9999C24.6654 14.318 21.6806 11.3333 17.9987 11.3333C14.3168 11.3333 11.332 14.318 11.332 17.9999C11.332 21.6818 14.3168 24.6666 17.9987 24.6666Z"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18 15.3333V17.9999"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18 20.6667H18.0067"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3223_1187">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(10 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InfoIcon;
