import { useMutation } from '@tanstack/react-query';
import { httpClient } from '../../api/http/http-client';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { AxiosError } from 'axios';

const startTest = async (): Promise<boolean> => {
  return httpClient.post(`/tests/start`, {});
};

export const useStartTest = (options: {
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const { handleNetworkError } = useSnackbar();

  const mutation = useMutation(() => startTest(), {
    onSuccess: options.onSuccess,
    onError: (error: AxiosError<{ message: string | string[] }>) => {
      handleNetworkError(error);
      options?.onError?.();
    },
  });

  return { mutation };
};
