import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

const Copyright: FC<{ className?: string }> = ({ className = '' }) => {
  return (
    <div
      className={`text-grey5 text-[10px] md:text-sm flex gap-[10px] justify-between mt-[40px] md:mt-[30px] ${className}`}
    >
      <span className="block ">
        COPYRIGHT © {new Date().getFullYear()} ELULA, INC.
        <br /> ALL RIGHTS RESERVED.
      </span>
      <span>
        <NavLink
          className="text-grey5 text-[10px] md:text-sm block align-text-right min-w-min"
          to="/privacy-policy"
        >
          PRIVACY POLICY
        </NavLink>
      </span>
    </div>
  );
};

export default Copyright;
