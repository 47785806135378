import RegistrationProgress from '../../components/RegistrationProgress/RegistrationProgress';
import React, { useState } from 'react';
import ClickIcon from '../../icons/ClickIcon';
import ShareSafariIcon from '../../icons/ShareSafariIcon';
import PreviousNextButtons from '../../components/PreviousNextButtons/PreviousNextButtons';
import MoreVerticalIcon from '../../icons/MoreVerticalIcon';
import { isSafari, isIOS, isMacOs } from 'react-device-detect';
import { useRegisterStudentContext } from '../../layouts/RegisterStudentLayout/RegisterStudentContext';
import Copyright from '../../components/Copyright';

enum DeviceTab {
  IPHONE = 'IPHONE',
  ANDROID = 'ANDROID',
}
const DeviceTabClassName =
  'w-1/2 text-header-color py-[10px] px-[20px] text-center cursor-pointer text-2xl';
const InstructionTitleClassName = 'm-0 text-2xl md:text-4xl';
const InstructionStepsClassName =
  'text-base md:text-lg text-grey3 flex flex-col gap-[30px]';
const AddToHomeScreenClassName =
  'text-blue3 py-[6px] px-[10px] rounded-[5px] mx-[15px] whitespace-nowrap';
const HowToDownload = () => {
  const [activeTab, setActiveTab] = useState<DeviceTab>(
    isSafari || isIOS || isMacOs ? DeviceTab.IPHONE : DeviceTab.ANDROID,
  );
  const { registrationSteps, goToNextStep, goToPrevStep } =
    useRegisterStudentContext();

  return (
    <div className="relative pb-[80px] bg-white p-[30px] px-[50px] md:p-20 h-full flex flex-col gap-[40px]">
      <div className="w-fit">
        <RegistrationProgress registrationSteps={registrationSteps} />
      </div>
      <h2 className="text-2xl md:text-4xl m-0">
        Add ULURU to your mobile device!
      </h2>
      <div className="flex gap-[10px] md:gap-[20px] md:w-[272px]">
        <div className="bg-green1 w-fit p-[16px] rounded-[15px] h-fit">
          <ClickIcon />
        </div>
        <p className="text-lg m-0 font-bold">
          Instant Access with one tap or click
        </p>
      </div>
      <div
        className="text-sm md:text-2xl text-bold text-grey1 w-full border-grey1 flex justify-between"
        style={{ borderBottom: '1px solid #b3b9c3' }}
      >
        <div
          className={DeviceTabClassName}
          style={{
            borderBottom:
              activeTab === DeviceTab.IPHONE ? '2px solid #468ed0' : 'none',
          }}
          onClick={() => setActiveTab(DeviceTab.IPHONE)}
        >
          iPhone
        </div>

        <div
          className={DeviceTabClassName}
          style={{
            borderBottom:
              activeTab === DeviceTab.ANDROID ? '2px solid #468ed0' : 'none',
          }}
          onClick={() => setActiveTab(DeviceTab.ANDROID)}
        >
          Android
        </div>
      </div>

      {activeTab === DeviceTab.IPHONE && (
        <>
          <h2 className={InstructionTitleClassName}>Shortcut for iOS</h2>
          <div className={InstructionStepsClassName}>
            <div className="flex gap-[15px]">
              <span>1. Open Safari</span>
              <img
                src="/images/safari-icon.png"
                alt="Safari icon"
                className="w-[30px]"
              />
            </div>
            <div>
              <div className="flex gap-[7px]">
                <span>2. Tap the share icon</span> <ShareSafariIcon />
              </div>
              (typically a square with an arrow pointing up)
            </div>
            <div>
              3. Select{' '}
              <span
                className={AddToHomeScreenClassName}
                style={{ boxShadow: '0px 2px 24px 0px rgba(37, 36, 45, 0.25)' }}
              >
                Add to Home Screen
              </span>{' '}
              <span className="invisible md:visible">Enjoy easy access!</span>
            </div>
          </div>
        </>
      )}

      {activeTab === DeviceTab.ANDROID && (
        <>
          <h2 className={InstructionTitleClassName}>Shortcut for Android</h2>
          <div className={InstructionStepsClassName}>
            <div className="flex gap-[15px]">
              <span>1. Open Chrome</span>
              <img
                src="/images/chrome-icon.png"
                alt="Safari icon"
                className="w-[30px]"
              />
            </div>
            <div>
              <div className="flex gap-[10px]">
                <span>2. Tap the three-dot menu</span> <MoreVerticalIcon />
              </div>
            </div>
            <div>
              3. Choose{' '}
              <span
                className={AddToHomeScreenClassName}
                style={{ boxShadow: '0px 2px 24px 0px rgba(37, 36, 45, 0.25)' }}
              >
                Add to Home Screen
              </span>
            </div>
          </div>
        </>
      )}

      <h4 className="text-lg md:text-2xl m-0">Download it now!</h4>
      <PreviousNextButtons
        nextStep={goToNextStep}
        hideIconNext
        nextLabel={'Finish'}
        previousStep={goToPrevStep}
      />
      <Copyright className="absolute left-0 right-0 bottom-0 p-[30px] px-[50px] md:px-[80px]" />
    </div>
  );
};

export default HowToDownload;
