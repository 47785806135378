import { AxiosError } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import { httpClient } from '../../api/http/http-client';
import { useSnackbar } from '../../contexts/SnackbarContext';

interface GradeData {
  grade: string;
}

interface MotivationsData {
  motivations: (string | null)[];
}

export const getMotivations = async (): Promise<MotivationsData> => {
  return httpClient.get<void, MotivationsData>('/motivation');
};

export const setLinkExpired = async (): Promise<void> => {
  await httpClient.post('/student/link/expired');
};

const submitMotivationGoals = async (
  goals: MotivationsData,
): Promise<MotivationsData> => {
  return httpClient.post('/motivation', goals);
};
const submitGrade = async (grade: GradeData): Promise<GradeData> => {
  return httpClient.post('/student/update/grade', grade);
};

export const useUpdateMotivations = (callbacks: {
  onSuccess?: (data: MotivationsData) => void;
  onError?: (error: AxiosError<{ message: string | string[] }>) => void;
}) => {
  const { handleNetworkError } = useSnackbar();
  const mutation = useMutation(
    (goals: MotivationsData) => submitMotivationGoals(goals),
    {
      onSuccess: callbacks.onSuccess ?? (() => {}),
      onError: (error: AxiosError<{ message: string | string[] }>) => {
        handleNetworkError(error);
        callbacks?.onError?.(error);
      },
    },
  );

  return { mutation };
};
export const useUpdateGrade = (callbacks: {
  onSuccess?: (data: GradeData) => void;
  onError?: (error: AxiosError<{ message: string | string[] }>) => void;
}) => {
  const { handleNetworkError } = useSnackbar();
  const mutation = useMutation((grade: GradeData) => submitGrade(grade), {
    onSuccess: callbacks.onSuccess ?? (() => {}),
    onError: (error: AxiosError<{ message: string | string[] }>) => {
      handleNetworkError(error);
      callbacks?.onError?.(error);
    },
  });

  return { mutation };
};

export const useGetMotivations = () => {
  return useQuery(['motivations'], getMotivations, {
    refetchOnWindowFocus: false,
  });
};
