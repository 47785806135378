import { ResultStatus } from '../pages/TestResult/useGetTestResult';

export enum UserRole {
  PARENT = 'parent',
  STUDENT = 'student',
  ADMIN = 'admin',
}

export enum FilledBy {
  STUDENT = 'child',
  PARENT = 'parent',
}

export interface ResultCounts {
  isTestResultAvailable: boolean;
  testResultPercent: number;
  testResultStatus?: ResultStatus;
  filledBy?: FilledBy;
  resultUid?: string;
}

export interface ResultStatusData {
  intermediateResult?: ResultCounts;
  finalResult?: ResultCounts;
}

export interface StudentData {
  uid: string;
  userUid: string;
  birthday: string;
  email: string;
  firstName: string | null;
  fullName: string;
  gender: string;
  gradeLevel: number;
  linkCode: string;
  linkCodeExpired: boolean;
  phone: string | null;
  schoolName: string;
  schoolZipCode: string;
  isSubscriptionActive?: boolean;
  testResultData: ResultStatusData;
}
export interface UserProfile {
  uid: string;
  email: string;
  role: UserRole;
  firstName: string;
  lastName: string;
  fullName?: string;
  birthday: string;
  country: string;
  state: string;
  street: string;
  street2: string;
  zipCode: string;
  phone: string;
  childRelationship: string;
  createdAt: string;
  updatedAt: string;
  gender?: string;
  students: StudentData[];
  gradeLevel?: number;
  verified?: boolean;
  isSubscriptionActive?: boolean;
  testResultData: ResultStatusData;
}

export interface UserProfileWithToken extends UserProfile {
  accessToken: string;
}
