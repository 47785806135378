import { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SchoologyTokenStatus, verifyToken } from './useVerify';

export const SchoologyVerify: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const initialized = useRef(false);

  const [message, setMessage] = useState<string | null>(null);

  const realm_id = queryParams.get('realm_id');
  const oauth_token = queryParams.get('oauth_token');

  if (!realm_id || !oauth_token) {
    navigate('/');
  }

  const verify = () => {
    if (!initialized.current) {
      initialized.current = true;
      verifyToken({ realm_id, oauth_token })
        .then((response) => {
          if (response.status === SchoologyTokenStatus.VERIFIED) {
            setMessage(
              'You are successfully integrated with Schoology. You can close window and check Uluru assignments',
            );
            return;
          }

          setMessage(
            'There was an error verifying your token. Please try again.',
          );
        })
        .catch(() => {
          setMessage(
            'Something went wrong. Please close window and try again.',
          );
        });
    }
  };

  useEffect(() => {
    verify();
  }, [realm_id, oauth_token]);

  return (
    <div>
      <p>{message}</p>
    </div>
  );
};
