import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import EmailInput from '../../components/EmailInput';
import PasswordInput from '../../components/PasswordInput';
import React, { useEffect } from 'react';
import Input from '../../components/Input';
import DateInput from '../../components/DateInput';
import SelectInput from '../../components/SelectInput';
import CheckboxInput from '../../components/CheckboxInput';
import { Button } from 'primereact/button';
import {
  ChildRelationship,
  ParentData,
  useRegisterParent,
} from './useRegisterParent';
import {
  CountriesSelectItems,
  DefaultCountry,
} from '../../constants/countries';
import { onZipcodeChange } from '../../utils/zipcode';
import PhoneNumberInput from '../../components/PhoneNumberInput';
import { ErrorMessages } from '../../constants/messages';
import RegistrationProgress from '../../components/RegistrationProgress/RegistrationProgress';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useRegisterContext } from '../../layouts/RegisterLayout/RegisterContext';
import { credentialsService } from '../../utils/services/credentialsService';
import { emailValidation, passwordValidation } from '../../types/form';
import useAuth from '../../contexts/AuthContext';
import Copyright from '../../components/Copyright';
import { isEmpty } from 'lodash';
import { format } from 'date-fns';

interface RegisterFormFields {
  uid?: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  birthday: string;
  childRelationship: string;
  country: string;
  state?: string;
  street?: string;
  street2?: string;
  zipCode: string;
  phone: string;
  agreeToRegister: boolean;
  privacyPolicy: boolean;
}

const schema = yup.object().shape({
  email: emailValidation,
  password: passwordValidation,
  firstName: yup
    .string()
    .required(ErrorMessages.Required('First Name'))
    .max(80, ErrorMessages.TooLong('First Name', 80)),
  lastName: yup
    .string()
    .required(ErrorMessages.Required('Last Name'))
    .max(80, ErrorMessages.TooLong('Last Name', 80)),
  birthday: yup.string().required(ErrorMessages.Required('Date of Birth')),
  childRelationship: yup
    .string()
    .required(ErrorMessages.Required('Relationship to Child'))
    .notOneOf(['Other'], ErrorMessages.Required('Relationship to Child'))
    .max(80, ErrorMessages.TooLong('Relationship to Child', 80)),
  country: yup.string().required(ErrorMessages.Required('Country')),
  state: yup.string().max(80, ErrorMessages.TooLong('State', 80)),
  street: yup.string().max(80, ErrorMessages.TooLong('Street', 80)),
  street2: yup.string().max(80, ErrorMessages.TooLong('Street 2', 80)),
  zipCode: yup
    .string()
    .required(ErrorMessages.Required('Zip Code'))
    .matches(/^\d{5}$/, 'Zip Code must be exactly 5 digits'),
  phone: yup
    .string()
    .max(15, ErrorMessages.Max('Phone', 15))
    .required(ErrorMessages.Required('Phone')),
  agreeToRegister: yup
    .boolean()
    .default(false)
    .oneOf([true], 'You must agree to register'),
  privacyPolicy: yup
    .boolean()
    .default(false)
    .oneOf([true], 'You must accept the privacy policy'),
});

export const minBirthdayDate = new Date(
  new Date().setFullYear(new Date().getFullYear() - 120),
);

export const maxBirthdayDate = new Date(
  new Date().setFullYear(new Date().getFullYear() - 18),
);

const RegisterParent: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get('code');
  const { registrationSteps, goToNextStep, updateRegistrationSteps } =
    useRegisterContext();
  updateRegistrationSteps(1);

  const { user, loadUser, logout } = useAuth();
  const navigate = useNavigate();

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      country: DefaultCountry,
      agreeToRegister: false,
      privacyPolicy: false,
    },
  });
  const { handleSubmit, formState, trigger, clearErrors } = formMethods;

  const { mutation } = useRegisterParent({
    onSuccess: (data) => {
      credentialsService.set({ token: data.accessToken });

      loadUser();

      goToNextStep();
    },
  });

  useEffect(() => {
    if (user) {
      formMethods.reset(user);
    }
  }, [user, formMethods]);

  const onSubmit: SubmitHandler<RegisterFormFields> = (data) => {
    const parentData: ParentData = {
      email: data.email,
      password: data.password,
      firstName: data.firstName,
      lastName: data.lastName,
      birthday: format(new Date(data.birthday), 'yyyy-MM-dd'),
      childRelationship: data.childRelationship,
      country: data.country,
      state: data.state,
      street: data.street,
      street2: data.street2,
      zipCode: data.zipCode,
      phone: data.phone.replace(/\D/g, ''),
      verificationLink: window.location.origin + '/login',
      linkCode: code,
    };
    if (user) {
      parentData.uid = user.uid;
    }
    mutation.mutate(parentData);
  };

  const hasError = !isEmpty(formState.errors);

  return (
    <div className="relative pb-[80px] bg-white p-[30px] px-[50px] md:p-20 h-full">
      <div className="w-fit">
        <RegistrationProgress registrationSteps={registrationSteps} />
      </div>
      <h2 className="text-2xl md:text-4xl">
        Parent Registration: <br /> Create Your Family Account
      </h2>
      <p className="text-lg pb-8">
        Let's get you and your family started on ULURU!
      </p>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-[30px]">
            <EmailInput name="email" placeholder="Email" />
            <PasswordInput name="password" placeholder="Password" />
            <div className="flex flex-col md:flex-row gap-[30px] md:gap-3">
              <div className="flex-1">
                <Input name="firstName" placeholder="First Name" />
              </div>
              <div className="flex-1">
                <Input name="lastName" placeholder="Last Name" />
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-[30px] md:gap-3">
              <div className="flex-1">
                <DateInput
                  name="birthday"
                  placeholder="Date of Birth"
                  minDate={minBirthdayDate}
                  maxDate={maxBirthdayDate}
                />
              </div>
              <div className="flex-1">
                <SelectInput
                  name="childRelationship"
                  placeholder="Relationship to Child"
                  otherOption
                  otherOptionPlaceholder="Enter relationship"
                  options={ChildRelationship}
                />
              </div>
            </div>
            <h3 className="text-2xl m-0">Address</h3>
            <div className="flex flex-col md:flex-row gap-[30px] md:gap-3">
              <div className="flex-1">
                <SelectInput
                  name="country"
                  placeholder="Country"
                  options={CountriesSelectItems}
                />
              </div>
              <div className="flex-1">
                <Input name="state" placeholder="State" />
              </div>
            </div>
            <Input name="street" placeholder="Street" />
            <Input name="street2" placeholder="Street 2" />
            <div className="flex flex-col md:flex-row gap-[30px] md:gap-3">
              <div className="flex-1">
                <Input
                  name="zipCode"
                  placeholder="Zip Code"
                  onChange={(e) => {
                    onZipcodeChange(e);
                    clearErrors('zipCode');
                  }}
                />
              </div>
              <div className="flex-1">
                <PhoneNumberInput name="phone" />
              </div>
            </div>
            <CheckboxInput
              name={'agreeToRegister'}
              onChange={() => trigger('agreeToRegister')}
            >
              I agree to register my children with ULURU.
            </CheckboxInput>
            <CheckboxInput
              name={'privacyPolicy'}
              onChange={() => trigger('privacyPolicy')}
            >
              <Link to="/privacy-policy" className="text-sm md:text-base">
                Privacy Policy and Terms of Service
              </Link>
            </CheckboxInput>
            <div className="flex justify-between flex-col md:flex-row gap-[30px] md:gap-3 items-center">
              <Button
                type="submit"
                className={`${
                  hasError ? 'error' : ''
                } p-[12px] text-center flex justify-center gap-[10px] text-sm md:text-base w-full md:w-[150px]`}
                disabled={hasError}
                loading={mutation.isLoading}
              >
                Sign Up
              </Button>
              <Button
                type="button"
                className="p-0 text-center flex justify-center text-sm md:text-base h-full"
                link
                onClick={async () => {
                  await logout();
                  navigate('/login');
                }}
              >
                Already have an Account?
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
      <Copyright className="absolute left-0 right-0 bottom-0 p-[30px] px-[50px] md:px-[80px]" />
    </div>
  );
};
export default RegisterParent;
