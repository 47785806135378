import { ChangeEvent } from 'react';
import { zipCodeRegex } from '../constants/regexp';

export const onZipcodeChange = (e: ChangeEvent<HTMLInputElement>) => {
  const currentValue = e.target.value;

  if (!zipCodeRegex.test(currentValue)) {
    e.target.value = currentValue.slice(0, -1); // Remove the last character if it doesn't match the pattern
  }
};
