import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { httpClient } from '../api/http/http-client';
import { UserProfile, UserRole } from '../types/UserProfile';
import { AxiosError } from 'axios';
import { credentialsService } from '../utils/services/credentialsService';
import usePushNotifications from '../hooks/usePushNotifications';
import { EMAIL_BANNER_STORAGE_KEY } from '../components/VerifyEmailBanner/VerifyEmailBanner';

interface AuthContextType {
  user?: UserProfile;
  isLoading: boolean;
  loadUser: () => void;
  logout: () => Promise<void>;
  checkSubscription: (studentId?: string) => boolean;
}

export const getProfile = () => {
  return httpClient.get<void, UserProfile>('/auth/profile');
};
export const getPermissions = () => {
  return httpClient.get<void, boolean>('/subscriptions/check');
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [user, setUser] = useState<UserProfile>();
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {
    subscribe: subscribeNotifications,
    unsubscribe: unsubscribeNotifications,
  } = usePushNotifications();

  const loadUser = async () => {
    if (!credentialsService.token) {
      await unsubscribeNotifications();
      setUser(undefined);
      setIsLoading(false);
      credentialsService.setUser(null);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    try {
      const profileResponse = await getProfile();
      setUser(profileResponse);
      credentialsService.setUser(profileResponse);
    } catch (error) {
      const message =
        (error as AxiosError<{ message: string }>).response?.data?.message ||
        (error as Error).message;
      setError(message);
      setUser(undefined);
      credentialsService.setUser(null);
    } finally {
      setIsLoading(false);
    }
  };

  const checkSubscription = (studentId?: string) => {
    if (user?.role === UserRole.PARENT) {
      if (studentId) {
        return !!user.students?.find((s) => s.uid === studentId)
          ?.isSubscriptionActive;
      } else {
        return user.students?.some((s) => !!s.isSubscriptionActive);
      }
    } else {
      return !!user?.isSubscriptionActive;
    }
  };

  const logout = async () => {
    await unsubscribeNotifications();
    setUser(undefined);
    credentialsService.setUser(null);
    credentialsService.flush();
    // reset email banner data
    localStorage.removeItem(EMAIL_BANNER_STORAGE_KEY);
  };

  useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    if (user?.role === UserRole.STUDENT) {
      subscribeNotifications();
    }
  }, [user]);

  const memoedValue = useMemo(
    () => ({
      user,
      isLoading,
      error,
      loadUser,
      logout,
      checkSubscription,
    }),
    [user, isLoading, error],
  );

  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
};

export default function useAuth() {
  return useContext(AuthContext);
}
