import React, { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../contexts/AuthContext';
import ProfileLayout from '../../layouts/ProfileLayout';
import { useGetFamilyProfile } from './useGetFamilyProfile';
import { UserRole } from '../../types/UserProfile';
import { Button } from 'primereact/button';
import ProfileInfo from '../../components/ProfileInfo';
import { ProfileInfoEdit } from '../../components/ProfileInfoEdit/ProfileInfoEdit';
import { getHomePath } from '../../utils/getHomePath';
import PlusIcon from '../../icons/PlusIcon';
import AddStudentDialog from '../../components/AddStudentDialog/AddStudentDialog';

const InfoRow = ({ label, value }: { label: string; value?: string }) => {
  return (
    <div className="flex justify-between text-base">
      <div>{label}</div>
      <div
        style={{
          flexGrow: 1,
          borderBottom: '1px dotted #b3b9c3',
          height: '17px',
          margin: '0 7px',
        }}
      ></div>
      <div>{value ?? ''}</div>
    </div>
  );
};

export const Profile: FC = () => {
  const { user } = useAuth();
  const { data: profileData, refetch } = useGetFamilyProfile();
  const [editMode, setEditMode] = useState(false);
  const [addStudentDialog, setAddStudentDialog] = useState(false);
  const isParent = user?.role === UserRole.PARENT;

  return (
    <ProfileLayout
      breadcrumbValues={[
        {
          label: 'Home',
          href: getHomePath(user),
        },
        { label: 'Profile', href: '#' },
      ]}
    >
      <AddStudentDialog open={addStudentDialog} setOpen={setAddStudentDialog} />
      <div className="md:mt-[40px] mb-[50px] flex justify-between ">
        <h3 className="m-0 text-2xl md:text-4xl text-header-color flex-shrink-0">
          {editMode ? 'Edit Family Profile' : 'Family info'}
        </h3>
        {isParent && (
          <div className="flex gap-[8px] flex-wrap justify-end">
            <Button
              className="p-button-secondary rounded-lg  text-center flex items-center justify-center gap-[10px]"
              color="info"
              onClick={() => {
                setAddStudentDialog(true);
              }}
            >
              <div>Add student</div>
              <PlusIcon />
            </Button>
            {editMode ? (
              <></>
            ) : (
              <Button
                className="text-base flex gap-[10px] items-center"
                onClick={() => setEditMode(true)}
              >
                <div>Edit</div>
                <i className="pi pi-pencil" />
              </Button>
            )}
          </div>
        )}
      </div>
      {!editMode && <ProfileInfo profileData={profileData} />}
      {editMode && profileData && (
        <ProfileInfoEdit
          profileData={profileData}
          onCancel={() => {
            setEditMode(false);
          }}
          onProfileUpdated={() => {
            refetch();
            setEditMode(false);
          }}
        />
      )}
    </ProfileLayout>
  );
};
