import { useMutation } from '@tanstack/react-query';
import { httpClient } from '../../../api/http/http-client';
import { AxiosError } from 'axios';
import { useSnackbar } from '../../../contexts/SnackbarContext';

interface SetPasswordRequest {
  newPassword: string;
}

const setPassword = async (data: SetPasswordRequest): Promise<boolean> => {
  return httpClient.post('/auth/change-password/', {
    newPassword: data.newPassword,
  });
};

export const useSetPasswordForUser = (callbacks: {
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const { handleNetworkError } = useSnackbar();

  const mutation = useMutation(
    (data: SetPasswordRequest) => setPassword(data),
    {
      onSuccess: callbacks.onSuccess,
      onError: (error: AxiosError<{ message: string | string[] }>) => {
        handleNetworkError(error);
        callbacks?.onError?.();
      },
    },
  );

  return { mutation };
};
