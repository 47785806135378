import React, { FC, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import StartNeurotest from '../../components/StartNeurotest';
import useAuth from '../../contexts/AuthContext';
import { useGetTestProgress } from '../NeuropsychTest/useGetTestProgress';
import { HomeAssignmentsList } from '../../components/HomeAssignmentsList/HomeAssignmentsList';
import { ListOfAssignmentsButton } from 'src/app/components/ListOfAssignmentsButton/ListOfAssignmentsButton';
import useSelectedStudent from '../../contexts/SelectedStudentContext';

export const ParentHome: FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { selectedStudentUid, setSelectedStudentUid, selectedStudent } =
    useSelectedStudent();
  const { data: progressData } = useGetTestProgress(selectedStudentUid);

  const studentIndex = useMemo(() => {
    return user?.students?.findIndex((s) => s.uid === selectedStudentUid);
  }, [user?.students, selectedStudentUid]);
  return (
    <>
      <div className="bg-grey2 w-full">
        <div className="flex pt-[30px] px-[17px] md:px-[130px] gap-[10px] overflow-x-auto">
          {user?.students &&
            user.students.map((student, i) => (
              <div
                className={`${
                  student.uid === selectedStudentUid ? 'bg-white' : ''
                } rounded-t-[8px] flex py-[12px] px-[20px] gap-[10px] cursor-pointer`}
                onClick={() => setSelectedStudentUid(student.uid)}
              >
                <img
                  src={
                    student.isSubscriptionActive
                      ? '/images/green-profile-pic.svg'
                      : '/images/default-profile-pic.svg'
                  }
                  alt=""
                />
                <div className="max-w-[300px]">
                  <div className="text-xs text-grey3">Student {i + 1}</div>
                  <div className="text-base text-header-color truncate">
                    {student.fullName}
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="bg-white h-full w-full py-[30px] px-[17px] md:py-[30px] lg:px-[130px]">
          <div className="flex flex-col items-stretch md:flex-row gap-[30px]">
            {selectedStudentUid ? (
              <HomeAssignmentsList studentId={selectedStudentUid} />
            ) : (
              <div className="min-h-[100vh] md:min-h-fit"></div>
            )}

            <div className="flex flex-col gap-[20px] w-full md:w-[300px] xl:w-[373px] shrink-0 ml-auto">
              {progressData?.isTestCompleted &&
              !progressData.resultStatusData?.finalResult?.testResultStatus ? (
                false
              ) : (
                <StartNeurotest
                  onStartClick={() => {
                    if (progressData?.testStatus === 'IN_PROGRESS') {
                      navigate(`/parent/neuro-test/${selectedStudentUid}`);
                    } else {
                      navigate(
                        `/parent/neuro-test/${selectedStudentUid}/intro`,
                      );
                    }
                    window.scrollTo(0, 0);
                  }}
                  completionPercentage={progressData?.percent}
                  testStatus={progressData?.testStatus}
                  testResultStatus={
                    progressData?.resultStatusData?.finalResult
                      ?.testResultStatus
                  }
                  testResultPercent={
                    progressData?.resultStatusData?.finalResult
                      ?.testResultPercent
                  }
                  studentNumber={
                    studentIndex !== undefined ? studentIndex + 1 : 0
                  }
                  onSummaryClick={() => {
                    navigate(
                      `/parent/neuro-test/result/${
                        progressData?.resultStatusData?.finalResult
                          ? progressData?.resultStatusData?.finalResult
                              .resultUid
                          : progressData?.resultStatusData?.intermediateResult
                              ?.resultUid
                      }`,
                    );
                    window.scrollTo(0, 0);
                  }}
                  studentId={selectedStudentUid}
                />
              )}
              <ListOfAssignmentsButton
                link={`/parent/assignments/${selectedStudentUid}`}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
