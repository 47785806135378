import RegistrationProgress from '../../components/RegistrationProgress/RegistrationProgress';
import React, { useEffect, useRef, useState } from 'react';
import RegisterStudentForm from '../../components/RegisterStudentForm';
import { Button } from 'primereact/button';
import {
  RegisterStudentFormRef,
  StudentFields,
} from '../../components/RegisterStudentForm/RegisterStudentForm';
import PreviousNextButtons from '../../components/PreviousNextButtons/PreviousNextButtons';
import { useRegisterContext } from '../../layouts/RegisterLayout/RegisterContext';
import {
  getRegisteredStudents,
  useGetRegisteredStudents,
  useRegisterStudent,
} from './useRegisterStudent';
import Copyright from '../../components/Copyright';
import { format } from 'date-fns';
import { useGetCreatedBindings } from './useGetCreatedBindings';
import { sortBy } from 'lodash';

const RegisterFormStep: React.FC = () => {
  const {
    registrationSteps,
    goToNextStep,
    goToPrevStep,
    updateRegistrationSteps,
  } = useRegisterContext();
  updateRegistrationSteps(2);

  const { data: bindingsData, isLoading: isLoadingBindings } =
    useGetCreatedBindings({});
  const { data: registeredStudentsData, isLoading: isLoadingStudents } =
    useGetRegisteredStudents();

  const [studentForms, setStudentForms] = useState<
    {
      id: number;
      ref: RegisterStudentFormRef | null;
      defaultValues?: StudentFields;
    }[]
  >([{ id: 0, ref: null }]);
  const [formValidity, setFormValidity] = useState<
    { id: number; isValid: boolean }[]
  >([
    {
      id: 0,
      isValid: false,
    },
  ]);
  const shouldRedirect = useRef<boolean>(false);

  const { mutation } = useRegisterStudent({
    onSuccess: () => {
      if (shouldRedirect.current) {
        goToNextStep();
      }
    },
    onError: () => {
      shouldRedirect.current = false;
    },
  });

  const handleValidityChange = (id: number, isValid: boolean) => {
    setFormValidity(
      formValidity.map((form) =>
        form.id === id ? { ...form, isValid } : form,
      ),
    );
  };

  const handleAddStudentClick = async () => {
    await submitForm();

    setStudentForms([
      ...studentForms,
      { id: studentForms[studentForms.length - 1].id + 1, ref: null },
    ]);
    setFormValidity([
      ...formValidity,
      { id: studentForms[studentForms.length - 1].id + 1, isValid: false },
    ]);

    // setShouldRedirect(false);
    shouldRedirect.current = false;
  };

  const submitForm = async () => {
    const promises = studentForms.map((studentForm) => {
      return new Promise<StudentFields | null>((resolve) => {
        studentForm?.ref?.handleSubmit
          ? studentForm?.ref?.handleSubmit?.((data: StudentFields) => {
              resolve(data);
            })()
          : resolve(null);
      });
    });

    const data = await Promise.all(promises);
    const filteredData = data.filter((d) => d !== null) as StudentFields[];
    // fix date
    const students = filteredData.map(
      (student) =>
        ({
          ...student,
          birthday: format(student.birthday, 'yyyy-MM-dd'),
        }) as StudentFields & { birthday: string },
    );
    mutation.mutate(students);
  };

  useEffect(() => {
    console.log(
      'bindingsData, registeredStudentsData',
      bindingsData,
      registeredStudentsData,
    );
    if (isLoadingBindings || isLoadingStudents) return;

    if (bindingsData?.length || registeredStudentsData?.length) {
      const bindingsValues: StudentFields[] = bindingsData?.map((binding) => ({
        email: binding.studentEmail,
        hasCreatedBinding: true,
      })) as StudentFields[];
      const studentsValues: StudentFields[] = registeredStudentsData?.map(
        (student) => ({
          ...student,
          birthday: new Date(student.birthday),
        }),
      ) as StudentFields[];
      const bindingsAndStudents = [
        ...(bindingsValues || []),
        ...(studentsValues || []),
      ];
      const sortedData = sortBy(bindingsAndStudents, 'createdAt');
      const studentsFields = sortedData.map((student, id) => ({
        id,
        ref: null,
        defaultValues: student,
      }));
      setStudentForms(studentsFields);
    }
  }, [bindingsData, registeredStudentsData]);

  return (
    <div className="relative pb-[80px] bg-white p-[30px] px-[50px] md:p-20 h-full">
      <div className="w-fit">
        <RegistrationProgress registrationSteps={registrationSteps} />
      </div>
      <h2 className="text-2xl md:text-4xl">Let's register your family!</h2>
      {studentForms.map((studentForm, index) => (
        <div key={studentForm.id}>
          <div
            className="text-sm md:text-base text-grey1 my-[20px] w-full border-grey1 flex justify-between"
            style={{ borderBottom: '1px solid #b3b9c3' }}
          >
            <div
              className="flex gap-[10px] w-fit text-header-color py-[10px] px-[20px]"
              style={{ borderBottom: '2px solid #468ed0' }}
            >
              Student {index + 1}
            </div>
            {index > 0 && (
              <i
                className="pi pi-trash text-2xl text-red1 cursor-pointer"
                onClick={() => {
                  setStudentForms(
                    studentForms.filter((f) => f.id !== studentForm.id),
                  );
                }}
              />
            )}
          </div>
          <RegisterStudentForm
            ref={(ref) => {
              studentForm.ref = ref;
            }}
            onValidityChange={(isValid) => {
              handleValidityChange(studentForm.id, isValid);
            }}
            defaultValues={studentForm.defaultValues}
          />
        </div>
      ))}
      <Button
        type="button"
        className="p-[12px] px-[40px] text-center flex justify-center text-sm md:text-base w-full my-[30px]"
        severity="secondary"
        onClick={handleAddStudentClick}
      >
        Add student <i className="pi pi-plus-circle ml-[10px] text-blue1" />
      </Button>
      <PreviousNextButtons
        nextStep={() => {
          shouldRedirect.current = true;
          submitForm();
        }}
        previousStep={async () => {
          goToPrevStep();
        }}
      />
      <Copyright className="absolute left-0 right-0 bottom-0 p-[30px] px-[50px] md:px-[80px]" />
    </div>
  );
};
export default RegisterFormStep;
