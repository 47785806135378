import { httpClient } from '../../api/http/http-client';
import { useQuery } from '@tanstack/react-query';
import { Assignment } from '../AssignmentsList/useGetAssignmentList';
import { useEffect, useState } from 'react';
import {
  AssignmentSuggestionsStatus,
  CoachingSuggestion,
} from '../CoachingSuggestionsModal/useGetCoaching';

export const getAssignment = async ({
  queryKey,
}: {
  queryKey: (string | undefined)[];
}): Promise<Assignment> => {
  const [, id] = queryKey;

  return httpClient.get<void, Assignment>(`/assignments/${id}`, {});
};

export const getCoaching = async ({ id }: { id?: string }) => {
  return httpClient.get<
    void,
    CoachingSuggestion[] | { suggestionsStatus: AssignmentSuggestionsStatus }
  >(`/coaching/${id}`, {});
};

export const useGetAssignment = ({ id }: { id?: string }) => {
  const [keepLoading, setKeepLoading] = useState(true);
  const useQueryResult = useQuery(['/assignments', id], getAssignment, {
    enabled: !!id,
    refetchInterval: keepLoading ? 3000 : false,
    retry: (failureCount, error) => {
      // @ts-ignore
      if (error.response.status === 403) {
        return false;
      }

      return failureCount < 3;
    },
  });

  useEffect(() => {
    setKeepLoading(
      !!(
        useQueryResult.data &&
        useQueryResult.data.suggestionsStatus !== 'completed'
      ),
    );
  }, [useQueryResult.data]);

  return {
    ...useQueryResult,
  };
};
