import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { httpClient } from '../../../api/http/http-client';
import { UserProfileWithToken } from '../../../types/UserProfile';

export interface UserCredentials {
  email: string;
  password: string;
}

interface VerificationCode {
  code: string;
}

type VerifySuccessResponse = {
  success: boolean;
  message: string;
};

const loginUser = async (userCredentials: UserCredentials) => {
  return httpClient.post<void, UserProfileWithToken>('/auth/signin', {
    ...userCredentials,
  });
};

const verifyUser = async (verificationCode: VerificationCode) => {
  return httpClient.post<void, VerifySuccessResponse>('/verification/verify', {
    ...verificationCode,
  });
};

export const useLogin = (callbacks: {
  onSuccess?: (data: UserProfileWithToken) => void;
  onError?: (error: AxiosError<{ message: string | string[] }>) => void;
}) => {
  const mutation = useMutation(
    (userCredentials: UserCredentials) => loginUser(userCredentials),
    {
      onSuccess: callbacks.onSuccess,
      onError: (error: AxiosError<{ message: string | string[] }>) => {
        callbacks?.onError?.(error);
      },
    },
  );

  return { mutation };
};

export const useVerifyUser = (callbacks: {
  onSuccess?: (data: string) => void;
  onError?: (error: string) => void;
}) => {
  const mutation = useMutation<
    VerifySuccessResponse,
    AxiosError<{ message: string }>,
    VerificationCode
  >((verificationCode: VerificationCode) => verifyUser(verificationCode), {
    onSuccess: (response) => {
      if (callbacks.onSuccess) {
        callbacks.onSuccess(response.message);
      }
    },
    onError: (error) => {
      if (callbacks.onError) {
        console.log('error', error?.response?.data.message);
      } else {
        console.error('Unexpected error:', error);
      }
    },
  });

  return { mutation };
};
