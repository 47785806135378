import React, { useEffect, useMemo, useState } from 'react';
import { getHomePath } from '../../utils/getHomePath';
import Breadcrumbs from '../Breadcrumbs';
import useAuth from '../../contexts/AuthContext';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import PlayIcon from '../../icons/PlayIcon';
import StrugglingBanner from '../StrugglingBanner';
import { getCoaching, useGetAssignment } from './useGetAssignment';
import { ProgressSpinner } from 'primereact/progressspinner';
import { formatDueDate } from '../AssignmentsListItem/AssignmentsListItem';
import { useUpdateAssignment } from './useUpdateAssignment';
import {
  AssignmentStatus,
  AttachmentFile,
} from '../AssignmentsList/useGetAssignmentList';
import { useStartAssignment } from './useStartAssignment';
import { useCompleteAssignment } from './useCompleteAssignment';
import { CoachingSuggestionsModal } from '../CoachingSuggestionsModal/CoachingSuggestionsModal';
import { UserRole } from '../../types/UserProfile';
import AttachmentFileItem from '../AttachmentFileItem';
import InfoComponent from '../InfoComponent';
import { ActivitySelectItem, getActivitiesBySubject } from './useGetActivities';
import { getSubjects, SubjectSelectItem } from './useGetSubjects';
import AssignmentTypeBadge from '../AssignmentTypeBadge/';
import { AssignmentTypes } from '../AssignmentTypeBadge/AssignmentTypeBadge';
import DOMPurify from 'dompurify';
import { trimStart } from 'lodash';

const CompleteButtonClassName =
  'text-base flex gap-[10px] py-[12px] px-[45px] w-full justify-center md:w-fit items-center my-[20px] md:my-0';

export const AssignmentDetailsPage = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [subject, setSubject] = useState<string>();
  const [activity, setActivity] = useState<string>();
  const [assignmentStatus, setAssignmentStatus] = useState<AssignmentStatus>(
    AssignmentStatus.NOT_STARTED,
  );
  const [attachments, setAttachments] = useState<AttachmentFile[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLMSAssignment, setIsLMSAssignment] = useState<boolean>(false);
  const [completeAssignmentErrorMessage, setCompleteAssignmentErrorMessage] =
    useState<string>('');

  const { mutation: updateAssignmentMutation } = useUpdateAssignment({
    assignmentId: id,
  });
  const { mutation: startAssignmentMutation } = useStartAssignment({
    assignmentId: id,
    onSuccess: () => {
      setAssignmentStatus(AssignmentStatus.IN_PROGRESS);
    },
  });

  const { mutation: completeAssignmentMutation } = useCompleteAssignment({
    assignmentId: id,
    onSuccess: () => {
      setAssignmentStatus(AssignmentStatus.COMPLETED);
    },
  });

  const { data, isLoading, error } = useGetAssignment({ id });
  const [subjectList, setSubjectList] = useState<SubjectSelectItem[]>([]);
  const [activityList, setActivityList] = useState<ActivitySelectItem[]>([]);

  const updateSubjects = async () => {
    const subjects = await getSubjects();
    setSubjectList(subjects);
  };

  const setActivityListForSubject = useMemo(
    () => async (subject: string) => {
      if (subject) {
        const activityList = await getActivitiesBySubject(subject);
        setActivityList(activityList || []);
      }
    },
    [],
  );

  useEffect(() => {
    if (data) {
      updateSubjects()
        .then(() => {
          setSubject(data.subject);
          setActivityListForSubject(data.subject);
          setActivity(data.activity);
          setAssignmentStatus(data.status);
          setAttachments(data.attachments || []);
          setIsLMSAssignment(data.type !== AssignmentTypes.MANUAL);

          let lmsType = '';
          if (data.type === AssignmentTypes.GCR) {
            lmsType = 'Google Classroom';
          }
          if (data.type === AssignmentTypes.BLACKBAUD) {
            lmsType = 'Blackbaud';
          }
          if (data.type === AssignmentTypes.SCHOOLOGY) {
            lmsType = 'Schoology';
          }
          if (data.type === AssignmentTypes.INFINITE_CAMPUS) {
            lmsType = 'Infinity Campus';
          }
          if (data.type === AssignmentTypes.CANVAS) {
            lmsType = 'Canvas LMS';
          }

          setCompleteAssignmentErrorMessage(
            `Please turn in this assignment via ${lmsType}`,
          );
        })
        .catch(() => {});

      getCoaching({ id }).catch(() => {});
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      navigate(getHomePath(user));
    }
  }, [error]);

  const isDueDateInFuture =
    data?.dueDate && new Date() < new Date(data.dueDate);

  return (
    <div className="bg-white h-full w-full px-[17px] pb-[30px] md:py-[30px] lg:px-[130px]">
      {data?.uid && (
        <CoachingSuggestionsModal
          onClose={() => setIsModalOpen(false)}
          open={isModalOpen}
          subject={subject || ''}
          assignmentUid={data?.uid}
        />
      )}
      <div className="flex flex-col gap-[30px]">
        <Breadcrumbs
          className="py-[30px]"
          values={[
            {
              label: 'Home',
              href: getHomePath(user),
            },
            {
              label: data?.title || '',
              href: '#',
            },
          ]}
        />
        <div className="flex flex-col md:flex-row gap-[30px]">
          {isLoading ? (
            <ProgressSpinner className="m-auto" />
          ) : (
            data && (
              <div className="flex flex-col gap-[10px] md:gap-[30px] w-full">
                <div className="flex gap-[20px] justify-between items-center mb-[10px]">
                  <img
                    src="/images/icon-park-outline-bookmark-three.svg"
                    className="bg-blue4 p-[9px] rounded-[10px]"
                  />
                  <div className="flex flex-col gap-[8px] mr-auto">
                    <div className="text-sm">
                      {data.subject} <AssignmentTypeBadge type={data.type} />
                    </div>
                    <h3
                      className={`text-[32px] md:text-4xl font-bold ${
                        data.title.includes(' ') ? 'break-words' : 'break-all'
                      } ${
                        data.status === AssignmentStatus.COMPLETED &&
                        isDueDateInFuture
                          ? 'line-through'
                          : ''
                      }`}
                    >
                      {data.title}
                    </h3>
                    <div className="text-xs md:hidden block">
                      {data.dueDate && formatDueDate(data.dueDate)}
                    </div>
                  </div>
                  <div className="whitespace-nowrap text-base shrink-0 hidden md:block">
                    {data.dueDate && formatDueDate(data.dueDate)}
                  </div>
                </div>
                <div className="flex gap-[10px] md:gap-[30px] md:flex-row flex-col grow-0 flex-wrap">
                  {!isLMSAssignment && (
                    <div className="flex items-center gap-[20px] w-full md:w-fit">
                      <label
                        htmlFor="subject"
                        className="text-base text-grey3 whitespace-nowrap"
                      >
                        Subject:
                      </label>
                      <Dropdown
                        id="subject"
                        placeholder="Subject"
                        options={subjectList}
                        className="w-full md:w-fit lg:min-w-[180px]"
                        value={subject}
                        onChange={(e) => {
                          updateAssignmentMutation.mutate({
                            subject: e.value,
                            activity: '',
                          });
                          setSubject(e.value);
                          setActivityListForSubject(e.value);
                          setActivity('');
                        }}
                      />
                    </div>
                  )}
                  {isLMSAssignment && activity && (
                    <div className="flex items-center gap-[20px] w-full md:w-fit">
                      <label
                        htmlFor="activity"
                        className="text-base text-grey3 whitespace-nowrap"
                      >
                        Activity:
                      </label>
                      <b>{activity}</b>
                    </div>
                  )}
                  {!isLMSAssignment && activityList.length ? (
                    <div className="flex items-center gap-[20px] w-full md:w-fit">
                      <label
                        htmlFor="activity"
                        className="text-base text-grey3 whitespace-nowrap"
                      >
                        Activity:
                      </label>
                      <Dropdown
                        id="activity"
                        placeholder="Activity"
                        options={activityList}
                        className="w-full md:w-fit lg:min-w-[180px]"
                        value={activity}
                        disabled={isLMSAssignment}
                        onChange={(e) => {
                          if (subject) {
                            updateAssignmentMutation.mutate({
                              subject,
                              activity: e.value,
                            });
                            setActivity(e.value);
                          }
                        }}
                      />
                    </div>
                  ) : (
                    false
                  )}
                </div>

                <div className="">
                  <div className="flex gap-[10px] mb-[10px] flex-wrap">
                    {attachments?.map((attachment) => (
                      <AttachmentFileItem
                        thumb={attachment.thumb}
                        title={attachment.title}
                        url={attachment.url}
                        type={attachment.type}
                      />
                    ))}
                  </div>

                  {data.type === AssignmentTypes.CANVAS ||
                  data.type === AssignmentTypes.BLACKBAUD ? (
                    <div
                      className="m-0 text-sm md:text-base"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data.description),
                      }}
                    />
                  ) : (
                    <p className="m-0 text-sm md:text-base whitespace-pre-line">
                      {trimStart(data.description || '')}
                    </p>
                  )}
                </div>
                {user?.role === UserRole.STUDENT && (
                  <>
                    {assignmentStatus === AssignmentStatus.NOT_STARTED && (
                      <Button
                        loading={startAssignmentMutation.isLoading}
                        className={CompleteButtonClassName}
                        onClick={() => {
                          setIsModalOpen(true);
                          startAssignmentMutation.mutate();
                        }}
                      >
                        Start
                        <PlayIcon />
                      </Button>
                    )}

                    {assignmentStatus === AssignmentStatus.IN_PROGRESS && (
                      <>
                        <Button
                          loading={completeAssignmentMutation.isLoading}
                          className={CompleteButtonClassName}
                          onClick={() => {
                            if (!isLMSAssignment) {
                              completeAssignmentMutation.mutate();
                            }
                          }}
                          disabled={isLMSAssignment}
                        >
                          Complete
                        </Button>
                        {isLMSAssignment && (
                          <InfoComponent
                            text={completeAssignmentErrorMessage}
                          />
                        )}
                      </>
                    )}

                    {assignmentStatus === AssignmentStatus.COMPLETED && (
                      <Button
                        className={`${CompleteButtonClassName} !bg-grey4 !border-none`}
                        disabled
                      >
                        Completed
                        <i className="pi pi-check" />
                      </Button>
                    )}
                  </>
                )}
              </div>
            )
          )}

          <div className="flex flex-col gap-[20px] w-full md:w-[300px] xl:w-[373px] shrink-0">
            <StrugglingBanner
              onGetSuggestionClick={() => setIsModalOpen(true)}
              isGeneral={data?.isGeneral}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
