import React, { FC, useEffect, useState } from 'react';
import useAuth from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../../contexts/SnackbarContext';
import TrashIcon from '../../../icons/TrashIcon';
import { Dialog } from 'primereact/dialog';
import { useCanvasLink } from './useGetCanvasLink';
import { ExternalPopup } from '../../../components/ExternalPopup/useExternalPopup';
import { useUnlinkCanvas } from './useUnlinkCanvas';

export interface Props {
  studentUid: string;
}

export const CanvasComponent: FC<Props> = ({ studentUid }) => {
  const { openSnackbar } = useSnackbar();

  const { data: canvasLinkData, refetch } = useCanvasLink({ studentUid });

  const [unlinkStudentVisibility, setUnlinkStudentVisibility] =
    useState<boolean>(false);
  const unlinkCanvas = useUnlinkCanvas({
    onSuccess: () => {
      refetch();
      setUnlinkStudentVisibility(false);
      openSnackbar({
        type: 'success',
        message: 'The Uluru account has been unlinked from Canvas LMS account.',
      });
    },
  });

  const login = async () => {
    if (canvasLinkData?.url) {
      await ExternalPopup(canvasLinkData.url);
    }
  };

  return (
    <>
      {canvasLinkData?.isLinkAvailable && (
        <>
          <button
            type="submit"
            className="p-button green-700 p-component p-[12px] px-[40px] text-center text-sm md:text-base"
            onClick={() => login()}
          >
            Link Canvas LMS account
          </button>
        </>
      )}
      {canvasLinkData?.isLinked && (
        <>
          <p className={'mt-2'}>
            This Uluru account has been already connected to Canvas LMS account.{' '}
            <a
              href="javascript:void(0)"
              onClick={(e) => {
                e.preventDefault();
                setUnlinkStudentVisibility(true);
              }}
            >
              <div className={'inline ml-1 relative top-1'}>
                <TrashIcon />
              </div>{' '}
              Unlink
            </a>
          </p>
          <Dialog
            header="Unlink Confirmation"
            visible={unlinkStudentVisibility}
            onHide={() => setUnlinkStudentVisibility(false)}
            blockScroll={true}
            contentStyle={{ overflow: 'auto' }}
          >
            <p className={'text-center'}>
              Are you sure want to unlink the Uluru account from Canvas LMS
              account?
            </p>
            <button
              type="button"
              onClick={() => {
                unlinkCanvas.mutate({ studentUid });
              }}
              className="p-button p-component float-right p-[12px] px-[40px] text-center flex justify-center align-right text-sm md:text-base mt-7"
            >
              Confirm
            </button>
            <button
              type="button"
              onClick={() => {
                setUnlinkStudentVisibility(false);
              }}
              className="p-button p-component float-left p-[12px] px-[40px] text-center flex justify-center align-right text-sm md:text-base mt-7"
            >
              Cancel
            </button>
          </Dialog>
        </>
      )}
    </>
  );
};
