import type { FC } from 'react';
import { memo } from 'react';
import { useDrop } from 'react-dnd';

export interface DustbinProps {
  item: string | undefined | null;
  onDrop: (data: { name: string }) => void;
  onRemove: () => void;
  dustbinClassName?: string;
}

export const Dustbin: FC<DustbinProps> = memo(function Dustbin({
  item,
  onDrop,
  onRemove,
  dustbinClassName = '',
}) {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ['goal'],
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;
  return (
    <div ref={drop} onClick={onRemove}>
      <div
        data-testid="dustbin"
        className={`relative flex items-center box-border font-work-sans font-medium z-10 text-center text-sm rounded-[20px] p-[12px] ${dustbinClassName} ${
          item && !isOver ? 'bg-green1 text-white' : 'bg-grey2 text-grey4'
        } ${
          isOver && 'bg-white text-grey4 border-dashed border-[1px] !p-[11px]'
        } dustbin-item min-[410px]:min-w-[184px]`}
      >
        {!isActive && item ? (
          <>
            <div className="w-full grow">{item}</div>
            <i className="cursor-pointer color-white pi pi-times py-[3px] pl-[7px]"></i>
          </>
        ) : (
          <div className="w-full grow">Drag Goal here</div>
        )}
      </div>
    </div>
  );
});
