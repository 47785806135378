import React, { FC, useRef, useState } from 'react';
import AssignmentsList from '../AssignmentsList';
import CreateAssignmentModal from '../CreateAssignmentModal';

export const SkeletonAssignmentItems = ({ number = 3 }) => {
  return (
    <div className="flex flex-col gap-[20px] w-full mt-[10px]">
      {Array.from({ length: number }).map((_, index) => (
        <div className="w-full flex flex-col gap-[17px] items-center bg-grey2 h-[73px] rounded-[10px]"></div>
      ))}
    </div>
  );
};

export const HomeAssignmentsList: FC<{ studentId: string }> = ({
  studentId,
}) => {
  const [createAssignmentOpen, setCreateAssignmentOpen] = useState(false);
  const assignmentListRef = useRef<{ refetch: () => void }>(null);
  return (
    <div className="w-full">
      <CreateAssignmentModal
        studentUid={studentId}
        open={createAssignmentOpen}
        onClose={() => {
          assignmentListRef.current?.refetch();
          setCreateAssignmentOpen(false);
        }}
      />
      <AssignmentsList
        ref={assignmentListRef}
        key={studentId}
        title="To Do List"
        displayAddAssignmentButtonInBottom
        displayTodoListFilters
        onAddAssignmentClick={() => {
          setCreateAssignmentOpen(true);
        }}
        studentId={studentId}
        excludePastCompleted
        displayHomePageBanners
      />
    </div>
  );
};
