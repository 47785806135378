import { httpClient } from '../../api/http/http-client';
import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { ResultStatus } from '../TestResult/useGetTestResult';
import { ResultStatusData } from '../../types/UserProfile';

export enum TestStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export interface ProgressData {
  total?: number;
  completed?: number;
  percent?: number;
  isTestStarted?: boolean;
  isTestCompleted?: boolean;
  testStatus?: TestStatus;
  resultStatusData?: ResultStatusData;
}

export const getTestProgress = async ({
  queryKey,
}: {
  queryKey: (string | undefined)[];
}): Promise<ProgressData> => {
  const [, studentUid] = queryKey;
  return httpClient.get<void, ProgressData>('/tests/progress', {
    params: { studentUid },
  });
};

export const useGetTestProgress = (studentUid?: string) => {
  const [keepLoading, setKeepLoading] = useState(true);

  const useQueryResult = useQuery(
    ['/tests/progress', studentUid],
    getTestProgress,
    {
      refetchInterval: keepLoading ? 3000 : false,
    },
  );

  useEffect(() => {
    if (
      useQueryResult.data?.isTestCompleted &&
      useQueryResult.data.resultStatusData?.finalResult &&
      useQueryResult.data.resultStatusData?.finalResult.testResultStatus !==
        ResultStatus.READY
    ) {
      setKeepLoading(true);
    } else {
      setKeepLoading(false);
    }
  }, [useQueryResult.data]);

  return {
    ...useQueryResult,
  };
};
