import { httpClient } from '../../api/http/http-client';
import { useQuery } from '@tanstack/react-query';

export interface Student {
  uid: string;
  fullName: string;
  linkCode: string;
  finishedRegistration: boolean;
}

export const getStudentsList = async (): Promise<Student[]> => {
  return httpClient.get<void, Student[]>('/parents/students');
};

export const useGetStudentsList = ({
  enabled = true,
}: {
  enabled?: boolean;
}) => {
  return useQuery(['students'], getStudentsList, {
    enabled,
  });
};
