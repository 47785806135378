import React, { FC } from 'react';

const BackgroundImage: FC<{
  desktopImage: string;
  mobileImage: string;
  fixed?: boolean;
}> = ({ desktopImage, mobileImage, fixed }) => {
  return (
    <>
      <div
        className="absolute bg-yellow2 top-0 left-0 w-full h-full hidden md:block"
        style={{
          backgroundImage: `url(${desktopImage})`,
          backgroundPosition: 'top',
          backgroundClip: 'border-box',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundOrigin: 'padding-box',
          backgroundAttachment: fixed ? 'fixed' : 'scroll',
          zIndex: -1,
        }}
      />
      <div
        className="absolute bg-yellow2 top-0 left-0 w-full h-full md:hidden visible"
        style={{
          backgroundImage: `url(${mobileImage})`,
          backgroundPosition: 'top',
          backgroundClip: 'border-box',
          backgroundSize: '100% auto',
          backgroundRepeat: 'no-repeat',
          backgroundOrigin: 'padding-box',
          zIndex: -1,
        }}
      />
    </>
  );
};

export default BackgroundImage;
