import React, { FC, useEffect, useState } from 'react';
import useAuth from '../../contexts/AuthContext';
import TrashIcon from '../../icons/TrashIcon';
import {
  getGcrLink,
  handleGoogleLogin,
  StudentGCRLink,
  unlinkGoogleLogin,
} from './useGoogleClassRoom';
import { UserRole } from '../../types/UserProfile';
import { Dialog } from 'primereact/dialog';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { useNavigate } from 'react-router-dom';
import InfoComponent from '../../components/InfoComponent';

export interface GcrProps {
  studentUid: string;
}

export const GcrComponent: FC<GcrProps> = ({ studentUid }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();

  const [gcrLink, setGcrLink] = useState<StudentGCRLink>();

  const [unlinkStudentVisibility, setUnlinkStudentVisibility] =
    useState<boolean>(false);

  const googleLogin = async (studentUid: string) => {
    await handleGoogleLogin(studentUid);
    const link = await updateList();

    if (!link?.isLinked) {
      openSnackbar({
        type: 'error',
        message: 'The Google Classroom account has not been linked.',
      });
      return;
    }

    openSnackbar({
      type: 'success',
      message: 'The Google Classroom account has been linked successfully.',
    });

    setTimeout(() => {
      if (user?.role === UserRole.STUDENT) navigate('/student/home');
      else navigate('/parent/home');
    }, 1000);
  };

  const unlinkGoogle = async (studentUid: string) => {
    await unlinkGoogleLogin(studentUid);
    updateList();
  };

  const toggleUnlinkPopup = (studentUid: string, open = false) => {
    setUnlinkStudentVisibility(open);
  };

  const updateList = async (): Promise<StudentGCRLink> => {
    return new Promise((resolve) => {
      getGcrLink(studentUid)
        .then((res) => {
          setGcrLink(res);
          setUnlinkStudentVisibility(false);
          resolve(res);
        })
        .catch(() => {});
    });
  };

  useEffect(() => {
    updateList();
  }, []);

  const displayPermissionText =
    user?.role === UserRole.STUDENT &&
    gcrLink &&
    gcrLink?.isLinked &&
    gcrLink?.invalid;

  return (
    <>
      {displayPermissionText && (
        <p>
          *Please give all permissions to Google by checking all boxes in the
          pop-up window
        </p>
      )}
      {!gcrLink?.isLinked && (
        <>
          <button
            type="submit"
            className="p-button p-component p-[12px] px-[40px] text-center text-sm md:text-base mt-2"
            onClick={() => googleLogin(studentUid)}
          >
            Link Google Classroom account
          </button>
        </>
      )}
      {gcrLink?.isLinked && (
        <>
          {gcrLink?.invalid && (
            <InfoComponent
              text="We are experiencing some issues with fetching your Google Classroom data. Please try again.
Otherwise ask the admin of your school institution that provided you with email account to configure permissions to Uluru."
            />
          )}
          <p className={'mt-2'}>
            This Uluru account has been already connected to Google Classroom
            account.{' '}
            <a
              href="javascript:void(0)"
              onClick={(e) => {
                e.preventDefault();
                toggleUnlinkPopup(studentUid, true);
              }}
            >
              <div className={'inline relative top-1'}>
                <TrashIcon />
              </div>{' '}
              Unlink
            </a>
          </p>
          <Dialog
            header="Unlink Confirmation"
            visible={unlinkStudentVisibility}
            onHide={() => toggleUnlinkPopup(studentUid, false)}
            blockScroll={true}
            contentStyle={{ overflow: 'auto' }}
          >
            <p className={'text-center'}>
              Are you sure want to unlink the Uluru account from Google
              Classroom account?
            </p>
            <button
              type="button"
              onClick={() => {
                unlinkGoogle(studentUid);
              }}
              className="p-button p-component float-right p-[12px] px-[40px] text-center flex justify-center align-right text-sm md:text-base mt-7"
            >
              Confirm
            </button>
            <button
              type="button"
              onClick={() => {
                toggleUnlinkPopup(studentUid, false);
              }}
              className="p-button p-component float-left p-[12px] px-[40px] text-center flex justify-center align-right text-sm md:text-base mt-7"
            >
              Cancel
            </button>
          </Dialog>
        </>
      )}
    </>
  );
};
