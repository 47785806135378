type CustomErrorMessagesType = {
  [key: string]: string;
};

const CustomErrorMessages: CustomErrorMessagesType = {};

type ErrorMessagesType = {
  Required: (field: string) => string;
  Max: (field: string, length: number) => string;
  Min: (field: string, length: number) => string;
  Invalid: (field: string) => string;
  EmailExists: (email: string) => string;
  FromTo: (field: string, from: number, to: number) => string;
  TooShort: (field: string, length: number) => string;
  TooLong: (field: string, length: number) => string;
};

export const ErrorMessages: ErrorMessagesType = {
  Required: (field: string) =>
    CustomErrorMessages[field.toLowerCase()] || `"${field}" cannot be blank`,
  Max: (field: string, length: number) =>
    `"${field}" cannot be more than ${length} characters`,
  Min: (field: string, length: number) =>
    `"${field}" cannot be less than ${length} characters`,
  Invalid: (field: string) => `The format of "${field}" is invalid.`,
  EmailExists: (email: string) => `User with email ${email} already exists`,
  FromTo: (field: string, from: number, to: number) =>
    `Enter digit from ${from} to ${to}`,
  TooShort: (field: string, length: number) =>
    `"${field}" should contain at least ${length} character(s).`,
  TooLong: (field: string, length: number) =>
    `"${field}" should contain at most ${length} character(s).`,
};
