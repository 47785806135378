import { FC } from 'react';
import { Badge } from 'primereact/badge';

export enum AssignmentTypes {
  MANUAL = 'manual',
  GCR = 'gcr',
  SCHOOLOGY = 'schoology',
  BLACKBAUD = 'blackbaud',
  INFINITE_CAMPUS = 'infinite_campus',
  BLACKBOARD = 'blackboard',
  CANVAS = 'canvas',
}

export const AssignmentTypeBadge: FC<{ type: AssignmentTypes }> = ({
  type,
}) => {
  return (
    <>
      {type === AssignmentTypes.GCR && (
        <Badge value="Google Classroom" severity="warning"></Badge>
      )}
      {type === AssignmentTypes.BLACKBAUD && (
        <Badge value="Blackbaud" severity="success"></Badge>
      )}
      {type === AssignmentTypes.SCHOOLOGY && (
        <Badge value="Schoology" severity="info"></Badge>
      )}
      {type === AssignmentTypes.BLACKBOARD && (
        <Badge value="Blackboard"></Badge>
      )}
      {type === AssignmentTypes.INFINITE_CAMPUS && (
        <Badge value="Infinity Campus" severity="success"></Badge>
      )}
      {type === AssignmentTypes.CANVAS && (
        <Badge value="Canvas" severity="info"></Badge>
      )}
      {type === AssignmentTypes.MANUAL && <Badge value="Uluru"></Badge>}
    </>
  );
};
