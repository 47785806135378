import { Outlet } from 'react-router';
import useAuth from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { UserRole } from '../../types/UserProfile';
import NavbarHome from '../../components/NavbarHome';
import { useEffect } from 'react';
import { SelectedStudentProvider } from '../../contexts/SelectedStudentContext';

export const ParentLayout = () => {
  const { user, isLoading: isLoadingUser } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoadingUser && !user) navigate('/login');
    if (!isLoadingUser && user?.role === UserRole.STUDENT) {
      navigate('/student/home');
    }
  }, [isLoadingUser]);

  return (
    <SelectedStudentProvider>
      <NavbarHome>
        <Outlet />
      </NavbarHome>
    </SelectedStudentProvider>
  );
};
