import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from './contexts/AuthContext';
import AppRoutes from './routes/AppRoutes';
import { hotjar } from 'react-hotjar';
import { HOTJAR_ID, HOTJAR_SV } from './constants/environments';
import { isNaN } from 'lodash';

const queryClient = new QueryClient();

const App: React.FC = () => {
  useEffect(() => {
    if (HOTJAR_ID && !isNaN(HOTJAR_ID) && HOTJAR_SV && !isNaN(HOTJAR_SV)) {
      hotjar.initialize(Number(HOTJAR_ID), Number(HOTJAR_SV));
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </QueryClientProvider>
  );
};
export default App;
