export const ExternalPopup = async (
  url: string,
  width = 600,
  height = 600,
): Promise<boolean> => {
  const left = (window.innerWidth - width) / 2 + width;
  const top = (window.innerHeight - height) / 2 + height / 2;

  const popup = window.open(
    url,
    'BlackbaudOAuthPopup',
    `toolbar=no, menubar=no, width=${width}, height=${height}, top=${top}, left=${left}`,
  );

  return new Promise((rs) => {
    const checkPopupClosed = setInterval(() => {
      if (popup?.closed) {
        clearInterval(checkPopupClosed);
        rs(true);
      }
    }, 500);
  });
};
