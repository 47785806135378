const FamilyProfileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M16.304 3.5C16.8216 3.81111 17.2499 4.25093 17.5471 4.77665C17.8443 5.30237 18.0003 5.89608 18 6.5C18.0003 7.10392 17.8443 7.69763 17.5471 8.22335C17.2499 8.74907 16.8216 9.18889 16.304 9.5M22 21V20.4C22 18.16 22 17.04 21.564 16.184C21.1805 15.4314 20.5686 14.8195 19.816 14.436M9.5 10C9.95963 10 10.4148 9.90947 10.8394 9.73358C11.264 9.55769 11.6499 9.29988 11.9749 8.97487C12.2999 8.64987 12.5577 8.26403 12.7336 7.83939C12.9095 7.41475 13 6.95963 13 6.5C13 6.04037 12.9095 5.58525 12.7336 5.16061C12.5577 4.73597 12.2999 4.35013 11.9749 4.02513C11.6499 3.70012 11.264 3.44231 10.8394 3.26642C10.4148 3.09053 9.95963 3 9.5 3C8.57174 3 7.6815 3.36875 7.02513 4.02513C6.36875 4.6815 6 5.57174 6 6.5C6 7.42826 6.36875 8.3185 7.02513 8.97487C7.6815 9.63125 8.57174 10 9.5 10ZM2 20.4V21H17V20.4C17 18.16 17 17.04 16.564 16.184C16.1805 15.4314 15.5686 14.8195 14.816 14.436C13.96 14 12.84 14 10.6 14H8.4C6.16 14 5.04 14 4.184 14.436C3.43139 14.8195 2.81949 15.4314 2.436 16.184C2 17.04 2 18.16 2 20.4Z"
        stroke="#797F8D"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default FamilyProfileIcon;
