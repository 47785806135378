export const production = process.env.REACT_APP_production === 'true';
export const serverUrl = process.env.REACT_APP_serverUrl;

export const GCR_CLIENT_ID: string = process.env.REACT_APP_GCR_CLIENT_ID || '';
export const GCR_REDIRECT_URL: string =
  process.env.REACT_APP_GCR_REDIRECT_URL || '';

export const PUSH_NOTIFICATION_PUBLIC_KEY: string =
  process.env.REACT_APP_PUSH_NOTIFICATION_PUBLIC_KEY || '';

export const HOTJAR_ID: string = process.env.REACT_APP_HOTJAR_ID || '';
export const HOTJAR_SV: string = process.env.REACT_APP_HOTJAR_SV || '';

export const SENTRY_DSN: string = process.env.REACT_APP_SENTRY_DSN || '';
