import { Snackbar, Alert } from '@mui/material';
import React, { createContext, useState } from 'react';
import { AxiosError } from 'axios';
import { Outlet } from 'react-router';

type SnackbarType = 'success' | 'error';

interface SnackbarMessage {
  message: string;
  type: SnackbarType;
}

interface SnackbarContextProps {
  openSnackbar: (message: SnackbarMessage) => void;
  handleNetworkError: (
    error: AxiosError<{ message?: string | string[] }>,
  ) => void;
}

export const SnackbarContext = createContext<SnackbarContextProps>({
  openSnackbar: () => {},
  handleNetworkError: () => {},
});

export const useSnackbar = () => React.useContext(SnackbarContext);

export const SnackbarProvider: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState<SnackbarType>('success');

  const openSnackbar = ({ message, type }: SnackbarMessage) => {
    setMessage(message);
    setType(type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNetworkError = (
    error: AxiosError<{ message?: string | string[] }>,
  ) => {
    let message = 'Network error';
    if (error?.response?.data?.message) {
      if (typeof error.response.data.message === 'string') {
        message = error.response.data.message;
      } else if (Array.isArray(error.response.data.message)) {
        message = error.response.data.message.join(' ');
      }
    }
    openSnackbar({
      type: 'error',
      message: message,
    });
  };

  return (
    <SnackbarContext.Provider value={{ openSnackbar, handleNetworkError }}>
      <Outlet />
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={type} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
