import { useQuery } from '@tanstack/react-query';
import { httpClient } from '../../../api/http/http-client';

export interface Response {
  url?: string;
  isLinked?: boolean;
  isLinkAvailable?: boolean;
}

export const getCanvasLink = async ({
  queryKey,
}: {
  queryKey: (string | undefined)[];
}): Promise<Response> => {
  const [, uid] = queryKey;
  return httpClient.get<void, Response>('/canvas/link/' + uid, {});
};

export const useCanvasLink = ({ studentUid }: { studentUid?: string }) => {
  const useQueryResult = useQuery(['/canvas/link', studentUid], getCanvasLink, {
    enabled: !!studentUid,
  });

  return {
    ...useQueryResult,
  };
};
