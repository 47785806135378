import { httpClient } from '../../api/http/http-client';
import { useQuery } from '@tanstack/react-query';

export interface CountResponse {
  count: number;
  subjects: string[];
}

export const getAssignmentsTodoCount = async ({
  queryKey,
}: {
  queryKey: (string | undefined)[];
}): Promise<CountResponse> => {
  const [, studentId] = queryKey;

  return httpClient.get<void, CountResponse>('/assignments/todo-count', {
    params: {
      studentUid: studentId,
    },
  });
};

export const useGetAssignmentTodoCount = ({
  studentId,
  enabled = true,
}: {
  studentId?: string;
  enabled?: boolean;
}) => {
  return useQuery(
    ['/assignments/todo-count', studentId],
    getAssignmentsTodoCount,
    {
      enabled: enabled && !!studentId,
      keepPreviousData: true,
    },
  );
};
