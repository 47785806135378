import React, { FC, useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ErrorMessages } from '../../constants/messages';
import Input from '../Input';
import DateInput from '../DateInput';
import SelectInput from '../SelectInput';
import TextareaInput from '../TextareaInput';
import { Button } from 'primereact/button';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { useCreateAssignment } from './useCreateAssignment';
import {
  getSubjects,
  SubjectSelectItem,
} from '../AssignmentDetailsPage/useGetSubjects';
import { getActivitiesBySubject } from '../AssignmentDetailsPage/useGetActivities';

const toUtcDate = (dateString: string) => {
  // Parse the input date string
  const localDate = new Date(dateString);

  // Get local date components
  const year = localDate.getFullYear();
  const month = localDate.getMonth();
  const day = localDate.getDate();
  const hours = localDate.getHours();
  const minutes = localDate.getMinutes();
  const seconds = localDate.getSeconds();

  // Create a new Date object in UTC using the same components
  const utcDate = new Date(Date.UTC(year, month, day, hours, minutes, seconds));

  // Return the UTC date as an ISO string
  return utcDate.toISOString();
};

interface CreateAssignmentFields {
  title: string;
  dueDate: string;
  subject: string;
  activity?: string;
  description: string;
}

const schema = yup.object().shape({
  title: yup
    .string()
    .required(ErrorMessages.Required('Title'))
    .min(2, ErrorMessages.TooShort('Title', 2))
    .max(200, ErrorMessages.TooLong('Title', 200)),
  dueDate: yup.string().required(ErrorMessages.Required('Due Date')),
  subject: yup.string().required(ErrorMessages.Required('Subject')),
  activity: yup.string(),
  description: yup
    .string()
    .required(ErrorMessages.Required('Description'))
    .min(2, ErrorMessages.TooShort('Description', 2))
    .max(5000, ErrorMessages.TooLong('Description', 5000)),
});

const minDueDate = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate(),
);

export const CreateAssignmentModal: FC<{
  open: boolean;
  onClose: () => void;
  studentUid: string;
}> = ({ open, onClose, studentUid }) => {
  const { openSnackbar } = useSnackbar();
  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const [activityList, setActivityList] = useState<
    { label: string; value: string }[]
  >([]);
  const [subjectList, setSubjectList] = useState<SubjectSelectItem[]>([]);

  const { mutation } = useCreateAssignment({
    onSuccess: () => {
      formMethods.reset();
      openSnackbar({
        message: 'Assignment created successfully.',
        type: 'success',
      });
      onClose();
    },
  });

  const updateSubjects = async () => {
    const subjects = await getSubjects();
    setSubjectList(subjects);
  };

  useEffect(() => {
    const subject = formMethods.watch('subject');
    if (subject) {
      getActivitiesBySubject(formMethods.watch('subject'))
        .then((activities) => {
          setActivityList(activities);
          formMethods.setValue('activity', '');
        })
        .catch(() => {});
    }
  }, [formMethods.watch('subject')]);

  useEffect(() => {
    updateSubjects();
  }, []);

  const onSubmit: SubmitHandler<CreateAssignmentFields> = (data) => {
    const dueDate = toUtcDate(data.dueDate);
    mutation.mutate({
      studentUid,
      ...data,
      dueDate,
    });
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        formMethods.reset();
        onClose();
      }}
      className={`flex items-center justify-center`}
    >
      <div className="w-full max-w-[776px] h-fit p-[17px] outline-none">
        <div className="relative w-full h-full p-[30px] bg-white rounded-[10px]">
          <Button
            className="absolute top-[20px] right-[20px]"
            icon="pi pi-times"
            rounded
            text
            severity="secondary"
            aria-label="Close modal"
            onClick={() => {
              formMethods.reset();
              onClose();
            }}
          />
          <h2 className="text-2xl md:text-4xl m-0 mb-[40px]">Add Assignment</h2>
          <FormProvider {...formMethods}>
            <form
              onSubmit={formMethods.handleSubmit(onSubmit)}
              className="flex flex-col gap-[20px] md:gap-[30px]"
            >
              <div className="flex flex-col md:flex-row gap-[20px]">
                <div className="w-full">
                  <Input
                    name="title"
                    placeholder="Title"
                    inputClassName="w-full"
                  />
                </div>
                <div className="min-w-[197px]">
                  <DateInput
                    name="dueDate"
                    placeholder="Due Date"
                    minDate={minDueDate}
                    icon="pi pi-calendar"
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-[20px] w-full">
                <SelectInput
                  name="subject"
                  placeholder="Subject"
                  options={subjectList}
                  containerClassName="md:w-[calc(50%-10px)]"
                />
                <SelectInput
                  name="activity"
                  placeholder="Activity"
                  options={activityList}
                  disabled={!activityList.length}
                  containerClassName="md:w-[calc(50%-10px)]"
                />
              </div>
              <TextareaInput
                name="description"
                label="Description"
                maxLength={5000}
              />
              <Button
                type="submit"
                className="w-full md:w-fit text-base self-center flex gap-[10px] md:py-[18px] md:px-[70px] justify-center"
                loading={mutation.isLoading}
              >
                <span>Add</span> <i className="pi pi-plus-circle" />
              </Button>
            </form>
          </FormProvider>
        </div>
      </div>
    </Modal>
  );
};
