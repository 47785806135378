import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { QuestionProgressCounter } from './QuestionProgressCounter';
import { AnswerValue, QuestionsData, useGetQuestions } from './useGetQuestions';
import useAuth from '../../contexts/AuthContext';
import { useSaveAnswer } from './useSaveAnswer';
import { useNavigate, useParams } from 'react-router-dom';
import { useFinishTest } from './useFinishTest';
import { getHomePath } from '../../utils/getHomePath';
import BackgroundImage from '../../components/BackgroundImage';
import { isNil } from 'lodash';
import { ProgressSpinner } from 'primereact/progressspinner';
import { UserRole } from '../../types/UserProfile';

export const testLogos: { [key: string]: string } = {
  default: '/images/uefa-logo.svg',

  'PHQ-9 (Parent Version)': '/images/phq-9-logo.svg',
  'PHQ-9 (Teen Version)': '/images/phq-9-logo.svg',
  'GAD-7': '/images/gad-7-logo.svg',
  PSS: '/images/pss-logo.svg',
  'SNAP-IV': '/images/snap-4-logo.png',
};

const titles: { [key: string]: string } = {
  'PHQ-9 (Parent Version)': 'PHQ- 9 (Depression)',
  'PHQ-9 (Teen Version)': 'PHQ- 9 (Depression)',
  'GAD-7': 'GAD 7 (Anxiety)',
  PSS: 'The Perceived Stress Scale (PSS)',
  'SNAP-IV': 'SNAP IV (ADHD)',
};

const ButtonClassName =
  'text-sm md:text-base w-full p-[20px] !bg-grey2 !border-none !text-header-color !hover:border-none !hover:text-header-color rounded-[10px] flex justify-between items-center';
const SelectedButtonClassName =
  'text-sm md:text-base w-full p-[20px] !bg-green1 !border-none !text-white !hover:border-none !hover:text-header-color rounded-[10px] flex justify-between items-center';

const AnswersList = ({
  answerOptions,
  currentQuestionIndex,
  answer,
  onAnswerChange,
  allowAnswer,
}: {
  answerOptions: AnswerValue[];
  currentQuestionIndex: number;
  answer?: string;
  onAnswerChange: (value: string) => void;
  allowAnswer?: boolean;
}) => {
  const [answerValue, setAnswerValue] = useState(answer);

  useEffect(() => {
    setAnswerValue(answer);
  }, [answer, currentQuestionIndex]);
  return (
    <div className="flex flex-col gap-[10px]">
      {answerOptions.map((value) => (
        <Button
          className={`disable-borders ${
            String(value.value) === answerValue
              ? SelectedButtonClassName
              : ButtonClassName
          }`}
          onClick={() => {
            if (!allowAnswer) return;
            const selectedValue = String(value.value);
            setAnswerValue(selectedValue);
            onAnswerChange(selectedValue);
          }}
        >
          <span className="block">{value.text}</span>
          <i
            className={`pi pi-arrow-right ${
              String(value.value) === answerValue ? 'text-white' : 'text-blue1'
            }`}
          ></i>
        </Button>
      ))}
    </div>
  );
};

const NeuropsychTest = () => {
  const navigate = useNavigate();
  const { studentUserId } = useParams();
  const { user } = useAuth();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const { data, isLoading } = useGetQuestions(studentUserId);
  const [questionsData, setQuestionsData] = useState<QuestionsData[]>([]);
  const [animate, setAnimate] = useState('');
  const questionRef = useRef<HTMLDivElement>(null);

  const { mutation: saveAnswerMutation } = useSaveAnswer({
    onSuccess: (data, variables) => {
      setQuestionsData((prev) => {
        const newData = [...prev];
        const question = newData.find((q) => q.uid === variables.questionUid);
        if (question) {
          question.answerValue = variables.answerValue;
        }
        return newData;
      });
    },
  });

  const goToNextQuestion = () => {
    if (currentQuestionIndex < questionsData.length - 1) {
      questionRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
      setCurrentQuestionIndex((prev) => {
        if (prev < questionsData.length - 1) {
          return prev + 1;
        }
        return prev;
      });
    }
  };
  const isAnswering = useRef<boolean>(false);

  const handleAnswer = (answerValue: string) => {
    if (isAnswering.current) {
      return;
    }
    // if last question
    if (currentQuestionIndex === questionsData.length - 1) {
      saveAnswerMutation.mutate({
        questionUid: currentQuestion?.uid,
        testUid: currentQuestion?.testUid,
        answerValue: answerValue,
      });
      return;
    }
    isAnswering.current = true;
    setTimeout(() => {
      setAnimate('slide-out-left');

      saveAnswerMutation.mutate({
        questionUid: currentQuestion?.uid,
        testUid: currentQuestion?.testUid,
        answerValue: answerValue,
      });
      setTimeout(() => {
        goToNextQuestion();

        setAnimate('slide-in-from-right');

        setTimeout(() => {
          setAnimate('');
          isAnswering.current = false;
        }, 500); // This duration should match your slide-in animation
      }, 500); // This duration should match your slide-out animation
    }, 500); // This is a delay to allow the user to see the answer they selected
  };

  const goToPreviousQuestion = () => {
    setAnimate('slide-out-right');

    setTimeout(() => {
      setCurrentQuestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));

      setAnimate('slide-in-from-left');

      setTimeout(() => {
        setAnimate('');
      }, 500); // This duration should match your slide-in animation
    }, 500); // This duration should match your slide-out animation
  };

  const { mutation: finishTestMutation } = useFinishTest({
    onSuccess: (data, variables) => {
      navigate(
        `/${
          user?.role === UserRole.PARENT ? 'parent' : 'student'
        }/neuro-test/result/${data?.uid}`,
      );
    },
  });

  useEffect(() => {
    if (data?.questions?.length) {
      const currentQuestionIndex = data?.questions?.findIndex((item) =>
        isNil(item.answerValue),
      );
      setCurrentQuestionIndex(
        currentQuestionIndex === -1
          ? data?.questions?.length - 1
          : currentQuestionIndex,
      );
      setQuestionsData(data?.questions);
    }
  }, [data]);

  useEffect(() => {
    if (questionsData?.length) {
      const currentQuestionIndex = questionsData?.findIndex((item) =>
        isNil(item.answerValue),
      );
      setCurrentQuestionIndex(
        currentQuestionIndex === -1
          ? questionsData?.length - 1
          : currentQuestionIndex,
      );
    }
  }, []);

  useEffect(() => {
    questionRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  }, [questionRef.current]);

  const currentQuestion = questionsData?.[currentQuestionIndex];

  const allowToFinish =
    questionsData && questionsData.every((item) => !isNil(item?.answerValue));
  return (
    <div className="flex md:flex-row flex-col grow">
      <div className="relative md:w-1/3 flex flex-row md:flex-col items-center justify-center p-[30px] gap-[10px] md:gap-[30px] md:grow h-auto">
        <BackgroundImage
          desktopImage={'/images/pebble-background/desktop-3.png'}
          mobileImage={'/images/pebble-background/mobile-3.png'}
        />
        <img
          src={testLogos[currentQuestion?.subTestName] || testLogos.default}
          className="w-[120px] md:w-[340px]"
          alt=""
        />
        <h3 className="text-2xl md:text-4xl md:text-center text-header-color m-0">
          {titles[currentQuestion?.subTestName] || currentQuestion?.subTestName}
        </h3>
      </div>
      <div className="md:w-2/3 bg-white h-full md:pt-[146px] py-[40px] px-[20px] md:pl-[101px] md:pr-[131px] flex flex-col gap-[30px] overflow-y-auto">
        {isLoading ? (
          <ProgressSpinner className="m-auto" />
        ) : (
          <>
            <div className="flex md:gap-[30px] gap-y-0 flex-wrap justify-between md:justify-normal items-center">
              <Button
                link
                className="disable-borders text-blue1 text-base pl-0"
                onClick={() => {
                  if (currentQuestionIndex === 0) {
                    navigate(getHomePath(user));
                  } else {
                    goToPreviousQuestion();
                  }
                }}
              >
                <i className="pi pi-arrow-left mr-[10px]" />{' '}
                <span className="hidden md:block">Back</span>
              </Button>
              <QuestionProgressCounter
                activeQuestion={currentQuestionIndex + 1}
                totalQuestions={questionsData?.length ?? 0}
              />
            </div>
            <div className="flex flex-col gap-[30px]" ref={questionRef}>
              <div className="question-container overflow-x-hidden overflow-y-auto">
                <div className={`${animate} flex flex-col gap-[10px] `}>
                  <h2 className="text-2xl md:text-4xl text-header-color m-0">
                    {currentQuestion?.questionText}
                  </h2>
                  <p className="text-sm md:text-base m-0">
                    {currentQuestion?.instructions}
                  </p>
                  <AnswersList
                    currentQuestionIndex={currentQuestionIndex}
                    answerOptions={currentQuestion?.answerOptions || []}
                    answer={currentQuestion?.answerValue}
                    onAnswerChange={(value) => {
                      handleAnswer(value);
                    }}
                    allowAnswer={!animate}
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row">
                {allowToFinish ? (
                  <Button
                    className="py-[12px] px-[75px] w-full md:w-fit text-sm md:text-base justify-center mr-auto"
                    onClick={() => {
                      finishTestMutation.mutate({
                        testUid: data?.testUid,
                      });
                    }}
                  >
                    Finish
                  </Button>
                ) : (
                  <Button
                    className="self-end w-fit text-sm md:text-base flex gap-[10px] ml-auto"
                    link
                    onClick={() => {
                      navigate(getHomePath(user));
                    }}
                  >
                    <i className="pi pi-question-circle text-2xl"></i>
                    <span className="block">Take a break?</span>
                  </Button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NeuropsychTest;
