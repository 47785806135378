import React from 'react';
import { Button } from 'primereact/button';
import { useDrag } from 'react-dnd';
import dragIcon from '../../images/icons/drag-icon.svg';

export interface MotivationGoalBoxProps {
  name: string;
  isDropped: boolean;
}

const MotivationGoalBox: React.FC<MotivationGoalBoxProps> = ({
  name,
  isDropped,
}) => {
  const [, drag] = useDrag(
    () => ({
      type: 'goal',
      item: { name },
    }),
    [name],
  );

  return (
    <div style={{ transform: 'translate(0, 0)' }}>
      {!isDropped ? (
        <div
          ref={drag}
          className="rounded-[20px]  m-[10px]"
          style={{ transform: 'translate(0, 0);' }}
        >
          <Button className="goal-item color-black1">
            <img src={dragIcon} alt="dragIcon" className="mr-[10px]" />
            {name}
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MotivationGoalBox;
