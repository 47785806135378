import React, { FC, useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { debounce } from 'lodash';

interface Props {
  search: string | undefined;
  setSearch: (v: string) => void;
}

export const SearchInput: FC<Props> = ({ search, setSearch }) => {
  const [searchValue, setSearchValue] = useState(search);

  useEffect(() => {
    if (!searchValue) {
      setSearchValue(search);
    }
  }, [search]);

  useEffect(() => {
    const debouncedUpdate = debounce((query) => {
      setSearch(query);
    }, 700);

    debouncedUpdate(searchValue);

    return () => {
      debouncedUpdate.cancel();
    };
  }, [searchValue]);

  return (
    <div className="p-input-icon-right w-full md:w-fit ">
      <i className="pi pi-search" />
      <InputText
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder="Search"
        className="w-full md:w-fit"
      />
    </div>
  );
};
