import { transformMessage } from '../../functions';
import { Typed } from '../../types';
import { InputText } from 'primereact/inputtext';
import { useFormContext } from 'react-hook-form';
import { FC } from 'react';

interface Props {
  name: string;
  label?: string;
  placeholder?: string;
  inputClassName?: string;
  disabled?: boolean;
  handleBlur?: (value: string) => void;
}

export const EmailInput: FC<Props> = ({
  name,
  label,
  placeholder,
  inputClassName = '',
  disabled = false,
  handleBlur,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const fieldError = errors[name];
  const message = fieldError?.message?.toString();

  return (
    <div className={`${!!fieldError ? 'error' : ''} label-container`}>
      <label className="hidden md:block">{label}</label>
      <InputText
        {...register(name)}
        placeholder={placeholder}
        type="email"
        className={`w-full ${inputClassName}`}
        disabled={disabled}
        onBlur={(e) => {
          handleBlur?.(e.target.value);
        }}
      />
      {message && <div className="input-error-text">{message}</div>}
    </div>
  );
};
