import * as yup from 'yup';
import { QuestionFields, SurveyTypes } from './types';

export const SurveyQuestionKeys = [
  SurveyTypes.TransformationInfoStep,
  SurveyTypes.SupportInfoStep,
  SurveyTypes.SupportRelationshipStep,
  SurveyTypes.MostChallengingStep,
];

export const SurveySchema = yup
  .object()
  .shape(
    Object.fromEntries(SurveyQuestionKeys.map((key) => [key, yup.boolean()])),
  );

export const SurveyQuestions: { [key in SurveyTypes]: QuestionFields } = {
  TransformationInfoStep: {
    question:
      'What kind of transformation do you most wish to see for your family?  I would like to see…',
    answers: [
      {
        name: 'aCalmerAfterSchoolExperience',
        label: 'A calmer after school experience',
        inputType: 'checkbox',
        hasLabel: true,
      },
      {
        name: 'moreTimeToConnectWithMyChildChildren',
        label: 'More time to connect with my child',
        inputType: 'checkbox',
        hasLabel: true,
      },
      {
        name: 'spaceForNonAcademicActivitiesAsAFamily',
        label: 'Space for non-academic activities as a family',
        inputType: 'checkbox',
        hasLabel: true,
      },
      {
        name: 'aGreaterSenseOfRhythmForMyFamily',
        label: 'A greater sense of rhythm for my family',
        inputType: 'checkbox',
        hasLabel: true,
      },
      {
        name: 'moreFreeTimeForMyselfAndMyPartner',
        label: 'More free time for myself and my partner',
        inputType: 'checkbox',
        hasLabel: true,
      },
    ],
  },
  SupportInfoStep: {
    question:
      'What kind of support would you as a parent most like to receive? I want support…',
    answers: [
      {
        name: 'gainingClarityOnMyChildChildrensHomeworkAssignments',
        label: 'Gaining clarity on my child homework assignments',
        inputType: 'checkbox',
        hasLabel: true,
      },
      {
        name: 'creatingMoreStructure',
        label: 'Creating more structure',
        inputType: 'checkbox',
        hasLabel: true,
      },
      {
        name: 'teachingHealthyHabitsToMyChildChildren',
        label: 'Teaching healthy habits to my child',
        inputType: 'checkbox',
        hasLabel: true,
      },
      {
        name: 'inspiringGreaterAcademicSelfConfidence',
        label: 'Inspiring greater academic self-confidence',
        inputType: 'checkbox',
        hasLabel: true,
      },
      {
        name: 'definingBoundariesForMyselfAsAParent1',
        label: 'Defining boundaries for myself as a parent',
        inputType: 'checkbox',
        hasLabel: true,
      },
    ],
  },
  SupportRelationshipStep: {
    question:
      'What kind of support would you like to receive for improving your relationship to your child’s school? I would like to have…',
    answers: [
      {
        name: 'gainingClarityOnMyChildChildrensHomeworkAssignments',
        label: 'Tools for communicating with the teachers and administration',
        inputType: 'checkbox',
        hasLabel: true,
      },
      {
        name: 'creatingMoreStructure',
        label: 'A clearer understanding of expectations for student success',
        inputType: 'checkbox',
        hasLabel: true,
      },
      {
        name: 'teachingHealthyHabitsToMyChildChildren',
        label: 'Support navigating homework portals',
        inputType: 'checkbox',
        hasLabel: true,
      },
      {
        name: 'inspiringGreaterAcademicSelfConfidence',
        label: 'Access to a clear assignment and exam calendar',
        inputType: 'checkbox',
        hasLabel: true,
      },
      {
        name: 'definingBoundariesForMyselfAsAParent1',
        label: 'A platform for connecting with other parents at my school',
        inputType: 'checkbox',
        hasLabel: true,
      },
    ],
  },
  MostChallengingStep: {
    question:
      'What is currently most challenging for your family around school?',
    answers: [
      {
        name: 'mostChallenging',
        label: 'Enter your answer',
        inputType: 'textarea',
        hasLabel: true,
      },
    ],
  },
};
