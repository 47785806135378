import React, { FC, useState } from 'react';
import { AttachmentType } from '../AssignmentsList/useGetAssignmentList';

export const AttachmentFileItem: FC<{
  type: AttachmentType;
  url: string;
  thumb: string;
  title: string;
}> = ({ url, thumb, title, type }) => {
  //check if image is broken
  const [isImageBroken, setIsImageBroken] = useState(true);

  return (
    <a href={url} target="_blank" rel="noreferrer">
      <div className="flex flex-col gap-[5px] items-center justify-center bg-grey w-[186px] h-[157px] rounded-[8px] text-header-color overflow-hidden">
        {type === AttachmentType.DRIVE_FILE && (
          <img
            className="block max-h-[80%]"
            style={isImageBroken ? { display: 'none' } : {}}
            src={thumb}
            alt={title}
            onError={() => setIsImageBroken(true)}
            onLoad={() => setIsImageBroken(false)}
          />
        )}
        {type === AttachmentType.DRIVE_FILE && isImageBroken && (
          <img className="block h-[60px]" src="/images/file.png" alt={title} />
        )}
        {type === AttachmentType.YOUTUBE_VIDEO && (
          <div className="relative max-h-[80%] w-full">
            <img
              className="block max-h-[100%] max-w-[100%] m-auto"
              style={isImageBroken ? { display: 'none' } : {}}
              src={thumb}
              alt={title}
              onError={() => setIsImageBroken(true)}
              onLoad={() => setIsImageBroken(false)}
            />
            <img
              className="block absolute top-[15%] left-[32%]"
              src="/images/play-circle.png"
            />
          </div>
        )}
        {(type === AttachmentType.LINK || type === AttachmentType.FORM) && (
          <img
            className="block h-[60px]"
            src="/images/link-file.png"
            alt={title}
          />
        )}

        <div className={`text-base px-[5px] truncate max-w-[100%]`}>
          {title}
        </div>
      </div>
    </a>
  );
};
