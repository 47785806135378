import React, { FC } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { SelectItem } from 'primereact/selectitem';
import { sortByOptions } from '../AssignmentPageFilters/AssignmentPageFilters';

interface Props {
  subjectList: SelectItem[];
  sortBy: string;
  setSortBy: (v: string) => void;
  subject: string;
  setSubject: (v: string) => void;
}
export const TodoListFilters: FC<Props> = ({
  sortBy,
  setSortBy,
  subject,
  setSubject,
  subjectList,
}) => {
  return (
    <div className="flex flex-col md:flex-row gap-[20px] items-center flex-wrap w-full md:w-fit justify-end">
      <div className="flex items-center gap-[10px] w-full md:w-fit">
        <label
          htmlFor="sort-by-date"
          className="text-base text-grey3 whitespace-nowrap"
        >
          Sort by:
        </label>
        <Dropdown
          id="sort-by-date"
          options={sortByOptions}
          className="w-full md:w-[180px]"
          value={sortBy}
          onChange={(e) => setSortBy(e.value)}
        />
      </div>
      <div className="flex items-center gap-[10px] w-full md:w-fit">
        <label
          htmlFor="subject"
          className="text-base text-grey3 whitespace-nowrap"
        >
          Subject:
        </label>
        <Dropdown
          id="subject"
          options={subjectList}
          className="w-full md:w-fit lg:min-w-[180px]"
          value={subject}
          onChange={(e) => setSubject(e.value)}
        />
      </div>
    </div>
  );
};
