import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';

export const BackButton = () => {
  const navigate = useNavigate();

  return (
    <Button
      className="disable-borders flex gap-[10px] pl-[5px] !text-blue1 w-fit md:mb-[40px]"
      link
      onClick={() => {
        if (window.history.length > 1) {
          navigate(-1); // Go back in history if possible
        } else {
          try {
            window.close(); // Try to close the window
          } catch (e) {
            navigate('/', { replace: true }); // Navigate to a default route if window cannot be closed
          }
        }
      }}
    >
      <i className="pi pi-arrow-left" />
      <span>Back</span>
    </Button>
  );
};

export default function PrivacyPolicyPage() {
  return (
    <div className="relative pb-20 md:pb-0 md:flex content-wrap md:min-h-screen px-12 py-10 md:justify-center flex flex-col bg-white grow">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy (ULURU)</h1>
      <h2 className="text-2xl font-semibold mb-2">ULURU Privacy Policy</h2>
      <p className="mb-4">
        Thank you for choosing to be part of the ULURU, Inc (“Company”, “we”,
        “us”, or “our”). We are committed to protecting your personal
        information and your right to privacy. If you have any questions or
        concerns about our policy, or our practices with regards to your
        personal information, please contact us at{' '}
        <a
          href="mailto:hello@theuluru.com"
          className="text-blue-600 underline"
          target="_blank"
          rel="noreferrer"
        >
          hello@theuluru.com
        </a>
        . When you visit our website{' '}
        <a
          href="https://www.theuluru.com"
          className="text-blue-600 underline"
          target="_blank"
          rel="noreferrer"
        >
          https://www.theuluru.com
        </a>
        , mobile application, or use our services, you trust us with your
        personal information. We take your privacy very seriously. In this
        privacy policy, we seek to explain to you in the clearest way possible
        what information we collect, how we use it and what rights you have in
        relation to it. We hope you take some time to read through it carefully,
        as it is important. If there are any terms in this privacy policy that
        you do not agree with, please discontinue use of our Sites or App and
        our services. This privacy policy applies to all information collected
        through our website (such as{' '}
        <a
          href="https://www.theuluru.com"
          className="text-blue-600 underline"
          target="_blank"
          rel="noreferrer"
        >
          https://www.theuluru.com
        </a>
        ), mobile application, ("App"), and/or any related services, sales,
        marketing or events (we refer to them collectively in this privacy
        policy as the "Services"). Please read this privacy policy carefully as
        it will help you make informed decisions about sharing your personal
        information with us.
      </p>
      <h3 className="text-xl font-semibold mb-2">TABLE OF CONTENTS</h3>
      <ul className="list-disc mb-4 pl-5">
        <li>WHAT INFORMATION DOES ULURU COLLECT?</li>
        <li>WHY WE COLLECT YOUR INFORMATION?</li>
        <li>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</li>
        <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
        <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
        <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
        <li>CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS</li>
        <li>PRIVACY POLICY UPDATES</li>
        <li>HOW CAN YOU CONTACT US ABOUT THIS POLICY?</li>
        <li>GOOGLE API SERVICES</li>
      </ul>
      <h4 className="text-lg font-semibold mb-2">
        1. WHAT INFORMATION DOES ULURU COLLECT?
      </h4>
      <p className="mb-4">
        Personal information you disclose to us In Short: We collect personal
        information that you voluntarily provide to us. We collect personal
        information that you voluntarily provide to us when you create a ULURU
        account, expressing an interest in obtaining information about us or our
        products and services, when participating in activities on ULURU or
        otherwise contacting us. The personal information that we collect
        depends on the context of your interactions with ULURU, the choices you
        make and the products and features you use. The personal information we
        collect may include the following (but is not required for use of app):
        <br />
        <br />
        Account Information. When you create an account on ULURU, we collect
        your name, nickname, email address, and password. Additionally, you are
        required to complete a Basic Questionnaire and detailed Intake
        Questionnaire. Both Questionnaires request personal information but if
        you are not comfortable sharing the requested information, you may
        select "Prefer not to answer." The personal information that you
        voluntarily provide to us does not need to be true, complete or
        accurate.
        <br />
        <br />
        Children are not permitted to create an account by themselves but
        instead must be invited to ULURU by a parent, guardian, therapist, or
        educator. Where children have permission to use ULURU, ULURU only
        collects the child's name as that is the only personally identifiable
        information that is necessary to provide the Service. A parent,
        guardian, or educator may also enter a child's date of birth, but note
        that this information is optional and not required for the Service.
        <br />
        <br />
        Information collected through ULURU. In Short: We may collect
        information regarding your push notifications when you use ULURU. If you
        use ULURU, we may also collect the following information:
        <br />
        <br />
        Push Notifications. We may request to send you push notifications
        regarding your account or the mobile application. If you wish to opt-out
        from receiving these types of communications, you may turn them off in
        your device's settings.
      </p>
      <h4 className="text-lg font-semibold mb-2">
        2. WHY WE COLLECT YOUR INFORMATION?
      </h4>
      <p className="mb-4">
        In Short: If your information is provided, we process your information
        for purposes based on legitimate business interests, the fulfillment of
        our contract with you, compliance with our legal obligations, and/or
        your consent. We use personal information collected via our Services or
        App for a variety of business purposes described below. We process your
        personal information for these purposes in reliance on our legitimate
        business interests, in order to enter into or perform a contract with
        you, with your consent, and/or for compliance with our legal
        obligations. We indicate the specific processing grounds we rely on next
        to each purpose listed below:
        <br />
        <br />
        To facilitate account creation and login process. If you choose to link
        your account with us to a third party account (such as your Google,
        Apple, or Facebook account), we use the information you allowed us to
        collect from those third parties to facilitate account creation and
        login process for the performance of the contract.
        <br />
        <br />
        To contact you. For example, to send you relationship, transactional and
        commercial communications and other marketing and advertising. To
        administer sweepstakes, surveys or contests. To enable user-to-user
        communications. We may use your information in order to enable
        user-to-user communications.
        <br />
        <br />
        For other Business Purposes. We may use your user information for other
        Business Purposes, such data analysis and identifying usage trends. We
        may use and store this information in aggregated and encrypted form so
        that it is not associated with your personal identifiable information,
        individual end users, which means external parties will not be able to
        identify the data that’s associated with you or your family. We will not
        use your information for other Business Purposes, such as sharing with
        third parties for direct marketing purposes.
      </p>
      <h4 className="text-lg font-semibold mb-2">
        3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
      </h4>
      <p className="mb-4">
        In Short: We only share personal identifiable information with your
        consent, to comply with laws, to provide you with services, to protect
        your rights, or to fulfill business obligations. We may process or share
        data based on the following legal basis:
        <br />
        <br />
        Consent: We may process your data if you have given us specific consent
        to use your personal information in a specific purpose. Consent may be
        granted via our website or mobile application.
        <br />
        <br />
        Performance of a Contract: Where we have entered into a contract with
        you, we may process your personal information to fulfill the terms of
        our contract.
        <br />
        <br />
        Legal Obligations: We may disclose your information where we are legally
        required to do so in order to comply with applicable law, governmental
        requests, a judicial proceeding, court order, or legal process, such as
        in response to a court order or a subpoena (including in response to
        public authorities to meet national security or law enforcement
        requirements).
        <br />
        <br />
        Vital Interests: We may disclose your information where we believe it is
        necessary to investigate, prevent, or take action regarding potential
        violations of our policies, suspected fraud, situations involving
        potential threats to the safety of any person and illegal activities, or
        as evidence in litigation in which we are involved. More specifically,
        we may need to process your data or share your personal information in
        the following situations:
        <br />
        <br />
        Business Transfers. We may share or transfer your information in
        connection with, or during negotiations of, any merger, sale of company
        assets, financing, or acquisition of all or a portion of our business to
        another company.
      </p>
      <h4 className="text-lg font-semibold mb-2">
        4. HOW LONG DO WE KEEP YOUR INFORMATION?
      </h4>
      <p className="mb-4">
        In Short: We keep your information for as long as necessary to fulfill
        the purposes outlined in this privacy policy unless otherwise required
        by law. We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy policy, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting or other legal requirements). No purpose in this policy will
        require us keeping your personal information for longer than the period
        of time in which users have an account with us. When we have no ongoing
        legitimate business need to process your personal information, we will
        either delete or anonymize it, or, if this is not possible (for example,
        because your personal information has been stored in backup archives),
        then we will securely store your personal information and isolate it
        from any further processing until deletion is possible.
      </p>
      <h4 className="text-lg font-semibold mb-2">
        5. WHAT ARE YOUR PRIVACY RIGHTS?
      </h4>
      <p className="mb-4">
        In Short: You may review, change, or terminate your account at any time.
        If you are resident in the European Economic Area and you believe we are
        unlawfully processing your personal information, you also have the right
        to complain to your local data protection supervisory authority. You can
        find their contact details here:{' '}
        <a
          href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
          className="text-blue-600 underline"
          target="_blank"
          rel="noreferrer"
        >
          http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
        </a>
        .
        <br />
        <br />
        Account Information: If you would at any time like to review or change
        the information in your account or terminate your account, you can: ■
        Contact us using the contact information provided. Upon your request to
        terminate your account, we will deactivate or delete your account and
        personal identifiable information from our active databases. However,
        some information may be retained in our files to prevent fraud,
        troubleshoot problems, assist with any investigations, enforce our Terms
        of Use and/or comply with legal requirements.
        <br />
        <br />
        Opting out of email marketing: You can unsubscribe from our marketing
        email list at any time by clicking on the unsubscribe link in the emails
        that we send or by contacting us using the details provided below. You
        will then be removed from the marketing email list – however, we will
        still need to send you service-related emails that are necessary for the
        administration and use of your account. To otherwise opt-out, you may: ■
        Contact us using the contact information provided.
      </p>
      <h4 className="text-lg font-semibold mb-2">
        6. CONTROLS FOR DO-NOT-TRACK FEATURES
      </h4>
      <p className="mb-4">
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track (“DNT”) feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected. No uniform
        technology standard for recognizing and implementing DNT signals has
        been finalized. As such, we do not currently respond to DNT browser
        signals or any other mechanism that automatically communicates your
        choice not to be tracked online. If a standard for online tracking is
        adopted that we must follow in the future, we will inform you about that
        practice in a revised version of this privacy policy.
      </p>
      <h4 className="text-lg font-semibold mb-2">
        7. CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS
      </h4>
      <p className="mb-4">
        If you are a resident of California, you are granted specific rights
        regarding access to your personal information. California Civil Code
        Section 1798.83, also known as the “Shine The Light” law, permits our
        users who are California residents to request and obtain from us, once a
        year and free of charge, information about categories of personal
        information (if any) we disclosed to third parties for direct marketing
        purposes and the names and addresses of all third parties with which we
        shared personal information in the immediately preceding calendar year.
        If you are a California resident and would like to make such a request,
        please submit your request in writing to us using the contact
        information provided below. If you are under 18 years of age, reside in
        California, and have a registered account with the Services or App, you
        have the right to request removal of unwanted data that you publicly
        post on the Services or App. To request removal of such data, please
        contact us using the contact information provided below, and include the
        email address associated with your account and a statement that you
        reside in California. We will make sure the data is not publicly
        displayed on the Services or App, but please be aware that the data may
        not be completely or comprehensively removed from our systems.
      </p>
      <h4 className="text-lg font-semibold mb-2">8. PRIVACY POLICY UPDATES</h4>
      <p className="mb-4">
        We will update this policy as necessary to stay compliant with relevant
        laws.We may update this privacy policy from time to time. The updated
        version will be indicated by an updated “Revised” date and the updated
        version will be effective as soon as it is accessible. If we make
        material changes to this privacy policy, we may notify you either by
        prominently posting a notice of such changes or by directly sending you
        a notification. We encourage you to review this privacy policy
        frequently to be informed of how we are protecting your information.
      </p>
      <h4 className="text-lg font-semibold mb-2">
        9. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
      </h4>
      <p className="mb-4">
        If you have questions or comments about this policy, you may email us at{' '}
        <a
          href="mailto:hello@theuluru.com"
          className="text-blue-600 underline"
          target="_blank"
          rel="noreferrer"
        >
          hello@theuluru.com
        </a>{' '}
        or by post to: 2377 23rd Street, Second floor, Astoria NY, 11105 United
        States
      </p>
      <h4 className="text-lg font-semibold mb-2">10. GOOGLE API SERVICES</h4>
      <p className="mb-4">
        Our app respects the privacy of students aged 7-21 years and is
        committed to transparent data usage. When integrating with Google
        Classroom via API, the app receives the following data:
        <ul className="list-disc mb-4 pl-5">
          <li>User Profile: Email address</li>
          <li>Assignments: Details of assignments</li>
          <li>Grades: Student grades</li>
          <li>
            Submissions and Submission Attachments: Information regarding
            submissions along with any attachments
          </li>
          <li>Auth Token Details: Authentication token information</li>
        </ul>
      </p>
      <h4 className="text-lg font-semibold mb-2">HOW WE STORE DATA?</h4>
      <p className="mb-4">
        The data received from Google Classroom data is stored in the Uluru
        database and on cloud Amazon Web Service while being used only. When the
        Uluru app is unlinked from Google Classroom account - the Google
        Classroom data is deleted to follow security purpose. This data is
        shared with:
        <ul className="list-disc mb-4 pl-5">
          <li>
            The Student: Data is accessible exclusively to the student who owns
            the Google account and has legitimate access to it.
          </li>
          <li>
            Verified Parent or Student's Caregiver: Additionally, verified
            parents or caregivers of the student have access to this data.
          </li>
        </ul>
        *We maintain stringent privacy measures to safeguard all information and
        adhere to established privacy standards to ensure the confidentiality
        and security of student data.
      </p>
      <h4 className="text-lg font-semibold mb-2">HOW DO WE USE INFORMATION?</h4>
      <p className="mb-4">
        The data we receive from Google Classroom, including user profile
        information such as email addresses, assignments, grades, submissions,
        submission attachments, and authentication token details, serves the
        following purposes:
        <ul className="list-disc mb-4 pl-5">
          <li>
            Efficient Time Management: We utilize this data to empower students
            in organizing their time and efforts effectively for studying at
            home. By accessing assignments, grades, and submission details
            within our app, students can streamline their study routines and
            optimize their academic performance.
          </li>
          <li>
            Enhanced Academic Success: Our app is designed to support students
            in achieving academic success. By providing seamless access to
            assignments and grades, we aim to foster a conducive learning
            environment that promotes excellence and achievement.
          </li>
          <li>
            Convenience and Accessibility: Our platform offers the convenience
            of receiving assignments directly within the app, eliminating the
            need to switch between multiple platforms like Google Classroom.
            This streamlined approach simplifies the learning process, making it
            more accessible and manageable for students.
          </li>
        </ul>
        We assure you that all data handling practices adhere to strict privacy
        standards and regulations. The data collected is solely used for the
        aforementioned purposes and is shared only with the student who owns the
        Google account and verified parents or caregivers. Your privacy and
        security are our top priorities, and we are committed to maintaining the
        confidentiality and integrity of your information.
      </p>

      <h4 className="text-lg font-semibold mb-2">
        HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </h4>
      <p className="mb-4">
        Based on the laws of some countries, you may have the right to request
        access to the personal information we collect from you, change that
        information, or delete it in some circumstances. To request to review,
        update, or delete your personal information, please email us at{' '}
        <a
          href="mailto:hello@theuluru.com"
          className="text-blue-600 underline"
          target="_blank"
          rel="noreferrer"
        >
          hello@theuluru.com
        </a>
      </p>
      <BackButton />
    </div>
  );
}
