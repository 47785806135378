import Breadcrumbs from '../../components/Breadcrumbs';
import { NavLink, useNavigate } from 'react-router-dom';
import FamilyProfileIcon from '../../icons/FamilyProfileIcon';
import ListIcon from '../../icons/ListIcon';
import LinkIcon from '../../icons/LinkIcon';
import LockIcon from '../../icons/LockIcon';
import SettingsIcon from '../../icons/SettingsIcon';
import NavbarHome from '../../components/NavbarHome';
import React, { FC, ReactNode, useEffect } from 'react';
import { BreadcrumbsItem } from '../../components/Breadcrumbs/Breadcrumbs';
import SubscriptionIcon from '../../icons/SubscriptionIcon';
import useAuth from '../../contexts/AuthContext';
import { useGetTestProgress } from '../../pages/NeuropsychTest/useGetTestProgress';
import Copyright from '../../components/Copyright';
import { CONTACT_PAGE_URL } from '../../pages/ManageSubscription/ManageSubscriptions';

const NavButtonClass =
  'text-base cursor-pointer !text-grey3 p-[12px] w-full flex gap-[12px]';
const ActiveNavButtonClass =
  'text-base cursor-pointer !text-grey3 p-[12px] w-full flex gap-[12px] rounded-[10px] bg-white';

export const ProfileLayout: FC<{
  children: ReactNode;
  breadcrumbValues: BreadcrumbsItem[];
}> = ({ children, breadcrumbValues }) => {
  const { user, checkSubscription, isLoading: isLoadingUser } = useAuth();
  const navigate = useNavigate();

  const { data: testProgressData } = useGetTestProgress();

  const isSubscriptionActive = checkSubscription();

  useEffect(() => {
    if (!isLoadingUser && !user) navigate('/login');
  }, [isLoadingUser]);

  let userTestResult;
  if (user?.testResultData.intermediateResult?.isTestResultAvailable) {
    userTestResult = user.testResultData.intermediateResult;
  }

  if (user?.testResultData.finalResult?.isTestResultAvailable) {
    userTestResult = user.testResultData.finalResult;
  }
  return (
    <NavbarHome>
      <div className="flex flex-col p-[17px] md:p-0 md:flex-row h-full grow">
        <Breadcrumbs
          className="md:hidden mt-[10px] mb-[30px] ml-[30px]"
          values={breadcrumbValues}
        />

        <div className="bg-grey2 rounded-[10px] md:rounded-[0px] w-full md:max-w-[403px] p-[20px] md:py-[64px] md:px-[30px]">
          <h3 className="m-0 text-2xl md:text-4xl text-header-color">
            Profile
          </h3>
          <div className="flex flex-col gap-[10px] mt-[25px]">
            <NavLink
              to="/profile"
              className={({ isActive }) =>
                isActive ? ActiveNavButtonClass : NavButtonClass
              }
            >
              <FamilyProfileIcon />
              <div>Family Profile</div>
            </NavLink>
            {user?.role === 'parent' && (
              <NavLink
                to="/manage-subscriptions"
                className={({ isActive }) =>
                  isActive ? ActiveNavButtonClass : NavButtonClass
                }
              >
                <SubscriptionIcon />
                <div>Subscription Management</div>
              </NavLink>
            )}
            {user?.role === 'student' && isSubscriptionActive && (
              <NavLink
                to={
                  userTestResult
                    ? `/student/neuro-test/result/${userTestResult.resultUid}`
                    : testProgressData?.isTestStarted
                    ? '/student/neuro-test'
                    : '/student/neuro-test/intro'
                }
                className={({ isActive }) =>
                  isActive ? ActiveNavButtonClass : NavButtonClass
                }
              >
                <ListIcon />
                <div>Mental Health Check-In</div>
              </NavLink>
            )}
            {(user?.role === 'parent' || isSubscriptionActive) && (
              <NavLink
                to="/lms"
                className={({ isActive }) =>
                  isActive ? ActiveNavButtonClass : NavButtonClass
                }
              >
                <LinkIcon />
                <div>LMS</div>
              </NavLink>
            )}
            <NavLink
              to="/change-password"
              className={({ isActive }) =>
                isActive ? ActiveNavButtonClass : NavButtonClass
              }
            >
              <LockIcon />
              <div>Change Password</div>
            </NavLink>
            <a href={CONTACT_PAGE_URL} className={NavButtonClass}>
              <SettingsIcon />
              <div>Support</div>
            </a>
          </div>
        </div>
        <div className="md:w-full md:max-w-[574px] p-[30px] 2xl:max-w-full 2xl:pr-[327px] md:py-[40px] md:ml-[131px] md:mr-[40px] h-auto flex flex-col justify-between grow">
          <div>
            <Breadcrumbs className="hidden md:flex" values={breadcrumbValues} />
            {children}
          </div>
          <Copyright />
        </div>
      </div>
    </NavbarHome>
  );
};
