import { httpClient } from '../../api/http/http-client';
import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { red } from '@mui/material/colors';

export interface BindingDataResponse {
  emailExists: boolean;
  bindingAvailable: boolean;
}

export const getBindingData = async ({
  queryKey,
}: {
  queryKey: (string | undefined)[];
}): Promise<BindingDataResponse> => {
  const [, email] = queryKey;
  return httpClient.get<void, BindingDataResponse>(
    '/binding-request/check-availability',
    {
      params: { email },
    },
  );
};

export const useCheckBinding = ({}: {}) => {
  const [email, setEmail] = useState('');

  const queryResult = useQuery(
    ['/binding-request/check-availability', email],
    getBindingData,
    {
      enabled: !!email,
    },
  );

  return {
    ...queryResult,
    checkEmail: setEmail,
  };
};
