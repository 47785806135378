import { useFormContext, Controller } from 'react-hook-form';
import React, { FC } from 'react';
import { RadioButton } from 'primereact/radiobutton';

interface Props {
  name: string;
  label?: string;
  options: Array<{ label: string; value: string }>;
}

export const RadioInput: FC<Props> = ({ name, options, label }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const fieldError = errors[name];
  const message = fieldError?.message?.toString();

  return (
    <div className={'relative'}>
      <div className="card flex gap-[10px] justify-between flex-wrap">
        <div className="flex align-items-center text-sm md:text-base text-grey1">
          {label}
        </div>
        <div className="flex gap-3 grow justify-around">
          {options.map((option, index) => (
            <div key={index} className="flex align-items-center">
              <Controller
                name={name}
                control={control}
                render={({ field }) => (
                  <RadioButton
                    {...field}
                    value={option.value}
                    onChange={(e) => field.onChange(e.value)}
                    checked={field.value === option.value}
                  />
                )}
              />
              <label
                htmlFor={option.value}
                className="ml-2 text-sm md:text-base"
              >
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </div>
      {fieldError && <div className="input-error-text ml-auto">{message}</div>}
    </div>
  );
};
