import React, { FC, useRef, useState } from 'react';
import AssignmentsList from '../AssignmentsList';
import { getHomePath } from '../../utils/getHomePath';
import Breadcrumbs from '../Breadcrumbs';
import useAuth from '../../contexts/AuthContext';
import { Button } from 'primereact/button';
import { useParams } from 'react-router-dom';
import { UserRole } from '../../types/UserProfile';
import CreateAssignmentModal from '../CreateAssignmentModal';

export const AssignmentsListPage = () => {
  const { studentId } = useParams();
  const { user, checkSubscription } = useAuth();
  const [createAssignmentOpen, setCreateAssignmentOpen] = useState(false);
  const assignmentListRef = useRef<{ refetch: () => void }>(null);

  const displayStudentId =
    user?.role === UserRole.PARENT ? studentId : user?.uid;

  const isSubscriptionActive = checkSubscription(studentId);

  return (
    <div className="bg-white h-full w-full pb-[30px] px-[17px] lg:px-[130px] flex flex-col gap-[20px]">
      {displayStudentId ? (
        <CreateAssignmentModal
          studentUid={displayStudentId}
          open={createAssignmentOpen}
          onClose={() => {
            assignmentListRef.current?.refetch();
            setCreateAssignmentOpen(false);
          }}
        />
      ) : (
        false
      )}
      <Breadcrumbs
        className="py-[30px]"
        values={[
          {
            label: 'Home',
            href: getHomePath(user),
          },
          {
            label: 'Assignments',
            href: '#',
          },
        ]}
      />

      <div className="flex justify-between items-center">
        <h2 className="text-2xl md:text-4xl m-0 mr-auto whitespace-nowrap">
          Assignments
        </h2>
        {isSubscriptionActive && (
          <Button
            className="lg:hidden flex p-[12px] h-[50px]"
            icon="pi pi-plus-circle text-2xl"
            onClick={() => {
              setCreateAssignmentOpen(true);
            }}
          ></Button>
        )}
      </div>
      {displayStudentId && (
        <AssignmentsList
          ref={assignmentListRef}
          greyItems
          displayAssignmentPageFilters
          onAddAssignmentClick={() => {
            setCreateAssignmentOpen(true);
          }}
          studentId={displayStudentId}
          numberOfItems={20}
        />
      )}
    </div>
  );
};
