import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Calendar } from 'primereact/calendar';

interface Props {
  name: string;
  placeholder?: string;
  minDate?: Date;
  maxDate?: Date;
  showBigWarning?: boolean;
  icon?: string;
}

export const DateInput: React.FC<Props> = ({
  name,
  placeholder,
  maxDate,
  minDate,
  showBigWarning,
  icon,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [date, setDate] = useState<Date | null>(null);
  const [value, setValue] = useState<string | null>(null);

  const fieldError = errors[name];
  const message = fieldError?.message?.toString();

  useEffect(() => {
    if (control._defaultValues[name]) {
      setDate(control._defaultValues[name]);
    }
  }, [control._defaultValues, name]);

  return (
    <div className={`${!!message ? 'error' : ''} label-container`}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <Calendar
              {...field}
              touchUI
              onChange={(e) => {
                setDate(e.value as Date);
                field.onChange(e);
              }}
              onInput={(e) => {
                // @ts-ignore
                let input = e.target.value;
                if (input === value?.slice?.(0, -1)) {
                  setValue(input);
                  return;
                }

                input = input.replace(/[^\d/]/g, '');

                let [month, day, year] = input.split('/');
                if (month && month > 12) {
                  month = 12;
                } else if (
                  month &&
                  month < 1 &&
                  month.toString().length === 2
                ) {
                  month = 1;
                }

                if (day && day > 31) {
                  day = 31;
                } else if (day && day < 1 && day.toString().length === 2) {
                  day = 1;
                }

                if (year && year.length > 4) {
                  year = year.slice(0, 4);
                }

                let formattedDate = '';
                if (month) {
                  formattedDate += month;
                  if (month.toString().length === 2) {
                    formattedDate += '/';
                  }
                }
                if (day) {
                  formattedDate += day;
                  if (day.toString().length === 2) {
                    formattedDate += '/';
                  }
                }
                if (year) {
                  formattedDate += year;
                }

                // @ts-ignore
                e.target.value = formattedDate;
                setValue(formattedDate);
              }}
              value={date}
              placeholder={placeholder}
              className="w-full"
              dateFormat="mm/dd/yy"
              maxDate={maxDate}
              minDate={minDate}
              showMinMaxRange={false}
              showOtherMonths={false}
              viewDate={maxDate}
            />
            {icon && (
              <i className={`${icon} absolute top-[15px] right-[15px]`} />
            )}
          </>
        )}
      />
      {!showBigWarning && fieldError && (
        <div className="input-error-text">{message}</div>
      )}
      {showBigWarning && fieldError && (
        <div className="text-xs text-white bg-red1 text-center p-[10px] rounded-[5px] mt-[10px]">
          {message}
        </div>
      )}
    </div>
  );
};
