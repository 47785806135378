import React, { FC } from 'react';
import { registrationSteps } from '../../types/registrationSteps';

const RegistrationProgress: FC<{
  registrationSteps: registrationSteps;
}> = ({ registrationSteps }) => {
  const activeStep = Number(registrationSteps?.active) || 1;
  const totalSteps = registrationSteps?.total || 1;
  const stepWidth = 180 / totalSteps;
  const blueLineWidth = activeStep * stepWidth;
  const greyLineWidth = (totalSteps - activeStep) * stepWidth;
  return (
    <div className="flex">
      <span className="text-black1 font-normal font-work-sans whitespace-nowrap">
        Step {activeStep}
      </span>
      <div className="px-2.5 whitespace-nowrap">
        <span
          className="blue line"
          style={{ width: `${blueLineWidth}px` }}
        ></span>
        <span
          className="grey line"
          style={{ width: `${greyLineWidth}px` }}
        ></span>
      </div>
      <span className="text-grey1 font-normal font-work-sans whitespace-nowrap">
        {totalSteps}
      </span>
    </div>
  );
};

export default RegistrationProgress;
