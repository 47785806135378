import { useCallback, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { serverUrl } from '../../constants/environments';
import { credentialsService } from '../../utils/services/credentialsService';
import { AuthorMessage, MessageEntity, useChatWindow } from './useChatWindow';
import { v4 as uuidv4 } from 'uuid';

export const useChatWebhooks = ({ studentUid }: { studentUid?: string }) => {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isFetchingMessage, setIsFetchingMessage] = useState<boolean>(false);
  const [socket, setSocket] = useState<any>(null);
  const { data: messagesData, refetch } = useChatWindow({ studentUid });
  const [messages, setMessages] = useState<MessageEntity[]>([]);

  useEffect(() => {
    if (messagesData) {
      setMessages(messagesData || []);
    }
  }, [messagesData]);

  useEffect(() => {
    const socketInstance = io(serverUrl || '', {
      autoConnect: false,
      extraHeaders: {
        authorization: credentialsService.token || '',
      },
    });
    socketInstance.on('connect', () => {
      setIsConnected(true);
    });

    socketInstance.on('disconnect', () => {
      setIsConnected(false);
    });

    socketInstance.connect();
    setSocket(socketInstance);

    socketInstance.on(
      'response',
      (data: { chunk: boolean; data: string; message: string }) => {
        if (!data.chunk) {
          setIsFetchingMessage(false);
        }
        setMessages((prevMessages) => {
          const lastMessage = { ...prevMessages[0] };
          if (data.chunk) {
            lastMessage.message += data.data;
          } else {
            lastMessage.message = data.data;
          }
          const newMessages = [...prevMessages];
          newMessages[0] = lastMessage;
          return newMessages;
        });
      },
    );

    return () => {
      socketInstance.off('response');
      socketInstance.disconnect();
      setIsConnected(false);
    };
  }, []);

  const sendChatMessage = (message: string) => {
    setIsFetchingMessage(true);
    setMessages((prevMessages) => [
      {
        uid: uuidv4(),
        studentUid: studentUid || '',
        message: '',
        author: AuthorMessage.AI,
        createdAt: new Date(),
      },
      {
        uid: uuidv4(),
        studentUid: studentUid || '',
        message,
        author: AuthorMessage.STUDENT,
        createdAt: new Date(),
      },
      ...prevMessages,
    ]);
    socket?.emit('message', { message });
  };

  return {
    sendChatMessage,
    messages,
    isFetchingMessage,
    isConnected,
  };
};
