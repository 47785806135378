import { httpClient } from '../../api/http/http-client';

export interface ActivitySelectItem {
  label: string;
  value: string;
}

export const getActivitiesBySubject = async (
  subject: string,
): Promise<ActivitySelectItem[]> => {
  return httpClient.get<void, ActivitySelectItem[]>(
    `/coaching/activities/${subject}`,
    {},
  );
};
