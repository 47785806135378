import { useMutation } from '@tanstack/react-query';
import { httpClient } from '../../api/http/http-client';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { AxiosError } from 'axios';
interface CreateAssignmentRequest {
  studentUid: string;
  title: string;
  dueDate: string;
  subject: string;
  activity?: string;
  description: string;
}

const createAssignment = async (
  data: CreateAssignmentRequest,
): Promise<boolean> => {
  return httpClient.post('/assignments', {
    ...data,
  });
};

export const useCreateAssignment = (callbacks: {
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const { handleNetworkError } = useSnackbar();

  const mutation = useMutation(
    (data: CreateAssignmentRequest) => createAssignment(data),
    {
      onSuccess: callbacks.onSuccess,
      onError: (error: AxiosError<{ message: string | string[] }>) => {
        handleNetworkError(error);
        callbacks?.onError?.();
      },
    },
  );

  return { mutation };
};
