import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { httpClient } from '../../api/http/http-client';
import { useSnackbar } from '../../contexts/SnackbarContext';

const finishRegistration = async () => {
  return httpClient.post('/auth/registration/finish', {});
};

export const useFinishRegistration = (callbacks: {
  onSuccess?: () => void;
  onError?: (err: { message: string | string[] }) => void;
}) => {
  const { handleNetworkError } = useSnackbar();

  const mutation = useMutation(finishRegistration, {
    onSuccess: callbacks.onSuccess,
    onError: (error: AxiosError<{ message: string | string[] }>) => {
      handleNetworkError(error);
      callbacks?.onError?.(error);
    },
  });

  return mutation;
};
