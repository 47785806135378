import { Button } from 'primereact/button';
import React from 'react';

type PreviousNextButtonsProps = {
  nextStep?: () => void;
  previousStep?: () => void;
  prevStepButton?: 'button' | 'submit';
  prevLabel?: string;
  nextLabel?: string;
  hideIconNext?: boolean;
  disableNext?: boolean;
};

const PreviousNextButtons: React.FC<PreviousNextButtonsProps> = ({
  nextStep,
  previousStep,
  prevStepButton = 'button',
  prevLabel = 'Previous',
  nextLabel = 'Next',
  disableNext = false,
  hideIconNext = false,
}) => {
  return (
    <div className="flex justify-between previous-next-btn-wrap">
      <Button
        type={prevStepButton}
        label={prevLabel}
        icon="pi pi-arrow-left"
        link
        className="disable-borders text-sm md:text-base ml-[-20px]"
        onClick={previousStep}
      />
      {nextStep && (
        <Button
          label={nextLabel}
          onClick={nextStep}
          disabled={disableNext}
          type="submit"
          icon={hideIconNext ? null : 'pi pi-arrow-right'}
          iconPos="right"
          className="text-sm md:text-base py-[12px] px-[45px] rounded-[8px]"
        />
      )}
    </div>
  );
};

export default PreviousNextButtons;
