import React, { FC, useEffect, useMemo, useState } from 'react';
import { Modal } from '@mui/material';
import { Button } from 'primereact/button';
import {
  AssignmentSuggestionsStatus,
  CoachingSuggestionType,
  useGetCoaching,
} from './useGetCoaching';
import { capitalize } from 'lodash';
import { ProgressSpinner } from 'primereact/progressspinner';

export const CoachingSuggestionsModal: FC<{
  open: boolean;
  onClose: () => void;
  subject: string;
  assignmentUid: string;
}> = ({ open, onClose, subject, assignmentUid }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { data, refetch, isFetching, remove } = useGetCoaching({
    id: assignmentUid,
    enabled: open,
  });

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open]);

  const handleClose = useMemo(() => {
    return () => {
      onClose();
      setCurrentIndex(0);
      remove();
    };
  }, [onClose]);

  const currentSuggestion = Array.isArray(data)
    ? data?.[currentIndex]
    : { type: '', domain: '', coachingSuggestion: '' };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={`flex items-center justify-center`}
    >
      <div className="w-full max-w-[776px] h-fit p-[17px] outline-none">
        <div className="relative w-full h-full p-[30px] bg-white rounded-[10px] flex flex-col gap-[20px]">
          <Button
            className="absolute top-[10px] right-[10px]"
            icon="pi pi-times"
            rounded
            text
            severity="secondary"
            aria-label="Close modal"
            onClick={handleClose}
          />
          <div className="m-auto w-[136px] md:w-[160px]">
            <img
              src="/images/uluru-logo/wink.svg"
              alt="Uluru Logo"
              className="w-full"
            />
          </div>
          {isFetching ||
          (data !== undefined &&
            'suggestionsStatus' in data &&
            data.suggestionsStatus === AssignmentSuggestionsStatus.LOADING) ? (
            <ProgressSpinner className="m-auto" />
          ) : (
            <>
              <h2 className="text-2xl md:text-4xl text-center m-0">
                {currentSuggestion?.type ===
                CoachingSuggestionType.SUBJECT_SPECIFIC
                  ? subject
                  : currentSuggestion?.domain}
              </h2>
              <p className="m-0 px-[10px] md:px-[20px] text-base md:text-lg mb-[20px]">
                {!isFetching &&
                  capitalize(currentSuggestion?.coachingSuggestion || '')}
              </p>

              <div className="flex justify-between w-full">
                <Button
                  className="flex gap-[10px] pl-[5px] !text-blue1"
                  link
                  onClick={() => {
                    setCurrentIndex(
                      currentIndex <= 0
                        ? ((Array.isArray(data) && data?.length) || 1) - 1
                        : currentIndex - 1,
                    );
                  }}
                >
                  <i className="pi pi-arrow-left" />
                  <span>Back</span>
                </Button>
                <Button
                  className="flex gap-[10px] px-[40px]"
                  onClick={() => {
                    setCurrentIndex(
                      currentIndex >=
                        ((Array.isArray(data) && data?.length) || 1) - 1
                        ? 0
                        : currentIndex + 1,
                    );
                  }}
                >
                  <span>Next</span>
                  <i className="pi pi-arrow-right" />
                </Button>
              </div>
            </>
          )}

          <div className="text-[10px] text-center text-grey4 mt-[20px]">
            Don’t click too fast as you should develop the strategies from the
            previous suggestions to create good habits
          </div>
        </div>
      </div>
    </Modal>
  );
};
