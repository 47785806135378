import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { httpClient } from '../../api/http/http-client';

export interface SurveyAnswerData {
  question: string;
  answers: string[];
}

export const getSurveyResult = async () => {
  return httpClient.get<void, { surveys: SurveyAnswerData[] }>(
    '/survey/results',
  );
};

const submitSurveyAnswers = async (data: SurveyAnswerData) => {
  return httpClient.post('/survey', { surveys: [data] });
};

export const useSurveySteps = (callbacks: {
  onSuccess?: () => void;
  onError?: (error: AxiosError<{ message: string | string[] }>) => void;
}) => {
  const { handleNetworkError } = useSnackbar();

  const mutation = useMutation(
    (data: SurveyAnswerData) => submitSurveyAnswers(data), // Update the mutation function
    {
      onSuccess: callbacks.onSuccess ?? (() => {}),
      onError: (error: AxiosError<{ message: string | string[] }>) => {
        handleNetworkError(error);
        callbacks?.onError?.(error);
      },
    },
  );

  return { mutation };
};
