import React from 'react';
import { RegisterStudentProvider } from './RegisterStudentContext';
import { Outlet } from 'react-router';

const RegisterStudentLayout: React.FC = () => {
  return (
    <RegisterStudentProvider>
      <Outlet />
    </RegisterStudentProvider>
  );
};

export default RegisterStudentLayout;
