import { useMutation } from '@tanstack/react-query';
import { httpClient } from '../../api/http/http-client';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { AxiosError } from 'axios';

const createBinding = async ({
  email,
}: {
  email: string;
}): Promise<boolean> => {
  return httpClient.post(`/binding-request`, {
    email,
  });
};

export const useCreateBinding = (options: {
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const { handleNetworkError } = useSnackbar();

  const mutation = useMutation(createBinding, {
    onSuccess: options.onSuccess,
    onError: (error: AxiosError<{ message: string | string[] }>) => {
      handleNetworkError(error);
      options?.onError?.();
    },
  });

  return mutation;
};
