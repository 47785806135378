import { httpClient } from '../../api/http/http-client';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

export enum CoachingSuggestionType {
  SUBJECT_SPECIFIC = 'subject_specific',
  GENERAL = 'general',
}

export enum AssignmentSuggestionsStatus {
  WAITING = 'waiting',
  LOADING = 'loading',
  COMPLETED = 'completed',
}

export interface CoachingSuggestion {
  coachingSuggestion: string;
  type: CoachingSuggestionType;
  domain?: string;
}

export const getCoaching = async ({
  queryKey,
}: {
  queryKey: (string | undefined)[];
}): Promise<
  CoachingSuggestion[] | { suggestionsStatus: AssignmentSuggestionsStatus }
> => {
  const [, id] = queryKey;

  return httpClient.get<
    void,
    CoachingSuggestion[] | { suggestionsStatus: AssignmentSuggestionsStatus }
  >(`/coaching/${id}`, {});
};

export const useGetCoaching = ({
  id,
  enabled,
}: {
  id?: string;
  enabled: boolean;
}) => {
  const [keepLoading, setKeepLoading] = useState(true);

  const useQueryResult = useQuery(['/coaching', id], getCoaching, {
    enabled: !!id && enabled,
    refetchInterval: keepLoading ? 3000 : false,
    retry: false,
    staleTime: Infinity,
    cacheTime: Infinity,
    keepPreviousData: false,
  });

  useEffect(() => {
    if (useQueryResult.data && Array.isArray(useQueryResult.data)) {
      setKeepLoading(false);
    }
  }, [useQueryResult.data]);

  return {
    ...useQueryResult,
  };
};
