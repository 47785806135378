import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { httpClient } from '../../api/http/http-client';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { StudentFields } from '../RegisterStudentForm/RegisterStudentForm';

export interface EditProfileData {
  email: string;
  firstName: string;
  lastName: string;
  birthday: string;
  country?: string;
  street?: string;
  street2?: string;
  state?: string;
  zipCode: string;
  phone: string;
  childRelationship: string;
  students: (StudentFields | { birthday: string })[];
}

interface EditProfileResponse {}

const editProfile = async (
  data: EditProfileData,
): Promise<EditProfileResponse> => {
  return httpClient.put('/family-profile', { ...data });
};

export const useEditProfile = (callbacks: {
  onSuccess?: (data: EditProfileResponse) => void;
  onError?: (error: AxiosError<{ message: string | string[] }>) => void;
}) => {
  const { handleNetworkError } = useSnackbar();
  const mutation = useMutation(
    (parentData: EditProfileData) => editProfile(parentData),
    {
      onSuccess: callbacks.onSuccess ?? (() => {}),
      onError: (error: AxiosError<{ message: string | string[] }>) => {
        handleNetworkError(error);
        callbacks?.onError?.(error);
      },
    },
  );

  return { mutation };
};
