import { FormField } from '../../types/form';

export enum SurveyTypes {
  TransformationInfoStep = 'TransformationInfoStep',
  SupportInfoStep = 'SupportInfoStep',
  SupportRelationshipStep = 'SupportRelationshipStep',
  MostChallengingStep = 'MostChallengingStep',
}

export interface QuestionFields {
  question: string;
  answers: FormField[];
}
