import { production } from '../../constants/environments';
import React, { ErrorInfo, ReactNode } from 'react';

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<
  { children: ReactNode },
  ErrorBoundaryState
> {
  state = { hasError: false };

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    /**
     * Here you can log the error
     */
    if (!production) {
      console.error('ERROR:', error);
      console.error('ERROR INFO:', errorInfo);
    }
  }

  render() {
    if (this.state.hasError) {
      return <h3>errors.unknown</h3>;
    }

    return this.props.children;
  }
}
