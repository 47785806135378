import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import AssignmentsListItem from '../AssignmentsListItem';
import { SelectItem } from 'primereact/selectitem';
import {
  sortByOptions,
  statusList,
} from '../AssignmentPageFilters/AssignmentPageFilters';
import TodoListFilters from '../TodoListFilters';
import AssignmentPageFilters from '../AssignmentPageFilters';
import { useGetAssignmentList } from './useGetAssignmentList';
import { SkeletonAssignmentItems } from '../HomeAssignmentsList/HomeAssignmentsList';
import { Link, useSearchParams } from 'react-router-dom';
import EducationalStatusBanner from '../EducationalStatusBanner';
import { useGetAssignmentTodoCount } from './useGetAssignmentTodoCount';
import VerifyEmailBanner from '../VerifyEmailBanner';
import useAuth from '../../contexts/AuthContext';
import { UserRole } from '../../types/UserProfile';

interface Props {
  title?: string;
  displayAddAssignmentButtonInBottom?: boolean;
  greyItems?: boolean;
  onAddAssignmentClick: () => void;
  studentId: string;
  displayTodoListFilters?: boolean;
  displayAssignmentPageFilters?: boolean;
  excludePastCompleted?: boolean;
  displayHomePageBanners?: boolean;
  numberOfItems?: number;
}

const defaultSortBy = sortByOptions[0].value;

export const AssignmentsList = forwardRef<{ refetch: () => void }, Props>(
  (
    {
      title,
      displayAddAssignmentButtonInBottom,
      greyItems,
      onAddAssignmentClick,

      displayAssignmentPageFilters,
      displayTodoListFilters,
      studentId,
      excludePastCompleted,
      displayHomePageBanners,
      numberOfItems = 5,
    },
    ref,
  ) => {
    const defaultStatus = displayAssignmentPageFilters
      ? statusList[2].value
      : statusList[0].value;

    const [searchParams, setSearchParams] = useSearchParams();
    const [isNoAssignments, setIsNoAssignments] = useState(false);

    const [sortBy, setSortBy] = useState<string>(defaultSortBy);
    const [search, setSearch] = useState<string>('');
    const [subject, setSubject] = useState<string>('');
    const [status, setStatus] = useState<string>(defaultStatus);
    const [page, setPage] = useState<number>(1);
    const {
      data: todoCountData,
      refetch: refetchCount,
      isLoading: isLoadingTodoCount,
    } = useGetAssignmentTodoCount({
      studentId,
      enabled: displayHomePageBanners,
    });
    const { checkSubscription, user } = useAuth();
    const isParent = user?.role === UserRole.PARENT;

    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const { data, isFetching, refetch } = useGetAssignmentList({
      sortByDate: sortBy,
      search,
      subject,
      status,
      studentId,
      limit: String(numberOfItems),
      page: String(page),
      excludePastCompleted: excludePastCompleted ? 'true' : 'false',
    });

    useEffect(() => {
      if (!isFetching) {
        if (isInitialLoading) {
          setIsInitialLoading(false);
          setIsNoAssignments(!data?.assignments.length);
        }
      }
    }, [isFetching]);

    useImperativeHandle(ref, () => ({
      refetch: () => {
        setIsNoAssignments(false);
        refetch();
        refetchCount();
      },
    }));

    const subjectList: SelectItem[] = [
      { label: 'All', value: '' },
      ...(data?.subjects.map((s) => ({
        label: s,
        value: s,
      })) || []),
    ];

    const resetPage = useMemo(
      () => () => {
        setPage(1);
      },
      [searchParams],
    );

    const updateSortBy = useMemo(
      () => (value: string) => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (value === defaultSortBy) {
          newSearchParams.delete('sortBy');
        } else {
          newSearchParams.set('sortBy', value);
        }
        newSearchParams.delete('page');
        setSearchParams(newSearchParams, { replace: true });
        setSortBy(value);
        resetPage();
      },
      [searchParams],
    );

    const updateSubject = useMemo(
      () => (value: string) => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (value === '') {
          newSearchParams.delete('subject');
        } else {
          newSearchParams.set('subject', value);
        }
        newSearchParams.delete('page');
        setSearchParams(newSearchParams, { replace: true });
        setSubject(value);
        resetPage();
      },
      [searchParams],
    );

    const updateSearch = useMemo(
      () => (value: string) => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (value === '') {
          newSearchParams.delete('search');
        } else {
          newSearchParams.set('search', value);
        }
        newSearchParams.delete('page');
        setSearchParams(newSearchParams, { replace: true });
        setSearch(value);
        resetPage();
      },
      [searchParams],
    );

    const updateStatus = useMemo(
      () => (value: string) => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (value === defaultStatus) {
          newSearchParams.delete('status');
        } else {
          newSearchParams.set('status', value);
        }
        newSearchParams.delete('page');
        setSearchParams(newSearchParams, { replace: true });
        setStatus(value);
        resetPage();
      },
      [searchParams],
    );

    const isSubscriptionActive = checkSubscription(studentId);

    useEffect(() => {
      if (searchParams.get('sortBy') !== sortBy) {
        setSortBy(searchParams.get('sortBy') || defaultSortBy);
      }
      if (searchParams.get('search') !== search) {
        setSearch(searchParams.get('search') || '');
      }
      if (searchParams.get('subject') !== subject) {
        setSubject(searchParams.get('subject') || '');
      }
      if (searchParams.get('status') !== status) {
        setStatus(searchParams.get('status') || defaultStatus);
      }
      if (searchParams.get('page') !== String(page)) {
        setPage(Number(searchParams.get('page')) || 1);
      }
    }, [searchParams.get('studentId')]);

    if (isInitialLoading && displayHomePageBanners) {
      return <div className="min-h-[100vh] md:min-h-fit"></div>;
    }

    if (isNoAssignments && displayHomePageBanners) {
      return (
        <div className="w-full flex flex-col gap-[17px] items-center">
          <VerifyEmailBanner />
          <h2 className="text-2xl md:text-4xl text-center m-0 mt-[15px] md:mt-[90px]">
            {isSubscriptionActive
              ? 'There are no assignments yet'
              : 'Current student account does not include a subscription, which means some features are limited'}
          </h2>
          {isSubscriptionActive ? (
            <p className="text-center m-0 text-base">
              Add first assignment manually or link Uluru account to{' '}
              <Link to={'/lms'}>Google Classroom</Link>
            </p>
          ) : (
            <p className="text-center m-0 text-base">
              Unlock enhanced features of the ULURU platform by{' '}
              <Link to={isParent ? '/manage-subscriptions' : '#'}>
                upgrading to a subscription
              </Link>
            </p>
          )}
          <Button
            className={`w-fit flex gap-[10px] ${
              !isSubscriptionActive && '!bg-grey4 !border-grey4'
            }`}
            onClick={onAddAssignmentClick}
            disabled={!isSubscriptionActive}
          >
            <span>Add Assignment</span> <i className="pi pi-plus-circle" />
          </Button>
          <SkeletonAssignmentItems />
        </div>
      );
    }

    return (
      <div className="w-full flex flex-col gap-[30px]">
        {displayHomePageBanners && (
          <>
            {!isSubscriptionActive && (
              <>
                <h2 className="text-xl md:text-3xl text-center m-0 mt-[30px]">
                  Current student account does not include a subscription, which
                  means some features are limited
                </h2>
                <p className="text-center m-0 text-base">
                  Unlock enhanced features of the ULURU platform by{' '}
                  <Link to={isParent ? '/manage-subscriptions' : '#'}>
                    upgrading to a subscription
                  </Link>
                </p>
              </>
            )}
            {todoCountData && !isLoadingTodoCount && (
              <EducationalStatusBanner todoCount={todoCountData.count} />
            )}
            <VerifyEmailBanner />
          </>
        )}
        <div className="flex flex-col md:flex-row gap-[30px] md:gap-[20px] items-center flex-wrap">
          {title ? (
            <h2 className="text-2xl md:text-4xl m-0 mr-auto whitespace-nowrap">
              {title}
            </h2>
          ) : (
            false
          )}
          {displayTodoListFilters ? (
            <TodoListFilters
              subjectList={subjectList}
              sortBy={sortBy}
              setSortBy={updateSortBy}
              subject={subject}
              setSubject={updateSubject}
            />
          ) : (
            false
          )}
          {displayAssignmentPageFilters ? (
            <AssignmentPageFilters
              subject={subject}
              setSubject={updateSubject}
              sortBy={sortBy}
              setSortBy={updateSortBy}
              search={search}
              setSearch={updateSearch}
              subjectList={subjectList}
              status={status}
              setStatus={updateStatus}
              onAddAssignmentClick={onAddAssignmentClick}
              isSubscriptionActive={isSubscriptionActive}
            />
          ) : (
            false
          )}
        </div>
        <div className="flex flex-col gap-[10px]">
          {data?.assignments.map((assignment, i) => (
            <AssignmentsListItem
              key={assignment.uid}
              index={i}
              isGrey={greyItems}
              assignment={assignment}
              allowToClick={isSubscriptionActive}
            />
          ))}
        </div>
        {displayAddAssignmentButtonInBottom && isSubscriptionActive ? (
          <Button
            className="mt-[-20px] !bg-grey2 !border-none !text-header-color text-sm flex gap-[10px] items-center justify-center"
            onClick={onAddAssignmentClick}
          >
            <span>Add Assignment</span>
            <i className="pi pi-plus-circle text-blue1" />
          </Button>
        ) : (
          false
        )}
        <Paginator
          className="p-0"
          first={(page - 1) * numberOfItems}
          rows={numberOfItems}
          totalRecords={data?.total}
          onPageChange={(event) => {
            const newPage = event.first / event.rows + 1;
            const newSearchParams = new URLSearchParams(searchParams);
            if (newPage === 1) {
              newSearchParams.delete('page');
            } else {
              newSearchParams.set('page', String(newPage));
            }
            setSearchParams(newSearchParams, { replace: true });
            setPage(newPage);
          }}
        />
      </div>
    );
  },
);
