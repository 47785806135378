import React, { FC } from 'react';
import { Link } from 'react-router-dom';

export interface BreadcrumbsItem {
  label: string;
  href: string;
}

export interface BreadcrumbsProps {
  values: BreadcrumbsItem[];
  className?: string;
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ values, className }) => {
  return (
    <div className={`flex gap-[10px] items-center text-sm ${className}`}>
      {values.map((b, index) => (
        <div className="flex gap-[10px] items-center" key={index}>
          <Link
            className="text-header-color truncate max-w-[300px]"
            to={b.href}
          >
            {b.label}
          </Link>
          {index !== values.length - 1 && (
            <i className="pi pi-arrow-right text-xs" />
          )}
        </div>
      ))}
    </div>
  );
};
