import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { httpClient } from '../../../api/http/http-client';
import { useSnackbar } from '../../../contexts/SnackbarContext';

interface CreateStudentAccountData {
  email: string;
  password: string;
  firstName: string;
  linkCode: string;
  phone: string;
}

interface RegisterStudentResponse {
  email: string;
  accessToken: string;
}

const registerStudent = async (
  student: CreateStudentAccountData,
): Promise<RegisterStudentResponse> => {
  return httpClient.post<void, RegisterStudentResponse>(
    '/student/signup',
    student,
  );
};

export const useCreateStudentAccount = (callbacks: {
  onSuccess?: (res: RegisterStudentResponse) => void;
  onError?: (error: AxiosError<{ message: string | string[] }>) => void;
}) => {
  const { handleNetworkError } = useSnackbar();
  const mutation = useMutation(
    (student: CreateStudentAccountData) => registerStudent(student),
    {
      onSuccess: callbacks.onSuccess ?? (() => {}),
      onError: (error: AxiosError<{ message: string | string[] }>) => {
        handleNetworkError(error);
        callbacks?.onError?.(error);
      },
    },
  );

  return { mutation };
};
