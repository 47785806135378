import React, { FC, useState } from 'react';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

interface Props {
  onInputChange: (newInput: string) => void;
}

const CalculatorKeyboard: FC<Props> = ({ onInputChange }) => {
  const handleKeyPress = (button: string) => {
    const paddedSymbols = [
      '√x',
      '3√x',
      'n√x',
      'x^',
      'π',
      'e',
      'x',
      'y',
      'z',
      'pi',
    ];

    if (paddedSymbols.includes(button)) {
      onInputChange(' ' + button);
    } else {
      onInputChange(button);
    }
  };

  return (
    <div>
      <Keyboard
        onKeyPress={handleKeyPress}
        layout={{
          default: [
            '( ) | [ ] >=',
            '+ - * / ^ <=',
            'x √x 7 8 9 >',
            'y n√x 4 5 6 <',
            'z x^ 1 2 3 =',
            'e pi , 0 . %',
          ],
        }}
        display={{
          '√x': '√x',
          '3√x': '∛x',
          'x^': 'xⁿ',
          pi: 'π',
          '*': '×',
          '/': '÷',
          '>=': '≥',
          '<=': '≤',
        }}
      />
    </div>
  );
};

export default CalculatorKeyboard;
