import { httpClient } from '../../api/http/http-client';
import { StudentFields } from '../../components/RegisterStudentForm/RegisterStudentForm';
import { Student } from '../RegisterStudent/useRegisterStudent';
import { AxiosError } from 'axios';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { useMutation, useQuery } from '@tanstack/react-query';

export interface SubscriptionListItem {
  fullName: string;
  userUid: string;
  dueDate: Date;
  status: string;
  price: number;
  containsFinal?: boolean;
  signedMoreThan14daysAgo?: boolean;
}

export enum StripeStatus {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  INCOMPLETE = 'incomplete',
  EXPIRED = 'incomplete_expired',
  PAST_DUE = 'past_due',
  PAUSED = 'paused',
  TRIALING = 'trialing',
  UNPAID = 'unpaid',
  PENDING = 'pending',
}

export const StatusLabel: { [key: string]: string } = {
  active: 'Active',
  inactive: 'Inactive',
  incomplete_expired: 'Expired',
  canceled: 'Canceled',
  pending: 'Not Active',
  incomplete: 'Incomplete',
  past_due: 'Past Due',
  paused: 'Paused',
  trialing: 'Trialing',
  unpaid: 'Unpaid',
};

export const getStatusLabel = (label: string): string => {
  return StatusLabel[label] || label;
};

export const getSubscriptionsList = async (): Promise<
  SubscriptionListItem[]
> => {
  return httpClient.get<void, SubscriptionListItem[]>('/subscriptions/list');
};
export const useGetSubscriptionsList = () => {
  const res = useQuery(['/subscriptions-list'], getSubscriptionsList, {
    keepPreviousData: true,
  });
  const data = res.data ?? [];
  return { ...res, data };
};

export const createStudent = async (
  student: StudentFields,
): Promise<Student> => {
  return httpClient.post<null, Student>('/parents/student', student);
};

export const cancelSubscriptionRequest = async (data: {
  selectedStudents: {
    [uid: string]: boolean;
  };
  reason: string;
}): Promise<void> => {
  return httpClient.post<void, void>('/subscriptions/cancel', data);
};

export const useCancelSubscription = (callbacks: {
  onSuccess?: () => void;
  onError?: (error: AxiosError<{ message: string | string[] }>) => void;
}) => {
  const { handleNetworkError } = useSnackbar();
  const mutation = useMutation(
    (data: {
      selectedStudents: {
        [uid: string]: boolean;
      };
      reason: string;
    }) => cancelSubscriptionRequest(data),
    {
      onSuccess: callbacks.onSuccess ?? (() => {}),
      onError: (error: AxiosError<{ message: string | string[] }>) => {
        handleNetworkError(error);
        callbacks?.onError?.(error);
      },
    },
  );

  return { mutation };
};
