import React, { FC } from 'react';

const LoadingProgress: FC<{
  label: string;
  current: number | undefined;
  total: number | undefined;
}> = ({ label = '', current = 0, total = 0 }) => {
  const stepWidth = 180 / total;
  const blueLineWidth = current * stepWidth;
  const greyLineWidth = (total - current) * stepWidth;
  return (
    <div className="flex">
      <span>
        {label} {current}
      </span>
      <div className="px-2.5">
        <span
          className="blue line"
          style={{ width: `${blueLineWidth}px` }}
        ></span>
        <span
          className="grey line"
          style={{ width: `${greyLineWidth}px` }}
        ></span>
      </div>
      <span>{total}</span>
    </div>
  );
};

export default LoadingProgress;
