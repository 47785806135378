import { Outlet } from 'react-router';
import useAuth from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { UserRole } from '../types/UserProfile';
import { useEffect } from 'react';

const Grey = () => {
  const { user, isLoading } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoading && user?.role !== UserRole.PARENT) navigate('/');
  }, [isLoading]);
  return (
    <div className="bg-grey">
      <Outlet />
    </div>
  );
};

export default Grey;
