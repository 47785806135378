import { useEffect, useState } from 'react';
import { httpClient } from '../../api/http/http-client';
import { ExternalPopup } from '../../components/ExternalPopup/useExternalPopup';
import { useQuery } from '@tanstack/react-query';

export enum BlackbaudTokenStatus {
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified',
}

export interface StudentBBLink {
  token: string;
  studentUid: string;
  isLinked: boolean;
  status: BlackbaudTokenStatus;
}

export const getBBLink = async (studentUid: string): Promise<StudentBBLink> => {
  return httpClient.get<void, StudentBBLink>(`/blackbaud/link/${studentUid}`);
};

export const unlinkBBLogin = async (studentUid: string | undefined) => {
  return httpClient.delete<void, void>(`/blackbaud/unlink/${studentUid}`);
};

export const getBlackbaudLoginUrl = async (
  studentUid: string,
): Promise<{ url: string }> => {
  return httpClient.get<void, { url: string }>(`/blackbaud/url/${studentUid}`);
};
export const handleBlackbaudLogin = async (studentUid: string) => {
  const url = (await getBlackbaudLoginUrl(studentUid)).url;

  return ExternalPopup(url);
};

export const getBlackbaudLink = async ({
  queryKey,
}: {
  queryKey: (string | number | undefined)[];
}): Promise<StudentBBLink> => {
  const [, studentUid] = queryKey;
  return httpClient.get<void, StudentBBLink>(`/blackbaud/link/${studentUid}`);
};

export const useBlackbaud = ({
  studentUid,
}: {
  studentUid?: string | undefined;
}) => {
  const [keepLoading, setKeepLoading] = useState(true);
  const useQueryResult = useQuery(
    [`/blackbaud/link`, studentUid],
    getBlackbaudLink,
    {
      refetchInterval: keepLoading ? 3000 : false,
      enabled: true,
    },
  );

  useEffect(() => {
    setKeepLoading(
      !(
        useQueryResult.data &&
        useQueryResult.data.status === BlackbaudTokenStatus.VERIFIED
      ),
    );
  }, [useQueryResult.data]);

  return {
    ...useQueryResult,
  };
};
