import { Button } from 'primereact/button';
import PlayIcon from '../../icons/PlayIcon';
import React, { FC } from 'react';
import useAuth from '../../contexts/AuthContext';
import useSelectedStudent from '../../contexts/SelectedStudentContext';
import { UserRole } from '../../types/UserProfile';
import { ResultStatus } from '../../pages/TestResult/useGetTestResult';
import { TestStatus } from '../../pages/NeuropsychTest/useGetTestProgress';

interface Props {
  onStartClick: () => void;
  completionPercentage?: number;
  testStatus?: TestStatus;
  testResultPercent?: number;
  testResultStatus?: string;
  onSummaryClick?: () => void;
  studentId?: string;
  studentNumber?: number;
}

const PercentageLine = ({
  completionPercentage,
  lineText,
}: {
  completionPercentage: number;
  lineText?: string;
}) => {
  return (
    <div className="flex items-center gap-[10px]">
      <div
        style={{ backgroundColor: 'rgba(29, 30, 32, 0.10)' }}
        className="w-full h-[2px] relative"
      >
        <div
          style={{ width: `${completionPercentage}%` }}
          className="bg-blue1 h-full transition-width duration-300 ease-in-out"
        ></div>
      </div>
      <div className="shrink-0 text-base text-grey1">{lineText}</div>
    </div>
  );
};

export const StartNeurotest: FC<Props> = ({
  onStartClick,
  completionPercentage,
  testResultStatus,
  testResultPercent,
  testStatus,
  onSummaryClick,
  studentId,
  studentNumber,
}) => {
  const { checkSubscription, user } = useAuth();
  const isSubscriptionActive = checkSubscription(studentId);
  const { selectedStudent } = useSelectedStudent();

  const name =
    user?.role === UserRole.STUDENT ? user.fullName : selectedStudent?.fullName;

  return (
    <div className="relative w-full bg-yellow2 p-[30px] rounded-[10px] shrink-0 gap-[10px] flex flex-col">
      <div
        className={`absolute top-[3px] left-[4px] bg-white rounded-[10px] flex py-[4px] px-[24px] gap-[10px]`}
      >
        <img
          src={
            isSubscriptionActive
              ? '/images/green-profile-pic.svg'
              : '/images/default-profile-pic.svg'
          }
          alt=""
        />
        <div className="max-w-[300px]">
          <div className="text-[10px] text-grey3">
            Student {studentNumber || ''}
          </div>
          <div className="text-base text-header-color truncate">{name}</div>
        </div>
      </div>
      <img
        src="/images/test-logo.svg"
        alt=""
        className="m-auto block max-w-[313px] w-full"
      />
      {testStatus === TestStatus.IN_PROGRESS && completionPercentage ? (
        <PercentageLine
          completionPercentage={completionPercentage}
          lineText={`${100 - completionPercentage}% left`}
        />
      ) : (
        false
      )}
      <h2
        className={`${
          user?.role === UserRole.PARENT ? 'text-[29px]' : 'text-4xl'
        } m-0`}
      >
        {user
          ? user?.role === UserRole.PARENT
            ? 'Parent neuro diagnostic'
            : 'Mental Health Check-In'
          : false}
      </h2>
      <p className="text-base text-grey3 m-0">
        {user?.role === UserRole.PARENT
          ? 'Discover the strength of your student for academic growth'
          : 'Discover strengths and areas for academic growth with Uluru'}
      </p>

      {isSubscriptionActive && (
        <>
          {(testResultStatus === ResultStatus.PARTIALLY_READY ||
            testResultStatus === ResultStatus.GENERATING) && (
            <div className="mt-[20px] mb-[-10px]">
              <PercentageLine
                completionPercentage={testResultPercent || 0}
                lineText={`${testResultPercent || 0}%`}
              />
            </div>
          )}

          {testResultStatus === ResultStatus.READY ||
          testResultStatus === ResultStatus.PARTIALLY_READY ? (
            <Button
              className="mt-[30px] mb-[20px] w-full text-sm md:text-base text-center justify-center"
              onClick={onSummaryClick}
            >
              <span className="mr-[10px]">View Summary</span>
              <PlayIcon />
            </Button>
          ) : (
            false
          )}

          {!testStatus || testStatus === TestStatus.IN_PROGRESS ? (
            <>
              <Button
                className="mt-[30px] mb-[20px] w-full text-sm md:text-base text-center justify-center"
                onClick={onStartClick}
              >
                <span className="mr-[10px]">
                  {testStatus === TestStatus.IN_PROGRESS
                    ? 'Back to the Test'
                    : 'Start test'}
                </span>
                <PlayIcon />
              </Button>
              {user?.role === UserRole.STUDENT && (
                <p className="text-grey3 text-sm m-0 mb-[-10px] mt-[-20px]">
                  *Parents won’t see your answers.
                </p>
              )}
            </>
          ) : (
            false
          )}
        </>
      )}
    </div>
  );
};
