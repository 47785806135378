import React, { useCallback, useEffect, useState } from 'react';
import RegistrationProgress from '../../components/RegistrationProgress/RegistrationProgress';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { CheckboxInput } from '../../components/CheckboxInput/CheckboxInput';
import PreviousNextButtons from '../../components/PreviousNextButtons/PreviousNextButtons';
import { yupResolver } from '@hookform/resolvers/yup';
import { SurveyQuestionKeys, SurveyQuestions, SurveySchema } from './constant';
import {
  getSurveyResult,
  SurveyAnswerData,
  useSurveySteps,
} from './useSurveySteps';
import { useRegisterContext } from '../../layouts/RegisterLayout/RegisterContext';
import TextareaInput from '../../components/TextareaInput';
import { QuestionFields } from './types';
import { credentialsService } from '../../utils/services/credentialsService';
import { useLocation } from 'react-router-dom';
import { useGetStudentsList } from '../InvintationLinks/useInvitationLinks';
import Copyright from '../../components/Copyright';
import { ProgressSpinner } from 'primereact/progressspinner';

const SurveySteps: React.FC = () => {
  const {
    registrationSteps,
    goToNextStep,
    goToPrevStep,
    updateRegistrationSteps,
  } = useRegisterContext();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const questionParam = queryParams.get('question');

  //parse question param to number or 1 as default
  const currentStep = questionParam ? parseInt(questionParam) : 1;

  updateRegistrationSteps(3 + currentStep);

  //If user not login - skip this step
  const token = credentialsService.credentials?.token;
  if (!token) {
    goToPrevStep();
  }

  const { data: students, isLoading: isLoadingStudents } = useGetStudentsList(
    {},
  );

  const [surveyResult, setSurveyResult] = useState<SurveyAnswerData[]>([]);
  useEffect(() => {
    getSurveyResult().then((result) => {
      setSurveyResult(result?.surveys || []);
    });
  }, []);

  const { mutation } = useSurveySteps({
    onSuccess: () => {
      getSurveyResult().then((result) => {
        setSurveyResult(result?.surveys || []);
      });
    },
  });

  const currentQuestion: QuestionFields =
    SurveyQuestions[SurveyQuestionKeys[currentStep - 1]];

  const onSubmit: SubmitHandler<{ [key: string]: boolean }> = (answers: {
    [key: string]: boolean | string;
  }) => {
    const onlyTrueAnswers = answers.mostChallenging
      ? [answers.mostChallenging as string]
      : Object.entries(answers)
          .filter(([, value]) => value)
          .map(([key]) => key);

    mutation.mutate({
      question: currentQuestion.question,
      answers: onlyTrueAnswers,
    });
  };

  const getDefaultValues = useCallback(() => {
    return Object.fromEntries(
      currentQuestion.answers.map((answer) => {
        const storedAnswers =
          surveyResult &&
          surveyResult?.find(
            (result) => result.question === currentQuestion.question,
          )?.answers;
        if (answer.name === 'mostChallenging') {
          return [answer.name, (storedAnswers && storedAnswers[0]) || ''];
        }

        return [answer.name, !!storedAnswers?.find((a) => a === answer.name)];
      }),
    );
  }, [currentQuestion, surveyResult]);

  const formMethods = useForm({
    resolver: yupResolver(SurveySchema),
    defaultValues: getDefaultValues(),
  });

  useEffect(() => {
    formMethods.reset(getDefaultValues());
  }, [currentStep, surveyResult]);

  const { handleSubmit } = formMethods;

  const prevStep = () => {
    onSubmit(formMethods.getValues());
    goToPrevStep();
  };

  const nextStep = () => {
    onSubmit(formMethods.getValues());
    goToNextStep();
  };

  return (
    <div className="relative md:flex gap-[30px] justify-end content-wrap md:min-h-screen md:pt-[85px] pb-[80px] px-[50px] md:justify-center flex flex-col">
      <RegistrationProgress registrationSteps={registrationSteps} />
      {isLoadingStudents ? (
        <ProgressSpinner className="m-auto" />
      ) : (
        <>
          <h2 className="text-2xl md:text-4xl m-0">
            {students && students.length > 1
              ? currentQuestion.question?.replace('child', 'children')
              : currentQuestion.question}
          </h2>
          {currentStep < SurveyQuestionKeys.length ? (
            <p className="text-sm md:text-base mt-[-10px] mb-0">
              Select all that apply
            </p>
          ) : null}
          <div className="register-checkbox-wrap">
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                {currentQuestion &&
                  currentQuestion?.answers.map((question) =>
                    question.name === 'mostChallenging' ? (
                      <TextareaInput
                        key={question.name}
                        name={question.name}
                        label={question.label}
                      />
                    ) : (
                      <CheckboxInput key={question.name} name={question.name}>
                        {students && students.length > 1
                          ? question.label?.replace('child', 'children')
                          : question.label}
                      </CheckboxInput>
                    ),
                  )}
                <div className="mt-[30px]">
                  <PreviousNextButtons
                    previousStep={prevStep}
                    nextStep={nextStep}
                  />
                </div>
              </form>
            </FormProvider>
          </div>
        </>
      )}
      <Copyright className="absolute left-0 right-0 bottom-0 p-[30px] px-[50px]" />
    </div>
  );
};

export default SurveySteps;
