import { useEffect, useState } from 'react';
import { urlBase64ToUint8Array } from '../utils/urlBase64ToUint8Array';
import { httpClient } from '../api/http/http-client';
import { PUSH_NOTIFICATION_PUBLIC_KEY } from '../constants/environments';
import { isIOS } from 'react-device-detect';

const sendSubscriptionToServer = (subscription: PushSubscription) => {
  return httpClient.post('/push-subscriptions', subscription);
};

export const updateTimezone = (timezone: string) => {
  return httpClient.post('/push-subscriptions/timezone', { timezone });
};

const sendUnsubscriptionToServer = (endpoint: string) => {
  return httpClient.delete('/push-subscriptions', { params: { endpoint } });
};

const usePushNotifications = () => {
  const [permission, setPermission] = useState(
    'Notification' in window ? window?.Notification?.permission : '',
  );

  useEffect(() => {
    if (permission === 'granted') {
      registerServiceWorker();
    }
  }, [permission]);

  const getCurrentSubscription = async () => {
    if ('serviceWorker' in navigator) {
      try {
        const registrations = await navigator.serviceWorker.getRegistrations();
        if (registrations.length > 0) {
          const swRegistration = registrations[0];
          return swRegistration.pushManager.getSubscription();
        } else {
          console.log('No service worker registrations found.');
        }
      } catch (error) {
        console.error('Error getting service worker registrations:', error);
      }
    } else {
      console.log('Service workers are not supported in this browser.');
    }
  };

  const registerServiceWorker = async () => {
    const currentSubscription = await getCurrentSubscription();
    if ('serviceWorker' in navigator) {
      try {
        await navigator.serviceWorker.register(
          '/push-notifications-service-worker.js',
        );
        console.log('Service Worker Registered');

        const swRegistration = await navigator.serviceWorker.ready;

        // Check if we already have a subscription
        if (currentSubscription) {
          await currentSubscription.unsubscribe();
        }

        // Subscribe or resubscribe
        const subscription = await swRegistration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(
            PUSH_NOTIFICATION_PUBLIC_KEY,
          ),
        });

        console.log('Subscribed to push notifications');

        // Send the new subscription to the server
        await sendSubscriptionToServer(subscription);
      } catch (err) {
        console.error('Service Worker Registration Failed', err);
      }
    }
  };

  const requestPermission = () => {
    if ('Notification' in window) {
      window?.Notification?.requestPermission().then((permissionResult) => {
        setPermission(permissionResult);
      });
    }
  };

  const showNotification = async (
    title: string,
    options?: NotificationOptions,
  ): Promise<void> => {
    if (permission === 'granted') {
      try {
        const registration = await navigator.serviceWorker.ready;
        await registration.showNotification(title, options);
      } catch (err) {
        console.error('Error showing notification:', err);
      }
    } else {
      console.log('Notifications permission is not granted.');
    }
  };

  const unsubscribe = async () => {
    try {
      const subscription = await getCurrentSubscription();
      if (subscription) {
        await subscription.unsubscribe();
        await sendUnsubscriptionToServer(subscription.endpoint);
      }
    } catch (e) {}
  };
  const subscribe = async () => {
    if ('Notification' in window && permission === 'default') {
      requestPermission();
    }
  };

  return {
    showNotification,
    requestPermission,
    permission,
    subscribe,
    unsubscribe,
  };
};

export default !isIOS ||
(isIOS && 'standalone' in window.navigator && window.navigator['standalone'])
  ? usePushNotifications
  : () => {
      return {
        showNotification: () => {},
        requestPermission: () => {},
        permission: 'denied',
        subscribe: () => {},
        unsubscribe: () => {},
      };
    };
