import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { useSetPasswordWithToken } from './useSetPasswordWithToken';
import { useSnackbar } from '../../../contexts/SnackbarContext';
import { passwordValidation } from '../../../types/form';
import PasswordInput from '../../../components/PasswordInput';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { httpClient } from '../../../api/http/http-client';
import useAuth from '../../../contexts/AuthContext';
import { useSetPasswordForUser } from './useSetPasswordForUser';
import { getHomePath } from '../../../utils/getHomePath';
import Copyright from '../../../components/Copyright';
import { BackButton } from '../../PrivacyPolicyPage/PrivacyPolicyPage';
import { useCheckToken } from './useCheckToken';

type ChangePasswordFormFields = {
  password: string;
  confirmPassword: string;
};

const schema = yup.object().shape({
  password: passwordValidation,
  confirmPassword: yup
    .string()
    .required('Please confirm your password')
    .oneOf([yup.ref('password'), ''], 'Passwords must match'),
});

function ChangePassword() {
  const { openSnackbar } = useSnackbar();
  const { user, isLoading: isLoadingUser } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();
  const { data: checkTokenData, isLoading: isLoadingTokenData } = useCheckToken(
    { token },
  );
  const { mutation: setPasswordWithTokenMutation } = useSetPasswordWithToken({
    onSuccess: () => {
      openSnackbar({
        message: 'Password has been changed.',
        type: 'success',
      });
      navigate('/login');
    },
  });
  const { mutation: setPasswordForUserMutation } = useSetPasswordForUser({
    onSuccess: () => {
      openSnackbar({
        message: 'Password has been changed.',
        type: 'success',
      });
      navigate(getHomePath(user));
    },
  });

  const navigateToMain = () => {
    navigate('/');
    openSnackbar({
      message:
        'The link is expired. Please use the most recent one or try to reset the password once again.',
      type: 'error',
    });
  };

  useEffect(() => {
    if (!isLoadingUser && !user && !token) {
      navigateToMain();
    }
  }, [isLoadingUser]);

  useEffect(() => {
    if (token && !isLoadingTokenData && !checkTokenData?.valid) {
      navigateToMain();
    }
  }, [isLoadingTokenData]);

  const formMethods = useForm({
    resolver: yupResolver(schema),
  });
  const { handleSubmit } = formMethods;

  const onSubmit: SubmitHandler<ChangePasswordFormFields> = (data) => {
    if (token) {
      setPasswordWithTokenMutation.mutate({
        token: token,
        newPassword: data.password,
      });
    } else if (user) {
      setPasswordForUserMutation.mutate({
        newPassword: data.password,
      });
    }
  };

  if ((!user && !token) || (token && isLoadingTokenData)) {
    return <></>;
  }

  return (
    <div className="relative pb-[80px] md:flex content-wrap md:min-h-screen px-12 py-10 md:justify-center flex flex-col bg-white grow">
      {user && !token && <BackButton />}
      <h2 className="text-4xl">Change password</h2>
      <p className="text-lg pb-8">Please enter new password.</p>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-[30px]">
            <PasswordInput name="password" placeholder="New Password" />
            <PasswordInput
              name="confirmPassword"
              placeholder="Confirm Password"
              hideFeedback
            />
            <Button
              type="submit"
              className="p-[12px] px-[40px] flex justify-center text-center text-sm md:text-base w-full md:w-fit md:self-end"
            >
              Update Password
            </Button>
          </div>
        </form>
      </FormProvider>
      <Copyright className="absolute left-0 right-0 bottom-0 p-[30px] px-[50px] md:px-[80px]" />
    </div>
  );
}

export default ChangePassword;
