import { useMutation } from '@tanstack/react-query';
import { httpClient } from '../../../api/http/http-client';
import { AxiosError } from 'axios';
import { useSnackbar } from '../../../contexts/SnackbarContext';

interface UserCredentials {
  email: string;
}

type ResetErrorResponse = {
  message: string;
};

const resetPassword = async (userCredentials: UserCredentials) => {
  return httpClient.post('/auth/reset-password', { ...userCredentials });
};

export const useResetPassword = (callbacks: {
  onSuccess?: () => void;
  onError?: (error: ResetErrorResponse) => void;
}) => {
  const { handleNetworkError } = useSnackbar();

  const mutation = useMutation(
    (userCredentials: UserCredentials) => resetPassword(userCredentials),
    {
      onSuccess: callbacks.onSuccess,
      onError: callbacks?.onError,
    },
  );

  return { mutation };
};
