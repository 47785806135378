import { httpClient } from '../../api/http/http-client';
import { useQuery } from '@tanstack/react-query';
import { Parent } from '../../types/Parent';
import { SurveyAnswerData } from '../SurveySteps/useSurveySteps';
import { Student } from '../../types/Student';

export interface StudentWithTestResults extends Student {
  intermediateTestResultUid?: string;
  finalTestResultUid?: string;
  testStartedAt?: string;
  testCompletedAt?: string;
}

export interface ParentWithSurveys extends Parent {
  surveys: SurveyAnswerData[];
  students: StudentWithTestResults[];
}

export const getFamilyAccount = async ({
  queryKey,
}: {
  queryKey: (string | undefined)[];
}): Promise<ParentWithSurveys> => {
  const [, parentUid] = queryKey;

  return httpClient.get<void, ParentWithSurveys>(
    `/family-profile/parent/${parentUid}`,
  );
};

export const useGetFamilyAccount = ({ parentUid }: { parentUid?: string }) => {
  return useQuery(['/family-profile/parent', parentUid], getFamilyAccount, {
    enabled: !!parentUid,
  });
};
