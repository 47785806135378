import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { httpClient } from '../../api/http/http-client';
import { useSnackbar } from '../../contexts/SnackbarContext';

interface QuestionData {
  testUid: string;
  questionUid: string;
  answerValue: string;
}

const saveAnswer = async (questionData: QuestionData): Promise<{}> => {
  return httpClient.post('/tests/save', questionData);
};

export const useSaveAnswer = (callbacks: {
  onSuccess?: (data: {}, variables: QuestionData) => void;
  onError?: (error: AxiosError<{ message: string | string[] }>) => void;
}) => {
  const { handleNetworkError } = useSnackbar();
  const mutation = useMutation(
    (questionData: QuestionData) => saveAnswer(questionData),
    {
      onSuccess: callbacks.onSuccess ?? (() => {}),
      onError: (error: AxiosError<{ message: string | string[] }>) => {
        handleNetworkError(error);
        callbacks?.onError?.(error);
      },
    },
  );

  return { mutation };
};
