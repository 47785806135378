import React, { FC } from 'react';
import InfoIcon from '../../icons/InfoIcon';

const InfoComponent: FC<{ text: string }> = ({ text = '' }) => {
  return (
    <div className={`info-component w-full flex`}>
      <div className={'flex mr-3 ml-3 pt-3 pb-3'}>
        <InfoIcon />
      </div>
      <div className={'flex mr-3 ml-3 items-center h-full'}>
        <p className="text-left">{text}</p>
      </div>
    </div>
  );
};

export default InfoComponent;
