const ListIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M10 12H22"
        stroke="#797F8D"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 19H22"
        stroke="#797F8D"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 5H22"
        stroke="#797F8D"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 17H6V21H2V17ZM2 10H6V14H2V10ZM2 3H6V7H2V3Z"
        stroke="#797F8D"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ListIcon;
