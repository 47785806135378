import axios, {
  AxiosHeaderValue,
  AxiosHeaders,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosStatic,
  AxiosError,
} from 'axios';
import { BaseSuccessResponse } from './types';
import { serverUrl } from '../../constants/environments';
import { credentialsService } from '../../utils/services/credentialsService';
import { useSnackbar } from '../../contexts/SnackbarContext';

class HttpClient {
  private readonly api: AxiosInstance;

  private readonly config: AxiosRequestConfig = {
    baseURL: serverUrl,
    responseType: 'json',
    headers: {
      Accept: 'application/json',
    },
  };

  constructor(axiosStatic: AxiosStatic) {
    this.api = axiosStatic.create(this.config);
    this.initInterceptors();
  }

  public get<T, R = BaseSuccessResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.api.get(url, config);
  }

  public delete<T, R = BaseSuccessResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.api.delete(url, config);
  }

  public post<T, R = BaseSuccessResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.api.post(url, data, config);
  }

  public put<T, D, R = BaseSuccessResponse<T>>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.api.put(url, data, config);
  }

  public patch<T, D, R = BaseSuccessResponse<T>>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.api.patch(url, data, config);
  }

  private initInterceptors(): void {
    this.api.interceptors.request.use(
      function (config) {
        const url = config.url as string;
        const token = credentialsService.token;

        if (!/^(http|https):/i.test(url)) {
          const auth = `Bearer ${token}` as AxiosHeaderValue;
          config.headers = new AxiosHeaders({
            ...config.headers,
            Authorization: auth,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,OPTIONS',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          });

          config.url = `${url.replace(/^\/+/, '')}`;

          return config;
        }

        // Do something before request is sent
        return config;
      },
      function (error: AxiosError<{ message: string | string[] }>) {
        // Do something with request error
        if (error.response?.status === 403) {
          const { handleNetworkError } = useSnackbar();
          handleNetworkError(error);
        }
        return Promise.reject(error);
      },
    );
    this.api.interceptors.response.use((response) => response.data);
  }
}

export const httpClient = new HttpClient(axios);
