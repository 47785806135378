import React, { FC } from 'react';
import {
  getStatusLabel,
  StripeStatus,
  SubscriptionListItem,
} from '../../pages/ManageSubscription/useManageSubscriptions';
import CheckboxInput from '../CheckboxInput';
import DateIcon from '../../icons/DateIcon';
import { formatPrice } from '../../pages/ParentSubscription/useParentSubscription';
import { Tooltip } from 'primereact/tooltip';
import QuestionIcon from '../../icons/QuestionIcon';
import { CONTACT_PAGE_URL } from '../../pages/ManageSubscription/ManageSubscriptions';

export const SubscriptionItem: FC<{
  subscription: SubscriptionListItem;
  selectedStudents: { [uid: string]: boolean };
  refundDays: number;
  handleCheckboxChange: (uid: string, checked: boolean) => void;
  purchaseSubscription: (userUid: string) => void;
}> = ({
  subscription,
  selectedStudents,
  refundDays,
  handleCheckboxChange,
  purchaseSubscription,
}) => {
  const isSelected = selectedStudents[subscription.userUid];

  const isActive = subscription.status === StripeStatus.ACTIVE;
  const isCanceled = subscription.status === StripeStatus.CANCELED;
  const isPending = subscription.status === StripeStatus.PENDING;

  const containsFinal = subscription.containsFinal;
  const signedMoreThan14daysAgo = subscription.signedMoreThan14daysAgo;

  return (
    <div
      key={subscription.userUid}
      className={`student-select flex rounded-[10px] p-5 mb-2 ${
        !isCanceled && !isPending ? 'bg-grey' : ''
      } }`}
      style={{
        border: !isCanceled && !isPending ? 'none' : '1px solid #B3B9C3',
      }}
    >
      {isCanceled || isPending ? (
        <div className="w-[42px]"></div>
      ) : (
        <CheckboxInput
          name={`student-${subscription.userUid}`}
          disabled={isCanceled || isPending}
          checked={isSelected}
          onChange={() =>
            handleCheckboxChange(
              subscription.userUid,
              !selectedStudents[subscription.userUid],
            )
          }
        />
      )}
      <div className="flex flex-col w-full gap-[10px]">
        <div className="flex flex-col md:flex-row justify-between flex-wrap gap-y-[10px]">
          <div className="flex w-full md:w-fit justify-between">
            <h3
              className={`${
                subscription.fullName.includes(' ')
                  ? 'break-words'
                  : 'break-all'
              }`}
            >
              {subscription.fullName}
            </h3>
            <div
              className={`md:hidden ml-auto text-gray-300 ${
                isActive ? '!text-green6' : ''
              }`}
            >
              {getStatusLabel(subscription.status)}
            </div>
          </div>
          {!isPending && (
            <div className="flex items-center w-fit -mt-[5px] gap-[5px] flex-wrap">
              <span className="text-gray-400">Active before</span>{' '}
              <div className="inline relative top-1">
                <DateIcon />
              </div>{' '}
              {new Date(subscription.dueDate).toLocaleDateString()}
            </div>
          )}
        </div>
        <div
          className={`hidden md:block text-gray-300 ${
            isActive ? '!text-green6' : ''
          }`}
        >
          {getStatusLabel(subscription.status)}
        </div>
        {!isActive && subscription.status === StripeStatus.PENDING && (
          <div className="flex text-gray-300">
            <a
              href="javascipt:void(0)"
              onClick={(e) => {
                e.preventDefault();
                purchaseSubscription(subscription.userUid);
              }}
            >
              Buy Subscription
            </a>
          </div>
        )}
        {!isPending && (
          <div
            className={`flex gap-[10px] justify-between ${
              isSelected ? '' : 'not-active'
            }`}
          >
            <p className="m-0">Annual Membership</p>
            <p className="price text-right m-0 text-blue-500">
              <span>${formatPrice(subscription.price)}</span>
            </p>
          </div>
        )}
        {isActive && !containsFinal && !signedMoreThan14daysAgo && (
          <div className="flex text-gray-300 items-center">
            <Tooltip target=".refund-info" className="text-xs text-green-200" />
            <a
              href={CONTACT_PAGE_URL}
              target={'_blank'}
              className="refund-info items-center block"
              data-pr-tooltip={`You have ${refundDays}-days to try ULURU and receive a full refund if you choose. However, this is not available once you complete the neuropsych evaluation.`}
              data-pr-position="bottom"
              rel="noreferrer"
            >
              Request refund{' '}
              <div className="inline relative top-1.5 left-0.5">
                <QuestionIcon />
              </div>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
