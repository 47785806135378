import { httpClient } from '../../api/http/http-client';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Student } from '../../types/Student';
export enum ResultType {
  INTERMEDIATE = 'INTERMEDIATE',
  FINAL = 'FINAL',
}

export enum ResultStatus {
  GENERATING = 'GENERATING',
  PARTIALLY_READY = 'PARTIALLY_READY',
  READY = 'READY',
}

export interface ResultItem {
  testSubName: string;
  resultText: string;
}

export interface TestResult {
  uid: string;
  results: ResultItem[];
  summaryText?: string;
  type: ResultType;
  status: ResultStatus;
  studentTestUid: string;
  parentTestUid?: string;
  jobIds: JSON;
  aiServiceMapping?: {
    [jobUid: string]: string;
  };
  errorMapping?: {
    [jobUid: string]: string[];
  };
  createdAt: Date;
  updatedAt: Date;
}

export interface TestResultWithStudent extends TestResult {
  student: Student;
}

export const getTestResult = async ({
  queryKey,
}: {
  queryKey: (string | undefined)[];
}): Promise<TestResultWithStudent> => {
  const [, studentUid] = queryKey;
  return httpClient.get<void, TestResultWithStudent>('/tests/result', {
    params: { studentUid },
  });
};

export const useGetTestResult = ({
  studentUid,
  enabled,
}: {
  studentUid?: string;
  enabled?: boolean;
}) => {
  const [keepLoading, setKeepLoading] = useState(true);
  const useQueryResult = useQuery(
    ['/tests/result', studentUid],
    getTestResult,
    {
      refetchInterval: keepLoading ? 3000 : false,
      enabled,
    },
  );

  useEffect(() => {
    if (useQueryResult.data?.status === ResultStatus.READY) {
      setKeepLoading(false);
    }
  }, [useQueryResult.data]);

  return {
    ...useQueryResult,
  };
};
