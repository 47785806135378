import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { httpClient } from '../../../api/http/http-client';

export interface Data {
  studentUid: string;
}

const unlinkCanvas = async (data: Data) => {
  return httpClient.post<void, void>(`/canvas/unlink/${data.studentUid}`, {});
};

export const useUnlinkCanvas = (callbacks: {
  onSuccess?: () => void;
  onError?: (error: AxiosError<{ message: string | string[] }>) => void;
}) => {
  const mutation = useMutation((data: Data) => unlinkCanvas(data), {
    onSuccess: callbacks.onSuccess,
    onError: (error: AxiosError<{ message: string | string[] }>) => {
      callbacks?.onError?.(error);
    },
  });

  return mutation;
};
