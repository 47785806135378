import { httpClient } from '../../api/http/http-client';

export interface SubjectSelectItem {
  label: string;
  value: string;
}

export const getSubjects = async (): Promise<SubjectSelectItem[]> => {
  return httpClient.get<void, SubjectSelectItem[]>(`/coaching/subjects`, {});
};
