import { ChildRelationship } from '../../pages/RegisterParent/useRegisterParent';
import {
  RegisterStudentFormRef,
  StudentFields,
} from '../RegisterStudentForm/RegisterStudentForm';
import React, { FC, useEffect, useState } from 'react';
import { UserProfile } from '../../types/UserProfile';
import EmailInput from '../EmailInput';
import Input from '../Input';
import DateInput from '../DateInput';
import SelectInput from '../SelectInput';
import {
  CountriesSelectItems,
  DefaultCountry,
} from '../../constants/countries';
import { onZipcodeChange } from '../../utils/zipcode';
import PhoneNumberInput from '../PhoneNumberInput';
import { Button } from 'primereact/button';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { emailValidation, passwordValidation } from '../../types/form';
import { ErrorMessages } from '../../constants/messages';
import RegisterStudentForm from '../RegisterStudentForm';
import {
  maxBirthdayDate,
  minBirthdayDate,
} from '../../pages/RegisterParent/RegisterParent';
import { useEditProfile } from './useEditProfile';
import { format, parse } from 'date-fns';

interface ParentFormFields {
  uid?: string;
  email: string;
  firstName: string;
  lastName: string;
  birthday: Date;
  childRelationship: string;
  country: string;
  state?: string;
  street?: string;
  street2?: string;
  zipCode: string;
  phone: string;
}

const schema = yup.object().shape({
  email: emailValidation,
  firstName: yup
    .string()
    .required(ErrorMessages.Required('First Name'))
    .max(80, ErrorMessages.TooLong('First Name', 80)),
  lastName: yup
    .string()
    .required(ErrorMessages.Required('Last Name'))
    .max(80, ErrorMessages.TooLong('Last Name', 80)),
  birthday: yup.date().required(ErrorMessages.Required('Date of Birth')),
  childRelationship: yup
    .string()
    .required(ErrorMessages.Required('Relationship to Child'))
    .notOneOf(['Other'], ErrorMessages.Required('Relationship to Child')),
  country: yup.string().required(ErrorMessages.Required('Country')),
  state: yup.string().max(80, ErrorMessages.TooLong('State', 80)),
  street: yup.string().max(80, ErrorMessages.TooLong('Street', 80)),
  street2: yup.string().max(80, ErrorMessages.TooLong('Street 2', 80)),
  zipCode: yup
    .string()
    .required(ErrorMessages.Required('Zip Code'))
    .matches(/^\d{5}$/, 'Zip Code must be exactly 5 digits'),
  phone: yup
    .string()
    .max(15, ErrorMessages.Max('Phone', 15))
    .required(ErrorMessages.Required('Phone')),
});

interface Props {
  profileData: UserProfile;
  onCancel: () => void;
  onProfileUpdated: () => void;
}

interface StudentForm {
  id: string;
  ref: RegisterStudentFormRef | null;
  defaultValues?: StudentFields;
}

const parseBirthday = (birthday: string) => {
  return parse(birthday, 'yyyy-MM-dd', new Date());
};

export const ProfileInfoEdit: FC<Props> = ({
  profileData,
  onCancel,
  onProfileUpdated,
}) => {
  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      country: DefaultCountry,
    },
  });

  const { mutation } = useEditProfile({
    onSuccess: () => {
      onProfileUpdated();
    },
  });

  const [studentForms, setStudentForms] = useState<StudentForm[]>([]);

  useEffect(() => {
    formMethods.reset({
      ...profileData,
      birthday: parseBirthday(profileData.birthday),
    });

    const newStudentForms: StudentForm[] = [];
    profileData.students.forEach((student, index) => {
      newStudentForms.push({
        id: student.uid,
        ref: null,
        defaultValues: {
          fullName: student.fullName,
          birthday: parseBirthday(student.birthday),
          email: student.email,
          schoolZipCode: student.schoolZipCode,
          schoolName: student.schoolName,
          gradeLevel: Number(student.gradeLevel),
          gender: student.gender,
        },
      });
    });
    setStudentForms(newStudentForms);
  }, [profileData]);

  const onSubmitClick = async () => {
    const childFormPromises = studentForms.map((studentForm) => {
      return new Promise<(StudentFields & { uid: string }) | null>(
        (resolve) => {
          studentForm?.ref?.handleSubmit
            ? studentForm?.ref?.handleSubmit?.((data: StudentFields) => {
                resolve({ ...data, uid: studentForm.id });
              })()
            : resolve(null);
        },
      );
    });

    const parentFormPromise = new Promise<ParentFormFields>((resolve) => {
      formMethods.handleSubmit((data: ParentFormFields) => {
        resolve(data);
      })();
    });

    const [parentData, ...studentData] = await Promise.all([
      parentFormPromise,
      ...childFormPromises,
    ]);
    const studentDataFiltered = studentData.filter(
      (student) => student !== null,
    ) as (StudentFields & { uid: string })[];
    mutation.mutate({
      ...parentData,
      birthday: format(parentData.birthday, 'yyyy-MM-dd'),
      students: studentDataFiltered.map((student) => ({
        ...student,
        birthday: format(student.birthday, 'yyyy-MM-dd'),
      })),
    });
  };

  return (
    <div className="flex flex-col gap-[30px]">
      <FormProvider {...formMethods}>
        <form>
          <div className="flex flex-col gap-[30px]">
            <EmailInput name="email" placeholder="Email" />
            <div className="flex flex-col md:flex-row gap-[30px] md:gap-3">
              <div className="flex-1">
                <Input name="firstName" placeholder="First Name" />
              </div>
              <div className="flex-1">
                <Input name="lastName" placeholder="Last Name" />
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-[30px] md:gap-3">
              <div className="flex-1">
                <DateInput
                  name="birthday"
                  placeholder="Date of Birth"
                  minDate={minBirthdayDate}
                  maxDate={maxBirthdayDate}
                />
              </div>
              <div className="flex-1">
                <SelectInput
                  name="childRelationship"
                  placeholder="Relationship to Child"
                  otherOption
                  options={ChildRelationship}
                />
              </div>
            </div>
            <h3 className="text-2xl m-0">Address</h3>
            <div className="flex flex-col md:flex-row gap-[30px] md:gap-3">
              <div className="flex-1">
                <SelectInput
                  name="country"
                  placeholder="Country"
                  options={CountriesSelectItems}
                />
              </div>
              <div className="flex-1">
                <Input name="state" placeholder="State" />
              </div>
            </div>
            <Input name="street" placeholder="Street" />
            <Input name="street2" placeholder="Street 2" />
            <div className="flex flex-col md:flex-row gap-[30px] md:gap-3">
              <div className="flex-1">
                <Input
                  name="zipCode"
                  placeholder="Zip Code"
                  onChange={onZipcodeChange}
                />
              </div>
              <div className="flex-1">
                <PhoneNumberInput name="phone" />
              </div>
            </div>
          </div>
        </form>
      </FormProvider>

      <h3 className="m-0 text-2xl md:text-4xl text-header-color">
        Edit Students
      </h3>

      {studentForms.map((studentForm, index) => (
        <div key={studentForm.id}>
          <div
            className="text-sm md:text-base text-grey1 mb-[20px] w-full border-grey1 flex justify-between"
            style={{ borderBottom: '1px solid #b3b9c3' }}
          >
            <div
              className="flex gap-[10px] w-fit text-header-color py-[10px] px-[20px]"
              style={{ borderBottom: '2px solid #468ed0' }}
            >
              Student {index + 1}
            </div>
          </div>
          <RegisterStudentForm
            ref={(ref) => {
              studentForm.ref = ref;
            }}
            onValidityChange={(isValid) => {}}
            defaultValues={studentForm.defaultValues}
          />
        </div>
      ))}

      <div className="flex justify-between">
        <Button
          link
          className="flex text-base gap-[10px] ml-[-20px]"
          onClick={onCancel}
        >
          <i className="pi pi-times" />
          <div>Cancel</div>
        </Button>
        <Button
          className="flex text-base gap-[10px] px-[40px]"
          onClick={onSubmitClick}
        >
          <i className="pi pi-save" />
          <div>Save</div>
        </Button>
      </div>
    </div>
  );
};
